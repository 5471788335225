(function () {
  angular
    .module('easyshipDashboardApp')
    .controller('DashboardHeaderSingleCtrl', DashboardHeaderSingleCtrl);

  DashboardHeaderSingleCtrl.$inject = ['$scope', '$state', 'UserRightsService', 'CheckoutService'];

  function DashboardHeaderSingleCtrl($scope, $state, UserRightsService, CheckoutService) {
    $scope.state = $state;
    const vm = this;
    vm.CheckoutService = CheckoutService;
    $scope.isLoadingCustomValue = !!vm.CheckoutService.isFetchingValueForNotInsured();
    const isAtConfirmStage = $scope.state.current.name == 'order-summary';
    const { canPurchaseLabel } = UserRightsService;
    $scope.isConfirmPurchaseLabelDisabled = isAtConfirmStage && !canPurchaseLabel;

    $scope.$on('domestic.value_for_not_insured', function () {
      $scope.$apply();
    });

    $scope.isLoadingCustomValueFn = function () {
      $scope.isLoadingCustomValue = !!vm.CheckoutService.isFetchingValueForNotInsured();
      return !vm.isLoadingCustomValue;
    };
  }
})();

/* eslint-disable no-param-reassign */
import { toastSuccess } from '@client/core/components/react/Toastify';

(function () {
  angular.module('easyshipDashboardApp').controller('DashboardHomepageCtrl', DashboardHomepageCtrl);

  DashboardHomepageCtrl.$inject = [
    '$scope',
    '$timeout',
    '$stateParams',
    '$cookies',
    '$window',
    'UserSession',
    'Auth',
    'analyticsDetailsModal',
    'CompanyService',
    'StoreService',
    'HelperService',
    'AnalyticsHelpers',
    'API',
    '$translate',
    'AppCuesService',
    'MixpanelService',
  ];

  function DashboardHomepageCtrl(
    $scope,
    $timeout,
    $stateParams,
    $cookies,
    $window,
    UserSession,
    Auth,
    analyticsDetailsModal,
    CompanyService,
    StoreService,
    HelperService,
    AnalyticsHelpers,
    API,
    $translate,
    AppCuesService,
    MixpanelService
  ) {
    const vm = this;

    const defaultDaysDuration = 30;
    const maxItemsInTop = 6;

    vm.UserSession = UserSession;
    vm.AnalyticsHelpers = AnalyticsHelpers;
    vm.analyticsDetailsModal = analyticsDetailsModal;
    vm.HelperService = HelperService;
    vm.API = API;
    vm.$translate = $translate;

    vm.translations = {};
    vm.dateParams = {};
    vm.show = {};
    vm.dateHovered = {};
    vm.busy = {};
    vm.errors = {};
    vm.wrappers = {
      blue(value) {
        return `<span class="text-blue-700 text-xl font-bold">${value}</span>`;
      },
      yellow(value) {
        return `<span class="text-yellow-500 text-xl font-bold">${value}</span>`;
      },
    };

    vm.data = {
      analytics: {},
      disconnectedStores: [],
    };

    vm.greetingTime = {
      isMorning: false,
      isAfternoon: false,
      isEvening: false,
    };

    $scope.datePicker = {
      date: {},
    };

    vm.getDashboardHomeImageUrl = function (filename) {
      return new URL(
        `../../../../assets/images/dashboard/dashboard-homepage/${filename}.svg`,
        import.meta.url
      ).href;
    };

    vm.openViewMoreModal = function (type, data) {
      vm.analyticsDetailsModal.open({
        type,
        data,
        totalShipments: vm.totals.total_shipments_count,
        selectedDate: vm.AnalyticsHelpers.selectedDate,
      });
    };

    vm.dateRangeIsSameDay = function () {
      return vm.AnalyticsHelpers.dateRangeIsSameDay;
    };

    vm.dateRangeIsToday = function () {
      return vm.AnalyticsHelpers.dateRangeIsToday;
    };

    vm.onDatePickerClick = function () {
      MixpanelService.track('Analytics - Open Date Selector');
    };

    vm.trackReconnectDetails = function (store) {
      MixpanelService.track('Disconnected Store - See Details - Banner', {
        source: 'Home Page',
        store_id: store.id,
        store_name: store.name,
        platform: store.platform.display_name,
      });
    };

    vm.hideStoreAlert = function (id) {
      vm.data.disconnectedStores = vm.data.disconnectedStores.filter(function (store) {
        return store.id !== id;
      });
      $scope.$apply();
    };

    vm.showConnectStoreButton = function () {
      const canConnectStore = UserSession.hasUserRole('integrations');
      return !vm.show.noShipments && canConnectStore;
    };

    this.handleChartClick = function (type, data) {
      if (type === 'doughnut') {
        AnalyticsHelpers.goToShipmentsPage(data, vm.AnalyticsHelpers.selectedDate);
        MixpanelService.track('Analytics - Click Filter');
      } else if (type === 'bar') {
        AnalyticsHelpers.goToShipmentsPage(
          {
            last_status_message_id: data.status_message_ids.join(','),
            shipments_status: data.x,
          },
          vm.AnalyticsHelpers.selectedDate
        );
        MixpanelService.track('Analytics - Click Filter');
      }
    };

    function _fetchDataAndBuildAllCharts(dateParams) {
      vm.dateParams = dateParams;
      _clearAllCharts();

      _getChartData(dateParams, 'totals_and_averages')
        .then(function (data) {
          _buildTotalsSection(data);
          if (data.analytics.total_shipments_count === 0) vm.show.noShipments = true;
        })
        .catch(function () {
          vm.errors.totals = true;
        });

      // Top solutions
      _getChartData(dateParams, 'shipments_count_by_courier')
        .then(function (data) {
          _buildTopSolutionsSection(data);
        })
        .catch(function () {
          vm.errors.topSolutions = true;
        });

      // Top solutions
      _getChartData(dateParams, 'shipments_count_by_country_and_zone')
        .then(function (data) {
          _buildTopDestinationsSection(data);
        })
        .catch(function () {
          vm.errors.topDestinations = true;
        });

      // Returns
      _getChartData(dateParams, 'total_return_shipments_count')
        .then(function (data) {
          _buildReturnSection(data);
        })
        .catch(function () {
          vm.errors.returns = true;
        });

      $scope.datePicker.options = _loadDatePickerOptions();

      vm.busy.page = false;
    }

    function _buildReturnSection(data) {
      vm.returnShipmentsCount = data.analytics.total_return_shipments_count;

      vm.busy.returns = false;
    }

    function _buildTopSolutionsSection(data) {
      // Set value to hide/show "View More" at the bottom of each card
      vm.totalTopSolutions = data.analytics.shipments_count_by_courier.length;

      vm.show.viewMoreShippingSolutions = vm.totalTopSolutions > maxItemsInTop;

      vm.totalShipmentsByCourier = data.analytics.shipments_count_by_courier;

      vm.topCouriers = HelperService.getSplicedArray(
        data.analytics.shipments_count_by_courier,
        maxItemsInTop
      );

      vm.busy.topSolutions = false;
    }

    function _buildTopDestinationsSection(data) {
      vm.totalTopCountries = data.analytics.shipments_count_by_country.length;

      vm.show.viewMoreDestinationCountries = vm.totalTopCountries > maxItemsInTop;

      vm.totalShipmentsByCountry = data.analytics.shipments_count_by_country;

      // Assign values for items to be shown in main cards
      vm.topDestinationCountries = HelperService.getSplicedArray(
        data.analytics.shipments_count_by_country,
        maxItemsInTop
      );

      vm.busy.topDestinations = false;
    }

    function _buildTotalsSection(data) {
      // Totals
      vm.totals = Object.assign(data.analytics, data.analytics.totals);
      // Averages
      vm.averages = data.analytics.averages_per_shipment;

      vm.busy.totals = false;
    }

    $scope.goToFilter = function (field, value) {
      AnalyticsHelpers.goToFilter(field, value, vm.AnalyticsHelpers.selectedDate);
      MixpanelService.track('Analytics - Click Filter');
    };

    function _getGreetingTime() {
      const time = moment();

      if (!time || !time.isValid()) {
        return;
      } // if we can't find a valid or filled moment, we return.

      const splitAfternoon = 12; // 24hr time to split the afternoon
      const splitEvening = 17; // 24hr time to split the evening
      const currentHour = parseFloat(time.format('HH'));

      const isAfternoon = currentHour >= splitAfternoon && currentHour < splitEvening;
      const isEvening = currentHour >= splitEvening;

      vm.greetingTime = {
        isMorning: !isAfternoon && !isEvening,
        isAfternoon,
        isEvening,
      };
    }

    function _setChartsBusyState(boolean) {
      vm.busy.topSolutions = boolean;
      vm.busy.topDestinations = boolean;
      vm.busy.data = boolean;
      vm.busy.totals = boolean;
      vm.busy.returns = boolean;
    }

    function _clearAllCharts() {
      vm.totals = null;
      vm.averages = null;

      vm.errors = {};
    }

    function _initDates() {
      if (Object.keys(vm.AnalyticsHelpers.selectedDate).length) {
        $scope.datePicker.date = {
          startDate: vm.AnalyticsHelpers.selectedDate.startDate,
          endDate: vm.AnalyticsHelpers.selectedDate.endDate,
        };
      } else {
        const isBeforeCreation = moment()
          .subtract(defaultDaysDuration - 1, 'days')
          .isBefore(UserSession.company.created_at);
        const startDate = isBeforeCreation
          ? moment(UserSession.company.created_at)
          : moment()
              .subtract(defaultDaysDuration - 1, 'days')
              .startOf('day');

        $scope.datePicker.date = { startDate, endDate: moment() };
        vm.AnalyticsHelpers.selectedDate = angular.copy($scope.datePicker.date);
      }
    }

    function _loadDatePickerOptions() {
      const picker = {
        applyClass: 'button__color-green',
        locale: {
          applyLabel: vm.translations['global.search'],
          fromLabel: vm.translations['global.from'],
          format: 'DD MMM',
          toLabel: vm.translations['global.to'],
          cancelLabel: vm.translations['global.close'],
          customRangeLabel: vm.translations['global.custom-range'],
          monthNames: [
            vm.translations['global.january'],
            vm.translations['global.february'],
            vm.translations['global.march'],
            vm.translations['global.april'],
            vm.translations['global.may'],
            vm.translations['global.june'],
            vm.translations['global.july'],
            vm.translations['global.august'],
            vm.translations['global.september'],
            vm.translations['global.october'],
            vm.translations['global.november'],
            vm.translations['global.december'],
          ],
          separator: ' - ',
        },
        ranges: {},
        alwaysShowCalendars: true,
        autoUpdateInput: false,
        opens: 'left',
        minDate: moment(UserSession.company.created_at), // Bug/typo in library: for minDate, month is first
        maxDate: moment().format('DD/MM/YYYY'),
        eventHandlers: {
          'apply.daterangepicker': function () {
            vm.AnalyticsHelpers.selectedDate = angular.copy($scope.datePicker.date);

            const dateParams = AnalyticsHelpers.sanitizeDateParams(
              vm.AnalyticsHelpers.selectedDate
            );

            _start(dateParams);

            MixpanelService.track('Analytics - Changed Default Date');
          },
        },
      };

      picker.ranges[vm.translations['global.last-7-days']] = [
        moment().subtract(6, 'days'),
        moment(),
      ];
      picker.ranges[vm.translations['global.last-30-days']] = [
        moment().subtract(29, 'days'),
        moment(),
      ];
      picker.ranges[vm.translations['global.this-year']] = [
        moment(new Date(new Date().getFullYear(), 0, 1)),
        moment(),
      ];

      return picker;
    }

    function _loadTranslations() {
      return vm
        .$translate([
          'analytics.good-morning',
          'analytics.good-afternoon',
          'analytics.good-evening',
          'global.today',
          'global.january',
          'global.february',
          'global.march',
          'global.april',
          'global.may',
          'global.june',
          'global.july',
          'global.august',
          'global.september',
          'global.october',
          'global.november',
          'global.december',
          'global.search',
          'global.from',
          'global.to',
          'global.close',
          'global.custom-range',
          'global.last-7-days',
          'global.last-30-days',
          'global.this-year',
        ])
        .then(function (translations) {
          vm.translations = translations;
          _getGreetingTime();
        });
    }

    function _hasEverShipped(dateParams) {
      return _getChartData(dateParams, 'has_ever_shipped').then(function (data) {
        return data.analytics && data.analytics.has_ever_shipped;
      });
    }

    function _getChartData(params, scopes) {
      if (scopes !== 'has_ever_shipped') _setChartsBusyState(true);

      params = Object.assign(params, { scopes });

      return CompanyService.getCompanyAnalyticsV2(params)
        .then(function (data) {
          return data;
        })
        .catch(function (err) {
          return err;
        });
    }

    // eslint-disable-next-line consistent-return
    function _start(dateParams) {
      if (!UserSession.company) {
        return _showEmptyState();
      }

      StoreService.getStores().then(function (data) {
        const dismissed = $cookies.getObject('ES_DISMISSED_ALERTS') || [];
        vm.data.disconnectedStores = data.stores.filter(function (store) {
          return (
            (store.auth_state === 'auth_failed' || store.requires_reauthorize_permission) &&
            !dismissed.includes(store.id)
          );
        });
      });

      if ($stateParams.new_connect) {
        toastSuccess($translate.instant('connect.notifications.added'));
      }

      // Initialize and sanitize dates for query and datepicker
      if (!dateParams) {
        _initDates();
        dateParams = AnalyticsHelpers.sanitizeDateParams($scope.datePicker.date);
        $scope.datePicker.options = _loadDatePickerOptions();
        vm.busy.page = true;
      }

      _hasEverShipped(dateParams).then(function (value) {
        vm.busy.page = false;
        if (value) _fetchDataAndBuildAllCharts(dateParams);
        else _showEmptyState();
      });
    }

    (function init() {
      if (UserSession.user.first_name) {
        vm.avatarLetter = HelperService.getFirstLetter(UserSession.user.first_name);
      }

      const urlParams = new URLSearchParams($window.location.search);

      if ($stateParams.onboarding || urlParams.get('onboarding')) {
        MixpanelService.track('Analytics - Arrived', {
          ab_test_key: 'direct_to_quote',
          ab_test_variation_key:
            $cookies.getObject('AB_TESTS') && $cookies.getObject('AB_TESTS').direct_to_quote,
        });
      }

      Auth.retrieveSession()
        .then(_loadTranslations)
        .then(_start)
        .catch(function () {
          AppCuesService.reset();

          $timeout(function () {
            window.location = '/logout';
          }, 1000);
        });
    })();

    function _showEmptyState() {
      _setChartsBusyState(false);
      vm.show.welcome = true;
      vm.busy.page = false;
    }
  }
})();

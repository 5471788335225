import React from 'react';
import { FormattedMessage } from 'react-intl';

import { API } from '@client/core/config/api.constant';
import style from './LearnMore.r.module.scss';
import ReactRootProviders from '../../context/ReactRootProviders';

interface LearnMoreProps {
  esSectionName: string;
  esSupportLink: string;
  esDescription?: string;
  esAddBorder?: boolean;
  esClickLink?: () => void;
}

export default function LearnMore({
  esSectionName,
  esSupportLink,
  esDescription,
  esClickLink,
  esAddBorder,
}: LearnMoreProps) {
  return (
    <div className={style.wrapper}>
      <div className={esAddBorder ? style.earnMoreBorder : style.noBorder}>
        <div className={style.badge}>
          <span className="easyship-badge badge-medium badge-white">
            <span className="font-bold">?</span>
          </span>
        </div>
        <span className={`${style.description} text-base`}>
          {esDescription || <FormattedMessage id="learn-more.content" />}
          &nbsp;
          <a
            href={API.help + esSupportLink}
            target="_blank"
            rel="noopener noreferrer"
            onClick={esClickLink}
          >
            {esSectionName}
          </a>
        </span>
      </div>
    </div>
  );
}

export function WrappedLearnMore(props: LearnMoreProps) {
  return (
    <ReactRootProviders>
      <LearnMore {...props} />
    </ReactRootProviders>
  );
}

import { ProductListingModal } from '@client/src/data/product-listing';
import { formatHsCodeNumber } from '@client/core/corelogic/models/HsCode';

import { IComboButtonMenuAction } from 'typings/core/components/combo-button';
import {
  IProductListingProducts,
  IProductListingTotals,
  IProductListingFilters,
  IProductListingSelectedProducts,
  IProductListingLoading,
} from 'typings/dashboard/services/product-listing';

import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { AppCuesService } from '@client/core/services/app-cues/app-cues.service';
import { toastError } from '@client/core/components/react/Toastify';
import iconBatteryUrl from '@assets/images/dashboard/create-shipment-main-modal/icon-battery.svg';
import iconLiquidUrl from '@assets/images/dashboard/create-shipment-main-modal/icon-liquid.svg';
import image from './products-page-no-products.svg?svgo';
import style from './products-page.module.scss';
import template from './products-page.html?raw';
import { ProductListingService } from '../product-listing.service';

class ProductsPage implements IComponentController {
  style = style;
  image = image;
  iconBatteryUrl = iconBatteryUrl;
  iconLiquidUrl = iconLiquidUrl;
  showFilter = false;
  pagination = 10;
  moreActions: IComboButtonMenuAction[] = [];
  onRowClick?: (payload: { productIndex: number }) => void;
  formatHsCodeNumber = formatHsCodeNumber;

  static $inject = [
    '$scope',
    '$translate',
    'ProductListingService',
    'MixpanelService',
    'AppCuesService',
  ];
  constructor(
    private $scope: ng.IScope,
    private $translate: angular.translate.ITranslateService,
    private ProductListingService: ProductListingService,
    private MixpanelService: MixpanelService,
    private AppCuesService: AppCuesService
  ) {}

  $onInit(): void {
    this.watchProductMissingValue();

    this.MixpanelService.track('Products - Visit page');
  }

  get currentFilters(): IProductListingFilters {
    return this.ProductListingService.currentFilters;
  }

  get newFilters(): IProductListingFilters {
    return this.ProductListingService.newFilters;
  }

  get products(): IProductListingProducts[] {
    return this.ProductListingService.products;
  }

  get totals(): IProductListingTotals {
    return this.ProductListingService.totals;
  }

  get loading(): IProductListingLoading {
    return this.ProductListingService.loading;
  }

  get itemsPerPage(): number {
    return this.ProductListingService.itemsPerPage;
  }

  get currentPage(): number {
    return this.ProductListingService.currentPage;
  }

  get isSelectedAllProducts(): boolean {
    return this.ProductListingService.isSelectedAllProducts;
  }

  get excludedProducts(): IProductListingSelectedProducts {
    return this.ProductListingService.excludedProducts;
  }

  get selectedProducts(): IProductListingSelectedProducts {
    return this.ProductListingService.selectedProducts;
  }

  get selectedProductsCount(): number {
    return this.isSelectedAllProducts
      ? this.totals.total_products_count - Object.keys(this.excludedProducts).length
      : Object.keys(this.selectedProducts).length;
  }

  getMoreActions(): void {
    let exportKey = 'product-listing.buttons.export';

    if (Object.keys(this.selectedProducts).length || Object.keys(this.excludedProducts).length) {
      exportKey += '-selected';
    } else if (this.ProductListingService.isFiltered) {
      exportKey += '-filtered';
    }

    this.moreActions = [
      {
        icon: 'icon-trash',
        translationKey: 'product-listing.buttons.delete-selected',
        action: () => {
          this.ProductListingService.selectedProductsCount = this.selectedProductsCount;
          if (this.ProductListingService.selectedProductsCount > 0) {
            this.ProductListingService.activeModal = ProductListingModal.DeleteProduct;
          } else {
            toastError(
              this.$translate.instant('toast.select-error', {
                noun: this.$translate
                  .instant('global.pluralize.product', { COUNT: 1 }, 'messageformat')
                  .toLowerCase(),
              })
            );
          }
        },
      },
      {
        icon: 'icon-pencil',
        translationKey: 'product-listing.buttons.edit-selected',
        action: () => {
          if (this.selectedProductsCount > 0) {
            this.ProductListingService.isOpenBulkEditor = false;
            this.ProductListingService.activeModal = ProductListingModal.EditProducts;
          } else {
            toastError(
              this.$translate.instant('toast.select-error', {
                noun: this.$translate
                  .instant('global.pluralize.product', { COUNT: 1 }, 'messageformat')
                  .toLowerCase(),
              })
            );
          }
        },
      },
      {
        icon: 'icon-export',
        translationKey: exportKey,
        action: () => {
          this.ProductListingService.export();
        },
      },
    ].map(
      (button: IComboButtonMenuAction): IComboButtonMenuAction => ({
        ...button,
        displayText: this.$translate.instant(button.translationKey as string),
      })
    );
  }

  enterItemDetails(): void {
    this.ProductListingService.activeModal = ProductListingModal.EditProductsMissingInformation;
  }

  onKeywordSearch = (value: string): void => {
    this.ProductListingService.currentFilters.keyword = value;
    this.ProductListingService.newFilters.keyword = value;
    this.ProductListingService.isFiltered = true;
    this.ProductListingService.getProductList();
  };

  toggleFilter = (): void => {
    this.showFilter = !this.showFilter;
  };

  resetSearch(): void {
    this.ProductListingService.resetSearch();
    this.ProductListingService.getProductList().then(() => {
      this.ProductListingService.isFiltered = false;
    });
    this.ProductListingService.resetCheckState();
  }

  selectProduct(state: boolean, productId: string): void {
    const selectionsKey = this.isSelectedAllProducts ? 'excludedProducts' : 'selectedProducts';
    if (this.isSelectedAllProducts !== state) {
      this.ProductListingService[selectionsKey][productId] = true;
    } else {
      delete this.ProductListingService[selectionsKey][productId];
    }
  }

  getSelectAllCheckboxState(): boolean {
    if (this.products.length === 0) return false;

    if (this.isSelectedAllProducts) return Object.keys(this.excludedProducts).length === 0;

    return this.products.every(
      (product) => this.ProductListingService.selectedProducts[product.id]
    );
  }

  getProductCheckboxState(id: number): boolean {
    if ((this.isSelectedAllProducts && !this.excludedProducts[id]) || this.selectedProducts[id]) {
      return true;
    }

    return false;
  }

  toggleAllProducts(): void {
    this.ProductListingService.isSelectedAllProducts =
      !this.ProductListingService.isSelectedAllProducts;
    this.ProductListingService.excludedProducts = {};
    this.ProductListingService.selectedProducts = {};
  }

  selectAllRow(state: boolean): void {
    if (this.isSelectedAllProducts) {
      this.products.forEach((product) => {
        if (!state) {
          this.ProductListingService.excludedProducts[product.id] = true;
        } else {
          delete this.ProductListingService.excludedProducts[product.id];
        }
      });
    } else {
      this.products.forEach((product) => {
        if (state) {
          this.ProductListingService.selectedProducts[product.id] = true;
        } else {
          delete this.ProductListingService.selectedProducts[product.id];
        }
      });
    }
  }

  onSelectItemsPerPage(itemsPerPage: number): void {
    this.ProductListingService.currentPage = 1;
    this.ProductListingService.itemsPerPage = itemsPerPage;

    this.ProductListingService.getProductList();
  }

  onChangePage(page: number): void {
    this.ProductListingService.currentPage = page;
    this.ProductListingService.getProductList();
  }

  haveMissingField(product: IProductListingProducts): boolean {
    return !(
      product.identifier &&
      product.name &&
      ((product.category && product.category.id && product.category.name) || product.hs_code) &&
      product.length &&
      product.width &&
      product.height &&
      product.weight
    );
  }

  editSingleProduct($event: Event, productId: string): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.clearAndAssignSelectedProducts(productId);
    this.ProductListingService.activeModal = ProductListingModal.EditProducts;
    this.ProductListingService.isOpenBulkEditor = true;
  }

  deleteSingleProduct($event: Event, productId: string): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.clearAndAssignSelectedProducts(productId);
    this.ProductListingService.selectedProductsCount = this.selectedProductsCount;
    this.ProductListingService.activeModal = ProductListingModal.DeleteProduct;
  }

  containsBatteries(product: IProductListingProducts): boolean {
    return !!(product.contains_battery_pi966 || product.contains_battery_pi967);
  }

  containsLiquids(product: IProductListingProducts): boolean {
    return !!product.contains_liquids;
  }

  private clearAndAssignSelectedProducts(productId: string): void {
    this.ProductListingService.selectedProducts = {};
    this.ProductListingService.selectedProducts[productId] = true;
  }

  private haveMissingWeightDimensionCategory(product: IProductListingProducts): boolean {
    if (product.category && (!product.category.id || !product.category.name)) {
      return true;
    }

    if (!product.weight || !product.length || !product.width || !product.height) {
      return true;
    }

    return false;
  }

  private watchProductMissingValue(): void {
    this.$scope.$watch(
      () => this.products,
      (products) => {
        const missingProduct = products.find((product) =>
          this.haveMissingWeightDimensionCategory(product)
        );
        if (missingProduct) {
          this.AppCuesService.track('Products | Missing Values', null, true);
        }
      }
    );
  }
}

const ProductsPageComponent: ng.IComponentOptions = {
  controller: ProductsPage,
  template,
  bindings: {
    index: '<',
    onRowClick: '&',
  },
};

export { ProductsPageComponent };

import { LiquidAndBatteryDeclarationProps } from '@/components/LiquidAndBatteryDeclaration/type';
import { UserSessionProvider } from '@app/contexts/providers/UserSessionProvider';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import React, { lazy, Suspense } from 'react';
import { react2angular } from 'react2angular';

const LiquidAndBatteryDeclaration = lazy(
  () => import('@app/components/LiquidAndBatteryDeclaration')
);

function WrappedLiquidAndBatteryDeclaration(props: LiquidAndBatteryDeclarationProps) {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <Suspense fallback={null}>
          <LiquidAndBatteryDeclaration {...props} />
        </Suspense>
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularLiquidAndBatteryDeclaration = react2angular(
  WrappedLiquidAndBatteryDeclaration,
  ['shipmentId', 'onChange', 'hasBattery', 'hasLiquid']
);

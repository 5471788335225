import React from 'react';
import { MenuItem } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { IShipmentListItem } from 'typings/shipment';
import { baseStyleIconBox, baseStyleIcon } from '../styles';

interface PrintAllDocsMenuItemProps {
  divider?: boolean;
  shipmentListItem: IShipmentListItem;
  onPrintAllLabels: () => void;
}

export default function PrintAllDocsMenuItem({
  divider = false,
  shipmentListItem,
  onPrintAllLabels,
}: PrintAllDocsMenuItemProps) {
  const {
    shipment_state: shipmentState,
    is_valid_to_print: isValidToPrint,
    is_return: isReturn,
  } = shipmentListItem;

  if (!isValidToPrint || shipmentState === 'cancelled' || isReturn) {
    return null;
  }

  return (
    <MenuItem onClick={onPrintAllLabels} divider={divider}>
      <div style={baseStyleIconBox}>
        <i className="icon-print text-md" style={{ color: baseStyleIcon.colorGrey }} />
      </div>
      <span className="text-base">
        <FormattedMessage id="shipments.speed-dial.printing-all-shipment-docs" />
      </span>
    </MenuItem>
  );
}

import React, { useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { getCountryRules } from '@client/src/create-shipments/shipment-details-drawer/countryRules/countryRules.data';
import { StateMatches } from '@client/core/corelogic/models/PostalCodeMatches';
import { usePostalCodeMatchesQuery } from '@client/src/create-shipments/shipment-details-drawer/sections/adresses-section/addresses-form/usePostalCodeMatchesQuery';
import Select from '../Select';
import Input from '../Input';
import { useFormFields } from './FormFieldsProvider';

export interface CountryFieldsData {
  countryId: number;
  postalCode: string;
  state: string;
  city: string;
}

export const initialCountryFieldsData: CountryFieldsData = {
  countryId: 0,
  postalCode: '',
  state: '',
  city: '',
};

export function CountryFields() {
  const {
    name,
    strictFieldsValidation,
    countries,
    isCountrySelectDisabled,
    renderCountriesOptions,
    onCountryChange,
    isPostalCodeMatchesQueryEnabled,
    renderHkDistrict,
    initialCountryId,
    senderCountries,
  } = useFormFields();
  const [isTriggerByUserChangingPostalCode, setIsTriggerByUserChangingPostalCode] = useState(false);
  const prefixName = name ? `${name}.` : '';
  const { formatMessage } = useIntl();
  const { setValue, getValues, trigger, clearErrors } = useFormContext();
  const [stateOptions, setStateOptions] = useState<StateMatches[]>([]);
  const [cityOptions, setCityOptions] = useState<string[]>([]);
  const [watchCountryId, watchPostalCodeValue] = useWatch({
    name: [`${prefixName}countryId`, `${prefixName}postalCode`],
  });
  const state = getValues(`${prefixName}state`);

  const country = countries.find((country) => country.id === (watchCountryId || initialCountryId));
  const countryRules = getCountryRules(country?.alpha2);
  const postalCodeMatchesQuery = usePostalCodeMatchesQuery({
    postalCode: watchPostalCodeValue,
    countryId: watchCountryId,
    enabled:
      countryRules.postalCode.shouldFetchCityState &&
      isPostalCodeMatchesQueryEnabled(
        country?.postalCodeValidation.regex,
        country,
        watchPostalCodeValue
      ),
    onError: () => {
      setValue(`${prefixName}state`, '');
      setValue(`${prefixName}city`, '');
    },
  });
  const stateFieldFlag = !countryRules.state.isRequired ? 'optional' : undefined;
  const isPostalCodeVisible = strictFieldsValidation
    ? country?.postalCodeValidation.isVisible ?? true
    : true;
  const isStateVisible = strictFieldsValidation ? countryRules.state.isVisible : true;

  useEffect(() => {
    if (postalCodeMatchesQuery.data) {
      const { states, cities } = postalCodeMatchesQuery.data;
      if (states.length > 0) {
        initializeFormStates(states);
        trigger(`${prefixName}state`);
      } else if (cities.length > 0) {
        initializeFormCities(cities);
      }
    }
  }, [postalCodeMatchesQuery.data]);

  function initializeFormStates(states: StateMatches[]) {
    let defaultState = states.find(
      ({ name, abbr }) =>
        name.toLowerCase() === state.toLowerCase() || abbr.toLowerCase() === state.toLowerCase()
    );
    const useInputStateIsNotFromPostalCodeList = states.length > 0 && !defaultState;
    if (!isTriggerByUserChangingPostalCode && useInputStateIsNotFromPostalCodeList) {
      const newStates = [...states, { name: state, abbr: state, cities: [] }];
      setStateOptions(newStates);
      setValue(`${prefixName}state`, state, {
        shouldValidate: true,
      });
      initializeFormCities([...states[0].cities]);
    } else {
      defaultState =
        states.find(
          ({ name, abbr }) =>
            name.toLowerCase() === state.toLowerCase() || abbr.toLowerCase() === state.toLowerCase()
        ) || states[0];

      setStateOptions(states);
      setValue(`${prefixName}state`, defaultState.name, {
        shouldValidate: true,
      });
      initializeFormCities(defaultState.cities);
    }
  }

  function initializeFormCities(cities: string[]) {
    if (isTriggerByUserChangingPostalCode) {
      const cityOptions =
        stateOptions.find(({ name }) => name === getValues(`${prefixName}state`))?.cities ||
        cities ||
        [];

      const city = cityOptions[0] ?? cities[0] ?? '';
      setValue(`${prefixName}city`, city, {
        shouldValidate: true,
      });

      setCityOptions(cityOptions);
      setIsTriggerByUserChangingPostalCode(false);
    } else {
      const currentCity = getValues(`${prefixName}city`);
      const matchedCity =
        cities.length > 0 &&
        cities.find((city) => city.toLowerCase() === currentCity.toLowerCase());
      if (!matchedCity) {
        setValue(`${prefixName}city`, currentCity, {
          shouldValidate: true,
        });
        const citiesList = [...cities, currentCity];
        setCityOptions(citiesList);
        setIsTriggerByUserChangingPostalCode(false);
      } else {
        setValue(`${prefixName}city`, matchedCity, {
          shouldValidate: true,
        });
        setCityOptions(cities);
        setIsTriggerByUserChangingPostalCode(false);
      }
    }
  }

  function handlePostalCodeChange(
    e: React.ChangeEvent<{
      name?: string;
      value: string;
    }>
  ) {
    setIsTriggerByUserChangingPostalCode(true);
    clearErrors([`${prefixName}state`, `${prefixName}city`]);
    setValue(`${prefixName}postalCode`, e.target.value, {
      shouldDirty: true,
    });
    setValue(`${prefixName}id`, '');
    setValue(`${prefixName}state`, '', {
      shouldDirty: true,
    });
    setValue(`${prefixName}city`, '', {
      shouldDirty: true,
    });
  }

  function handleStateChange() {
    setCityOptions([]);
    setValue(`${prefixName}id`, '');
  }

  useEffect(() => {
    if (isTriggerByUserChangingPostalCode) {
      initializeFormCities(state?.cities || []);
    }
  }, [isTriggerByUserChangingPostalCode]);

  function validateCountryId(value: number) {
    const isCountrySelected = value !== 0;

    return isCountrySelected;
  }

  return (
    <>
      <Controller
        name={`${prefixName}countryId`}
        rules={{ required: true, validate: (value: number) => validateCountryId(value) }}
        render={({ field, fieldState }) => (
          <Select
            native
            label={formatMessage({ id: 'global.country' })}
            {...field}
            value={field.value || ''}
            onChange={(e) => {
              onCountryChange(e, setStateOptions, setCityOptions);
              setIsTriggerByUserChangingPostalCode(true);
            }}
            error={!!fieldState.error}
            disabled={isCountrySelectDisabled}
          >
            {renderCountriesOptions({ fieldValue: field.value, senderCountries })}
          </Select>
        )}
      />
      {isPostalCodeVisible && (
        <Controller
          name={`${prefixName}postalCode`}
          rules={{
            required: true,
            maxLength: 20,
            ...(strictFieldsValidation &&
              country?.postalCodeValidation?.regex && {
                pattern: new RegExp(country.postalCodeValidation.regex),
              }),
          }}
          render={({ field, fieldState }) => (
            <Input
              label={formatMessage({ id: `global.${countryRules.postalCode.translationKey}` })}
              {...field}
              value={field.value || ''}
              onChange={handlePostalCodeChange}
              maxLength={20}
              error={!!fieldState.error}
            />
          )}
        />
      )}
      {isStateVisible && (
        <Controller
          name={`${prefixName}state`}
          rules={{ required: country?.isStateRequired, maxLength: 100 }}
          render={({ field, fieldState }) =>
            countryRules.state.isDropdown ? (
              <Select
                native
                label={formatMessage({ id: 'global.state-province' })}
                flag={stateFieldFlag}
                loading={postalCodeMatchesQuery.isFetching}
                {...field}
                value={field.value || ''}
                error={!!fieldState.error}
                disabled={stateOptions.length === 0}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  setIsTriggerByUserChangingPostalCode(true);
                  handleStateChange();
                }}
              >
                {stateOptions.map((state) => (
                  <option key={state.name} value={state.name}>
                    {state.name}
                  </option>
                ))}
              </Select>
            ) : (
              <Input
                label={formatMessage({ id: 'global.state-province' })}
                flag={stateFieldFlag}
                loading={postalCodeMatchesQuery.isFetching}
                {...field}
                value={field.value || ''}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  setValue(`${prefixName}id`, '');
                }}
                error={!!fieldState.error}
                maxLength={100}
                disabled={strictFieldsValidation && countryRules.state.isDisabled}
              />
            )
          }
        />
      )}
      {renderHkDistrict(countryRules.hkDistrict.isVisibleForSender)}
      <Controller
        name={`${prefixName}city`}
        rules={{ required: true, maxLength: 100 }}
        render={({ field, fieldState }) =>
          countryRules.city.isDropdown ? (
            <Select
              native
              label={formatMessage({ id: `global.${countryRules.city.translationKey}` })}
              loading={postalCodeMatchesQuery.isFetching}
              {...field}
              value={field.value || ''}
              onChange={(e) => {
                field.onChange(e.target.value);
                setValue(`${prefixName}id`, '');
              }}
              error={!!fieldState.error}
              disabled={cityOptions.length === 0}
            >
              {cityOptions.map((city) => (
                <option key={city} value={city}>
                  {city}
                </option>
              ))}
            </Select>
          ) : (
            <Input
              label={formatMessage({ id: `global.${countryRules.city.translationKey}` })}
              loading={postalCodeMatchesQuery.isFetching}
              {...field}
              value={field.value || ''}
              onChange={(e) => {
                clearErrors([`${prefixName}city`]);
                field.onChange(e.target.value);
                setValue(`${prefixName}id`, '');
              }}
              error={!!fieldState.error}
              maxLength={100}
            />
          )
        }
      />
    </>
  );
}

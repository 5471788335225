import angular from 'angular';

import {
  IRegistrationData,
  IRegistrationErrorResponse,
  IRegistrationLoginParams,
  IRegistrationSuccessResponse,
  IRegistrationUpdateParams,
  IRegistrationFetchParams,
} from 'typings/auth/services/registration';
import { StateParamsPlatform } from '@client/auth/data/state';
import { BillingFrequency } from '@client/data/subscription';
import { COUNTRY_ID } from '@client/data/country';
import { RegistrationResource } from './registration.resource';

const DEFAULT_DATA: IRegistrationData = {
  user: {
    email: '',
    is_active: false,
    is_email_confirmed: false,
    role_id: 0,
    first_name: '',
    last_name: '',
    mobile_phone: '',
  },
  company: {
    is_active: false,
    features: [],
    platforms: [],
    couriers: [],
    signup_drivers: [],
    id: '',
  },
  address: {
    contact_name: '',
    contact_phone: '',
    contact_email: '',
    line_1: '',
    line_2: '',
    hk_district_id: 0,
    postal_code: '',
    city: '',
    state: '',
  },
  store: {},
  courier_account: {},
  incart_settings: {},
};

const WEBSITE_PLATFORM_NAME = 'easyship';

enum ImpactIntegrationCookieName {
  IrClickId = 'irclickid',
  MediaId = 'MediaId',
}

enum ImpactIntegrationPayloadName {
  IrClickId = 'impact_irclick_id',
  MediaId = 'impact_media_id',
}

enum CookiesKey {
  EasyshipOwnerId = 'easyship_owner_id',
}

class RegistrationService {
  data: IRegistrationData = DEFAULT_DATA;
  impactIntegrationCookieName = ImpactIntegrationCookieName;
  cookiesKey = CookiesKey;
  private _platform = WEBSITE_PLATFORM_NAME;

  static $inject = ['$stateParams', 'RegistrationResource', '$cookies'];
  constructor(
    public $stateParams: ng.ui.IStateParamsService,
    public RegistrationResource: RegistrationResource,
    private $cookies: angular.cookies.ICookiesService
  ) {}

  get platform(): string {
    return this._platform;
  }

  set platform(platform: string) {
    this._platform = platform === WEBSITE_PLATFORM_NAME ? StateParamsPlatform.Dashboard : platform;
  }

  signup(
    recaptchaResponse?: string,
    email?: string,
    planId?: number,
    billingFrequency?: BillingFrequency,
    password?: string
  ): ng.IPromise<IRegistrationSuccessResponse> {
    const { promo_code, voucher, parcelcast_plan, postageforce_plan } = this.$stateParams;
    const promoCode = promo_code || voucher;

    if (email) {
      this.data.user.email = email;
    }

    if (password) {
      this.data.user.password = password;
    }

    if (planId && billingFrequency) {
      this.data = {
        ...this.data,
        subscription: {
          default_plan_id: planId,
          billing_frequency: billingFrequency,
        },
      };
    }

    if (promoCode) {
      this.data.company.promo_code = promoCode;
    }

    if (parcelcast_plan) {
      this.data.company.parcelcast_plan = parcelcast_plan;
    }

    if (postageforce_plan) {
      this.data.company.postageforce_plan = postageforce_plan;
    }

    let flow = null;
    const isDuda = this.$stateParams.platform === StateParamsPlatform.Duda;
    const isUberFreight = this.$stateParams.platform === StateParamsPlatform.UberFreight;

    if (this.$stateParams.platform !== StateParamsPlatform.Website) {
      if (isDuda) {
        flow = 'duda';
      } else if (isUberFreight) {
        flow = 'uber_freight';
      } else {
        flow = this.$stateParams.platform;
      }
    } else {
      flow = null;
    }

    // Do not send the platform on signup if website
    return this.RegistrationResource.signup(
      {
        flow,
        token: recaptchaResponse,
      },
      {
        token: recaptchaResponse,
        registration: {
          data: this.data,
        },
      }
    );
  }

  login(
    params: IRegistrationLoginParams,
    password: string,
    recaptchaResponse?: string
  ): ng.IPromise<IRegistrationSuccessResponse> {
    const { plan } = this.$stateParams;
    const payload = {
      token: recaptchaResponse,
      user: {
        email: this.data.user.email,
        password,
      },
      plan,
    };

    return this.RegistrationResource.login(params, payload);
  }

  update(
    params: IRegistrationUpdateParams,
    fingerprintResult?: string
  ): ng.IPromise<IRegistrationSuccessResponse> {
    const irclickid = this.$cookies.get(ImpactIntegrationCookieName.IrClickId);
    if (irclickid) {
      this.modifyTrafficSource(ImpactIntegrationPayloadName.IrClickId, irclickid);
    }

    const mediaId = this.$cookies.get(ImpactIntegrationCookieName.MediaId);
    if (mediaId) {
      this.modifyTrafficSource(ImpactIntegrationPayloadName.MediaId, mediaId);
    }

    const easyshipOwnerId = this.$cookies.get(CookiesKey.EasyshipOwnerId);
    if (easyshipOwnerId) {
      this.modifyTrafficSource(CookiesKey.EasyshipOwnerId, easyshipOwnerId);
    }

    const newParams = params;
    if (params.platform === StateParamsPlatform.Duda) newParams.platform = 'duda';
    if (params.platform === StateParamsPlatform.UberFreight) newParams.platform = 'uber_freight';

    const payload = {
      registration: {
        data: this.data,
      },
    };

    return this.RegistrationResource.update(newParams, payload, fingerprintResult);
  }

  fetch(params: IRegistrationFetchParams): ng.IPromise<IRegistrationSuccessResponse> {
    return this.RegistrationResource.fetch(params);
  }

  completeWithCredential(
    params: IRegistrationFetchParams
  ): ng.IPromise<IRegistrationSuccessResponse> {
    return this.RegistrationResource.completeWithCredential(params);
  }

  // Prevent component from break as backend only saves data that has value
  normalizeAndUpdateData(data: IRegistrationData): void {
    this.data = angular.merge(DEFAULT_DATA, data);

    if (typeof this.data.address.country_id === 'number') {
      const countryId = this.data.address.country_id;
      this.prefillCity(countryId);
    }
  }

  prefillCity(countryId: number): void {
    const { city } = this.data.address;

    if (countryId && !city) {
      if (countryId === COUNTRY_ID.HongKong) {
        this.data.address.city = 'Hong Kong';
      } else if (countryId === COUNTRY_ID.Singapore) {
        this.data.address.city = 'Singapore';
      }
    }
  }

  // Get the actual platform value that we will pass to the backend
  // eg: if `signup` we need to pass `easyship`
  getBackendPlatformName(platform: string): string {
    let newPlatform = platform;
    if (platform === StateParamsPlatform.Duda) newPlatform = 'duda';
    if (platform === StateParamsPlatform.UberFreight) newPlatform = 'uber_freight';
    return platform === StateParamsPlatform.Dashboard ? WEBSITE_PLATFORM_NAME : newPlatform;
  }

  formatErrors(errorResponse: IRegistrationErrorResponse): string {
    switch (errorResponse.status) {
      case 400:
        const errors = errorResponse.data.error || errorResponse.data.data.error;
        const listHtml =
          typeof errors === 'string'
            ? `<li>${errors}</li>`
            : errors.map((text) => `<li>${text}</li>`).join('');

        return `<ul>${listHtml}</ul>`;
      case 401:
        return errorResponse.data.status;
      default:
        return '';
    }
  }

  private modifyTrafficSource(key: ImpactIntegrationPayloadName | CookiesKey, value: string): void {
    if (this.data.company.traffic_source) {
      this.data.company.traffic_source[key] = value;
    } else {
      this.data.company.traffic_source = {
        [key]: value,
      };
    }
  }
}

export { RegistrationService };

import angular from 'angular';

import './alert-message';
// TODO: Dependancies on app/services to migrate when app is migrated to src
import './items-per-page';
import './pagination';
import './pill';
import './tab';
import './tabs';
import './tooltip';
import './uploader';
import './progress';
import './label-field';
import esNicknamedAddress from './nicknamed-address';
import tagPill from './tag-pill';
import pdfEmbed from '../wrappers/components/AngularPdfEmbedComponent';
import statusChip from './status-chip';
import countryFlag from './country-flag';
import insuranceCard from '../wrappers/components/AngularInsuranceCard';
import liquidAndBatteryDeclarationCheckboxes from '../wrappers/components/AngularLiquidAndBatteryDeclaration';

import { AngularChangeCourierErrorMessage } from '../wrappers/components/AngularChangeCourierErrorMessage';

const MODULE_NAME = 'core.components.utilities';

angular
  .module(MODULE_NAME, [
    'core.components.alert-message',
    'core.components.items-per-page',
    'core.components.pagination',
    'core.components.pill',
    'core.components.tab',
    'core.components.tabs',
    'core.components.tooltip',
    'core.components.uploader',
    'core.components.progress',
    'core.components.label-field',
    esNicknamedAddress,
    tagPill,
    pdfEmbed,
    statusChip,
    countryFlag,
    insuranceCard,
    liquidAndBatteryDeclarationCheckboxes,
  ])
  .component('esChangeCourierErrorMessage', AngularChangeCourierErrorMessage);

export default MODULE_NAME;

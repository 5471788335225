import { UserSessionProvider } from '@app/contexts/providers/UserSessionProvider';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import React, { lazy, Suspense } from 'react';
import { react2angular } from 'react2angular';
import { InsuranceCardProps } from '@/components/InsuranceCard/types';

const InsuranceCard = lazy(() => import('@app/components/InsuranceCard'));

function WrappedInsuranceCard(props: InsuranceCardProps) {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <Suspense fallback={null}>
          <InsuranceCard {...props} className="mb-4" />
        </Suspense>
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularInsuranceCard = react2angular(WrappedInsuranceCard, [
  'handleInputChange',
  'valueSelected',
  'shipmentId',
  'showRadioError',
  'isInputDomesticValidationError',
]);

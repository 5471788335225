import React, { ReactElement } from 'react';
import { useFormContext, Controller, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import Card from '@material-ui/core/Card';
import CardHeader, { CardHeaderProps as MuiCardHeaderProps } from '@material-ui/core/CardHeader';
import CardContent, {
  CardContentProps as MuiCardContentProps,
} from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import useCountries from '@client/core/corelogic/useCountries';
import Input from '@client/core/components/react/Input';
import {
  CategoryHsCodeInput,
  CategoryHsCodeLabel,
} from '@client/src/global/components/category-hs-code';
import {
  CategorySelection,
  HsCodeSelection,
  CategoryHsCodeSelection,
} from '@client/src/global/components/category-hs-code/types';
import Select from '@client/core/components/react/Select';
import COLORS from '@client/src/colors';
import { ProductFormData } from '../reset-confirmation/ProductFormData';

interface ProductImportCardProps {
  $injector: angular.auto.IInjectorService;
  CardHeaderProps: MuiCardHeaderProps;
  CardContentProps: MuiCardContentProps;
}

export default function ProductImportCard({
  CardHeaderProps,
  CardContentProps,
}: ProductImportCardProps): ReactElement {
  const { data: countries = [] } = useCountries();
  const { formatMessage } = useIntl();
  const { control, setValue } = useFormContext<ProductFormData>();
  const productId = useWatch({ control, name: 'id' });
  const categoriesWithContainedBattery = [1, 2, 3, 4, 6];

  const handleOnCategorySelect = (categoryId: number) => {
    setValue('categoryHsCode', { categoryId } as CategorySelection, {
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue('contains_liquids', !!(categoryId === 7));
    setValue(
      'batteryType',
      categoriesWithContainedBattery.includes(categoryId) ? 'contained_battery' : ''
    );
  };

  const handleOnHsCodeSelect = (hsCodeNumber: string, hsCodeDescription: string) => {
    const values = { hsCodeNumber, hsCodeDescription } as HsCodeSelection;

    setValue('categoryHsCode', values, { shouldDirty: true, shouldValidate: true });
    setValue('contains_liquids', false);
    setValue('batteryType', '');
  };

  const validate = (value: CategoryHsCodeSelection) => {
    if (!value) return false;

    return 'categoryId' in value || 'hsCodeNumber' in value;
  };

  return (
    <Card>
      <CardHeader {...CardHeaderProps} />
      <CardContent {...CardContentProps}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box pb={0.625}>
              <CategoryHsCodeLabel />
            </Box>

            <Controller
              name="categoryHsCode"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <CategoryHsCodeInput
                    /**
                     * Add `key` to force the component to re-render and
                     * update the suggested HS code list when changing the product to edit
                     */
                    key={productId}
                    value={field.value}
                    onCategorySelect={handleOnCategorySelect}
                    onHsCodeSelect={handleOnHsCodeSelect}
                    error={!!fieldState.error}
                  />
                );
              }}
              rules={{ validate }}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              render={({ field, fieldState }) => (
                <Select
                  native
                  label={
                    <p className="mb-[7px]">{formatMessage({ id: 'global.country-origin' })}</p>
                  }
                  {...field}
                  error={!!fieldState.error}
                >
                  <option value="">{formatMessage({ id: 'global.unspecified' })}</option>
                  {countries.map((country) => (
                    <option value={country.id} key={country.id}>
                      {country.name}
                    </option>
                  ))}
                </Select>
              )}
              name="origin_country_id"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ field, fieldState }) => (
                <Input
                  label={formatMessage({ id: 'product-listing.details.description' })}
                  {...field}
                  error={!!fieldState.error}
                  maxLength={200}
                  multiline
                  placeholder={formatMessage({
                    id: 'product-listing.details.description-example',
                  })}
                />
              )}
              control={control}
              rules={{ maxLength: 200 }}
              name="name"
            />
            <Typography
              variant="body2"
              style={{
                marginTop: 10,
                color: COLORS.inkLight,
                fontStyle: 'italic',
              }}
            >
              <FormattedMessage id="product-listing.details.description-note" />
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

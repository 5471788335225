import React, { useEffect, useState } from 'react';

import { useSubscriptionService } from '@client/src/global/context/SubscriptionProvider';
import {
  IShippingCountryStandards,
  useUserSession,
} from '@client/src/global/context/UserSessionProvider';
import { WrappedCourierLogoAndNameComponent } from '@client/src/global/dashboard-component-library/CourierLogoAndNameComponent/CourierLogoAndNameComponent';
import { Alert, Card, CardContent, Select } from 'easyship-components';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { LyocAvailability, buildCourierData } from './utils';

interface OptionsProps<T> {
  label: string;
  value: T | null;
}
export interface CourierPerCountryComponentProps {
  esOnCourierSelect: (
    selectedCourier: LyocAvailability,
    selectedCountry: IShippingCountryStandards
  ) => void;
  esCloseCourierCreateModal: () => void;
  esShowUpgradeModal: () => void;
}

export function CourierPerCountryComponent({
  esOnCourierSelect,
  esCloseCourierCreateModal,
  esShowUpgradeModal,
}: CourierPerCountryComponentProps) {
  const { formatMessage } = useIntl();
  const { company } = useUserSession();
  const { currentSubscription, isMaxCourier } = useSubscriptionService();
  const [selectedCountry, setSelectedCountry] = useState<IShippingCountryStandards | undefined>(
    undefined
  );
  const [couriers, setCouriers] = useState<LyocAvailability[]>([]);
  const labellingFee = currentSubscription?.all_features.lyoc_labelling_fee?.limit || 0;
  const isShowingAddAddressMessage = !!company.dashboardSettings.beta_feature_global_account;

  const countriesOptions: OptionsProps<number>[] = Object.values(company.shippingCountries).map(
    (country) => ({
      label: country.name,
      value: country.id,
    })
  );
  const selectedValue: OptionsProps<number> | undefined =
    countriesOptions.length === 1 ? countriesOptions[0] : undefined;

  function handleOnCountrySelected(option: OptionsProps<number | null> | null): void {
    if (option && option.value) {
      const country = Object.values(company.shippingCountries).find(
        (country) => country.id === option.value
      );
      setSelectedCountry(country);
    }
  }

  function handleOnCourierClick(selectedCourier: LyocAvailability): void {
    if (isMaxCourier) {
      esCloseCourierCreateModal();
      esShowUpgradeModal();
      return;
    }
    if (selectedCountry) {
      esOnCourierSelect(selectedCourier, selectedCountry);
    }
  }
  useEffect(() => {
    if (countriesOptions.length === 1) {
      handleOnCountrySelected(countriesOptions[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      setCouriers(buildCourierData(selectedCountry));
    }
  }, [selectedCountry]);
  // TODO: change <Card> to <courier-card> when implemented react component implemented

  return (
    <div>
      <p className="!mb-[15px] text-base">
        <FormattedMessage id="courier.connect.select-country" />
      </p>
      {isShowingAddAddressMessage && (
        <Alert className="mb-6">
          <FormattedMessage
            id="courier.connect.add-address"
            values={{
              url: <a href="/account/addresses">here</a>,
            }}
          />
        </Alert>
      )}
      <div className="max-w-[260px] mb-6">
        <Select
          label={formatMessage({ id: 'global.country' })}
          onChange={(e, val) => {
            handleOnCountrySelected(val);
          }}
          options={countriesOptions}
          placeholder={formatMessage({ id: 'global.choose-one' })}
          value={selectedValue}
        />
      </div>
      {!!labellingFee && (
        <p className="!mb-[5] text-base font-bold">
          <FormattedMessage
            id="courier.connect.labelling-fee"
            values={{
              labellingFee: (
                <FormattedNumber
                  value={labellingFee}
                  style="currency"
                  currency={company.currency}
                  minimumFractionDigits={2}
                />
              ),
            }}
          />
        </p>
      )}
      {couriers && (
        <div className="easyship-smooth-show">
          <p className="!mb-[5] text-base">
            <FormattedMessage id="courier.connect.select-courier" />
          </p>

          {couriers.map((i) => (
            <Card
              className="min-h-[70px] !mt-[15px]"
              onClick={() => handleOnCourierClick(i)}
              variant="normal"
              key={i.name}
            >
              <CardContent className="py-[10px]">
                <WrappedCourierLogoAndNameComponent
                  esLogoSize="large"
                  esLogoUrl={i.logoUrl}
                  esName={i.name}
                />
              </CardContent>
            </Card>
          ))}
        </div>
      )}
    </div>
  );
}

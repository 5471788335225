import { ShipmentData } from '@client/core/corelogic/models/Shipment';
import { difference, get, intersection } from 'lodash';
import { ParcelData } from '@client/core/corelogic/models/parcel';
import { SectionName } from './types';

const PARCEL_MODIFIED_FIELDS = [
  { path: 'totalWeight', fieldName: 'Total weight' },
  { path: 'box.id', fieldName: 'Type' },
  { path: 'box.height', fieldName: 'Height' },
  { path: 'box.length', fieldName: 'Length' },
  { path: 'box.width', fieldName: 'Width' },
  { path: 'weightUnit', fieldName: 'Box Weight Unit' },
] as const;

interface MapParcelModifiedDataToMixpanel {
  previousShipmentData: ShipmentData;
  newShipmentData: ShipmentData;
}
export function mapParcelModifiedDataToMixpanel({
  previousShipmentData,
  newShipmentData,
}: MapParcelModifiedDataToMixpanel) {
  const SECTION_NAME: SectionName = 'Parcel details';
  const parcelFieldsModified: string[] = [];

  if (previousShipmentData.order.parcels.length !== newShipmentData.order.parcels.length) {
    parcelFieldsModified.push('Total count');
  }

  const previousParcelIds = previousShipmentData.order.parcels.map((parcel) => parcel.id);
  const newParcelIds = newShipmentData.order.parcels.map((parcel) => parcel.id);

  /** Add new parcels */
  const addedParcelIds = difference(newParcelIds, previousParcelIds);
  if (addedParcelIds.length > 0) {
    parcelFieldsModified.push('Parcel added');
  }

  /** Delete parcels */
  const deletedParcelIds = difference(previousParcelIds, newParcelIds);
  if (deletedParcelIds.length > 0) {
    parcelFieldsModified.push('Parcel deleted');
  }

  /** Update parcel */
  const updatedParcelIds = intersection(previousParcelIds, newParcelIds);

  updatedParcelIds.forEach((parcelId) => {
    const previousParcel = previousShipmentData.order.parcels.find(
      (parcel) => parcel.id === parcelId
    );
    const currentParcel = newShipmentData.order.parcels.find((parcel) => parcel.id === parcelId);

    PARCEL_MODIFIED_FIELDS.forEach(({ path, fieldName }) => {
      if (get(previousParcel, path) !== get(currentParcel, path)) {
        parcelFieldsModified.push(fieldName);
      }
    });
  });

  const boxWeightUnitCount = getBoxWeightUnitCount(newShipmentData.order.parcels);

  return {
    modifiedParcelFields: parcelFieldsModified.map((field) => `${SECTION_NAME} / ${field}`),
    totalBoxCount: newParcelIds.length,
    boxWeightOzCount: boxWeightUnitCount.oz || undefined,
    boxWeightLbCount: boxWeightUnitCount.lb || undefined,
    boxWeightKgCount: boxWeightUnitCount.kg || undefined,
    boxWeightGCount: boxWeightUnitCount.g || undefined,
  };
}

export function getBoxWeightUnitCount(parcels: ParcelData[]) {
  const defaultBoxWeightUnitCount = {
    g: 0,
    kg: 0,
    lb: 0,
    oz: 0,
  };

  return parcels.reduce((acc, parcel) => {
    const currentParcelWeightUnit = parcel.weightUnit;
    acc[currentParcelWeightUnit] += 1;
    return acc;
  }, defaultBoxWeightUnitCount);
}

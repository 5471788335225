import _ from 'lodash';
import template from './credit-card-select.html?raw';
import style from './credit-card-select.module.scss';

class CreditCardSelect {
  constructor() {
    this.style = style;
  }

  $onChanges(changesObj) {
    const { esSelectedCard } = changesObj;
    const newSelectedCard = esSelectedCard && esSelectedCard.currentValue;

    if (newSelectedCard && !esSelectedCard.isFirstChange()) {
      const card = this._findCard(newSelectedCard);
      this._handleSelectedCardUpdate(card);
    }
  }

  _findCard(cardId) {
    if (typeof cardId !== 'string') return cardId;

    return _.find(this.esCards, { source_id: cardId });
  }

  formatBrand(brand) {
    return brand.replace(/\s/g, '').toLowerCase();
  }

  isDeletable(card) {
    if (this.esDisableDelete) {
      return false;
    }

    return card.brand !== 'WeWork' && this.esCards.length > 1;
  }

  formatCardDigit(card) {
    if (card.brand === 'Diners Club') {
      return `XX-XX${card.last4}`;
    }

    if (card.brand === 'American Express') {
      return `XXX-X${card.last4}`;
    }

    return `XXXX-${card.last4}`;
  }

  getCardIcon(card) {
    const cardImgSrc = `${import.meta.env.VITE_APP_STORAGE}/credit-cards/`;
    switch (card.css_class) {
      case 'visa':
        return `${cardImgSrc}visa.svg`;
      case 'mastercard':
        return `${cardImgSrc}master.svg`;
      case 'amex':
        return `${cardImgSrc}amex.svg`;
      case 'discover':
        return `${cardImgSrc}discover.svg`;
      case 'dinersclub':
        return `${cardImgSrc}dinersclub.svg`;
      case 'jcb':
        return `${cardImgSrc}jcb.svg`;
      case 'unionpay':
        return `${cardImgSrc}unionpay.svg`;
      case 'wework':
        return `${cardImgSrc}wework-colour.svg`;
      default:
        return '';
    }
  }

  _handleSelectedCardUpdate(selectedCard) {
    if (selectedCard && selectedCard.last4 === null) {
      this.esOnSelectNewCard();
    }

    if (selectedCard && selectedCard.expired) return;
    this.esOnSelectCard({ card: selectedCard });
  }

  filterOutExpiredCards(cards) {
    return cards.filter((card) => !card.expired);
  }
}

const CreditCardSelectComponent = {
  controller: CreditCardSelect,
  template,
  bindings: {
    esCards: '<',
    esShowLoader: '<',
    esSelectedCard: '<',
    esOnSelectCard: '&',
    esOnClickCard: '&',
    esOnSelectNewCard: '&',
    esOnDeleteCard: '&',
    esDisableDelete: '<',
    esDisableAdding: '<',
    esIsSubscriptionForm: '<',
  },
};

export { CreditCardSelectComponent };

import React, { lazy, Suspense } from 'react';
import { react2angular } from 'react2angular';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import { UserSessionProvider } from '@client/src/global/context/UserSessionProvider';

// Lazy load the ShipmentsTable component
const SchedulePickupLaterButton = lazy(() => import('@/components/SchedulePickupLaterButton'));

function Wrapper(props: { disabled?: boolean }) {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <Suspense fallback={null}>
          <SchedulePickupLaterButton {...props} />
        </Suspense>
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

// Export as an Angular component using react2angular
export const AngularSchedulePickupLaterButton = react2angular(Wrapper, ['disabled'], []);

DashboardRoutes.$inject = ['$stateProvider'];
function DashboardRoutes($stateProvider) {
  $stateProvider
    .state('app.shipments', {
      // NOTE: The 'manage shipment' pages uses the `sectionName` to decide which shipment list to display.
      // Therefore, its logic for determining the requiredUserRight is different.
      // For more details, refer to the `getShipmentListSectionUserRight` function.
      url: '^/shipments/:sectionName?page&batch_id&pickup_id&last_status_message_id&from_handover&shipment_id&managetour&sidebaropen&trackingtour&appcue&view_id&claim&direct_print_id',
      component: 'esManageShipmentsLanding',
      params: {
        sectionName: {
          value: 'shipments-all',
          squash: true,
        },
        easyship_shipment_ids: null,
        reload: false,
        query: null,
        purchasedLabelSuccess: false,
      },
      reloadOnSearch: false,
      resolve: {
        previousState: [
          'uibRoutingOptions',
          (uibRoutingOptions) => {
            return uibRoutingOptions.previousState();
          },
        ],
        checkSectionName: [
          'EndpointService',
          'HelperService',
          '$state',
          '$stateParams',
          (EndpointService, HelperService, $state, $stateParams) => {
            const currentSection = HelperService.kebabToSnakeCase($stateParams.sectionName);

            // When currentSection param is not valid or not specified, redirect with a clean slate
            if (EndpointService.availableScopes.indexOf(currentSection) < 0) {
              $state.go('app.shipments', { query: { scope: 'shipments_all' } });
            }
          },
        ],
        displayShipmentModal: [
          '$stateParams',
          'showShipmentModal',
          ($stateParams, showShipmentModal) => {
            if ($stateParams.shipment_id) {
              showShipmentModal.open($stateParams.shipment_id, 0);
            }
          },
        ],
      },
    })
    .state('app.returns', {
      url: '^/returns?shipment_id',
      component: 'esManageShipmentsLanding',
      params: {
        sectionName: {
          value: 'shipments-all',
        },
      },
      reloadOnSearch: false,
      resolve: {
        checkReturnFeatureSupport: [
          '$state',
          'UserSession',
          ($state, UserSession) => {
            if (!UserSession.hasReturnFeatureSupport()) {
              $state.go('app.shipments');
            }
          },
        ],
        displayShipmentModal: [
          '$stateParams',
          'showShipmentModal',
          ($stateParams, showShipmentModal) => {
            if ($stateParams.shipment_id) {
              showShipmentModal.open($stateParams.shipment_id, 0);
            }
          },
        ],
      },
      data: {
        requiredUserRight: 'menu.returns',
      },
    })
    .state('printing-options', {
      url: '/printing-options',
      parent: 'app.tools',
      component: 'esToolsPrinting',
      data: {
        requiredUserRight: 'menu.settings.printingOptions',
      },
    })
    .state('tracking-page', {
      url: '/tracking-page',
      parent: 'app.tools',
      component: 'trackingPage',
      data: {
        requiredUserRight: 'menu.settings.trackingPage',
      },
    })
    .state('customer-emails', {
      url: '/customer-emails',
      parent: 'app.tools',
      component: 'customerEmails',
      data: {
        requiredUserRight: 'menu.settings.emails',
      },
    })
    .state('packing', {
      url: '/packing?appcue',
      parent: 'app.tools',
      component: 'packingSlip',
      data: {
        requiredUserRight: 'menu.settings.packingSlip',
      },
    })
    .state('boxes', {
      url: '/boxes?appcue',
      parent: 'app.tools',
      component: 'boxes',
      data: {
        requiredUserRight: 'menu.settings.boxes',
      },
    })
    .state('insurance', {
      url: '/insurance?appcue',
      parent: 'app.tools',
      component: 'insurance',
      data: {
        requiredUserRight: 'menu.settings.insurance',
      },
    })
    .state('notifications', {
      url: '/notifications?appcue',
      parent: 'app.tools',
      component: 'notifications',
      data: {
        requiredUserRight: 'menu.settings.notifications',
      },
    })
    .state('app.reschedule-pickup', {
      url: '^/reschedule-pickup',
      component: 'reschedulePickup',
    })
    .state('redirection', { url: '^/redirection/:platformName', component: 'redirection' })
    .state('log-in-as', {
      url: '^/log-in-as?jwt',
      component: 'logInAsComponent',
      authenticate: false,
    })
    // To be removed once the new enterprise login (redirect-login) is fully implemented
    .state('enterprise-login', {
      url: '^/enterprise/login?jwt',
      component: 'logInAsComponent',
      authenticate: false,
    })
    .state('redirect-login', {
      url: '^/redirect/login?jwt',
      component: 'logInAsComponent',
      authenticate: false,
    })
    .state('app.couriers', {
      url: '^/couriers?new&courier_id&show_step&appcue&code&state',
      reloadOnSearch: false,
      component: 'couriers',
      data: {
        requiredUserRight: 'menu.couriers',
      },
    })
    .state('app.couriers-redirect', {
      url: '^/couriers/:courier_id/redirect',
      component: 'esCourierRedirect',
      params: {
        courier_id: {
          value: '',
          squash: true,
        },
      },
    })
    .state('app.single-store', {
      url: '^/store/:id?expand&refetch&new&platform',
      component: 'esStoreSettingsPage',
      data: {
        requiredUserRight: 'menu.connect.all',
      },
    })
    .state('app.webhooks', {
      url: '^/webhooks',
      component: 'webhooks',
      data: {
        requiredUserRight: 'menu.connect.webhooks',
      },
    })
    .state('app.ups-dap-agreement', {
      url: '^/couriers/ups/dap-agreement?countries',
      component: 'couriers',
      params: {
        openExternalUpsDapModal: true,
      },
    });
}

export { DashboardRoutes };

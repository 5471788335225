import { Chip } from 'easyship-components';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { react2angular } from 'react2angular';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import { UserSessionProvider } from '@client/src/global/context/UserSessionProvider';
import {
  SubscriptionServiceProvider,
  useSubscriptionService,
} from '@client/src/global/context/SubscriptionProvider';
import {
  CourierPerCountryComponent,
  CourierPerCountryComponentProps,
} from '../AngularCourierPerCountryComponent/CourierPerCountryComponent';

interface ChooseCourierComponentProps extends CourierPerCountryComponentProps {
  esOnContactUsClick: () => void;
}

function ChooseCourierComponent({
  esOnCourierSelect,
  esCloseCourierCreateModal,
  esShowUpgradeModal,
  esOnContactUsClick,
}: ChooseCourierComponentProps) {
  const { getPlanNameByFeatureKey, isMaxCourier } = useSubscriptionService();
  const planName = getPlanNameByFeatureKey('lyoc');

  const { formatMessage } = useIntl();
  return (
    <div className="ChooseCourierComponent">
      <div className="modal-header">
        <FormattedMessage id="courier.connect.header" />
        {isMaxCourier && (
          <Chip color="teal" className="!ml-[5px] !px-2" rounded>
            {planName}
          </Chip>
        )}
      </div>
      <div className="pt-5 px-12 pb-7 flex flex-col items-start">
        <CourierPerCountryComponent
          esCloseCourierCreateModal={esCloseCourierCreateModal}
          esOnCourierSelect={esOnCourierSelect}
          esShowUpgradeModal={esShowUpgradeModal}
        />
        <p className="!mt-5 text-base">
          <FormattedMessage id="courier.connect.missing" />
          <button
            type="button"
            onClick={esOnContactUsClick}
            className="!ml-[5px] text-blue-500 hover:text-blue-700"
          >
            {formatMessage({ id: 'courier.connect.suggest' })}
          </button>
        </p>
      </div>
    </div>
  );
}

export function WrappedChooseCourierComponent(props: ChooseCourierComponentProps) {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <SubscriptionServiceProvider>
          <ChooseCourierComponent {...props} />
        </SubscriptionServiceProvider>
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularChooseCourierComponent = react2angular(WrappedChooseCourierComponent, [
  'esOnCourierSelect',
  'esCloseCourierCreateModal',
  'esShowUpgradeModal',
  'esOnContactUsClick',
]);

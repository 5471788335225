import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MenuList } from '@material-ui/core';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';

import MixpanelService from '@client/core/services/mixpanel/mixpanel.service';
import {
  UserSessionProvider,
  useUserSession,
} from '@client/src/global/context/UserSessionProvider';
import PrintAllDocsMenuItem from '../menu-items/PrintAllDocsMenuItem';
import PrintCommercialInvoiceMenuItem from '../menu-items/PrintCommercialInvoiceMenuItem';
import DuplicateShipmentMenuItem from '../menu-items/DuplicateShipmentMenuItem';
import RescheduleHandoverMenuItem from '../menu-items/RescheduleHandoverMenuItem';
import SchedulePickupMenuItem from '../menu-items/SchedulePickupMenuItem';
import EditShipmentMenuItem from '../menu-items/EditShipmentMenuItem';
import InsuranceClaimMenuItem from '../menu-items/InsuranceClaimMenuItem';
import MarkAsDeliveredMenuItem from '../menu-items/MarkAsDeliveredMenuItem';
import ReturnLabelMenuItem from '../menu-items/ReturnLabelMenuItem';
import CancelShipmentMenuItem from '../menu-items/CancelShipmentMenuItem';
import { ShipmentActionProps } from '../types';
import { actionPanel } from '../styles';
import { SPEED_DIALS_MIXPANEL_EVENTS_NAMES } from '../speedDialsMixpanelEvents';

export default function ShipmentActionPanel({
  onCancelShipment,
  onPrintAllLabels,
  onPrintCommercialInvoice,
  onDuplicateShipment,
  onRescheduleHandover,
  onCreateReturnLabel,
  onMarkAsDelivered,
  onEditShipment,
  onStartInsuranceClaim,
  onScheduleHandover,
  markAsDeliveredModalIsOpen,
  markAsDeliveredModalOnClose,
  markAsDeliveredModalOnConfirm,
  shipmentListItem,
}: ShipmentActionProps) {
  const { user } = useUserSession();
  const showDuplicateActionButton: boolean = user.hasUserRole('create_shipments');

  return (
    <div style={actionPanel}>
      <h2 className="text-left font-bold text-ink-900">
        <FormattedMessage id="shipments.speed-dial.actions" />
      </h2>
      <MenuList>
        <PrintAllDocsMenuItem
          shipmentListItem={shipmentListItem}
          onPrintAllLabels={() => {
            onPrintAllLabels();

            MixpanelService.track(
              SPEED_DIALS_MIXPANEL_EVENTS_NAMES.fromShipmentDetailsPagePanel.printShippingDocument,
              {
                easyship_shipment_id: shipmentListItem.easyship_shipment_id,
              }
            );
          }}
        />

        <PrintCommercialInvoiceMenuItem
          shipmentListItem={shipmentListItem}
          onPrintCommercialInvoice={() => {
            onPrintCommercialInvoice();

            MixpanelService.track(
              SPEED_DIALS_MIXPANEL_EVENTS_NAMES.fromShipmentDetailsPagePanel.printCommercialInvoice,
              {
                easyship_shipment_id: shipmentListItem.easyship_shipment_id,
              }
            );
          }}
        />
        {showDuplicateActionButton && (
          <DuplicateShipmentMenuItem
            shipmentListItem={shipmentListItem}
            onDuplicateShipment={async () => {
              await onDuplicateShipment();

              MixpanelService.track(
                SPEED_DIALS_MIXPANEL_EVENTS_NAMES.fromShipmentDetailsPagePanel.duplicateShipment,
                {
                  easyship_shipment_id: shipmentListItem.easyship_shipment_id,
                }
              );
            }}
          />
        )}

        <RescheduleHandoverMenuItem
          shipmentListItem={shipmentListItem}
          onRescheduleHandover={() => {
            onRescheduleHandover();

            MixpanelService.track(
              SPEED_DIALS_MIXPANEL_EVENTS_NAMES.fromShipmentDetailsPagePanel.rescheduleHandover,
              {
                easyship_shipment_id: shipmentListItem.easyship_shipment_id,
              }
            );
          }}
        />

        <SchedulePickupMenuItem
          shipmentListItem={shipmentListItem}
          onScheduleHandover={() => onScheduleHandover()}
        />
        <EditShipmentMenuItem
          shipmentListItem={shipmentListItem}
          onEditShipment={async () => {
            await onEditShipment();

            MixpanelService.track(
              SPEED_DIALS_MIXPANEL_EVENTS_NAMES.fromShipmentDetailsPagePanel.editShipment,
              {
                easyship_shipment_id: shipmentListItem.easyship_shipment_id,
              }
            );
          }}
        />

        <InsuranceClaimMenuItem
          shipmentListItem={shipmentListItem}
          onStartInsuranceClaim={async () => {
            await onStartInsuranceClaim();

            MixpanelService.track(
              SPEED_DIALS_MIXPANEL_EVENTS_NAMES.fromShipmentDetailsPagePanel.claimInsurance,
              {
                easyship_shipment_id: shipmentListItem.easyship_shipment_id,
              }
            );
          }}
        />

        <MarkAsDeliveredMenuItem
          shipmentListItem={shipmentListItem}
          onMarkAsDelivered={() => {
            onMarkAsDelivered();

            MixpanelService.track(
              SPEED_DIALS_MIXPANEL_EVENTS_NAMES.fromShipmentDetailsPagePanel.markAsDelivered,
              {
                easyship_shipment_id: shipmentListItem.easyship_shipment_id,
              }
            );
          }}
          markAsDeliveredModalIsOpen={markAsDeliveredModalIsOpen}
          markAsDeliveredModalOnClose={markAsDeliveredModalOnClose}
          markAsDeliveredModalOnConfirm={markAsDeliveredModalOnConfirm}
        />

        <ReturnLabelMenuItem
          shipmentListItem={shipmentListItem}
          onCreateReturnLabel={async () => {
            await onCreateReturnLabel();

            MixpanelService.track(
              SPEED_DIALS_MIXPANEL_EVENTS_NAMES.fromShipmentDetailsPagePanel.createReturnLabel,
              {
                easyship_shipment_id: shipmentListItem.easyship_shipment_id,
              }
            );
          }}
        />

        <CancelShipmentMenuItem
          shipmentListItem={shipmentListItem}
          onCancelShipment={() => {
            onCancelShipment();

            MixpanelService.track(
              SPEED_DIALS_MIXPANEL_EVENTS_NAMES.fromShipmentDetailsPagePanel.cancel,
              {
                easyship_shipment_id: shipmentListItem.easyship_shipment_id,
              }
            );
          }}
        />
      </MenuList>
    </div>
  );
}

export function WrappedShipmentActionPanel(props: ShipmentActionProps) {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <ShipmentActionPanel {...props} />
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

import iconShippingRuleUrl from '@assets/images/dashboard/connect/icon-shipping-rule.svg';
import iconEmptyRuleUrl from '@assets/images/dashboard/connect/icon-empty-rule.svg';
import template from './single-store-shipping-rules.html?raw';

(function () {
  angular
    .module('easyshipDashboardApp')
    .directive('singleStoreShippingRules', singleStoreShippingRules);

  function singleStoreShippingRules() {
    const directive = {
      template,
      restrict: 'E',
      controller,
      controllerAs: 'vm',
      scope: {
        automations: '<',
      },
    };

    return directive;
  }

  controller.$inject = ['$timeout', 'StoreService', 'UserSession', 'SubscriptionService', 'API'];

  function controller($timeout, StoreService, UserSession, SubscriptionService, API) {
    const vm = this;

    vm.StoreService = StoreService;
    vm.UserSession = UserSession;
    vm.SubscriptionService = SubscriptionService;
    vm.API = API;
    vm.iconShippingRuleUrl = iconShippingRuleUrl;
    vm.iconEmptyRuleUrl = iconEmptyRuleUrl;

    vm.busy = {};
    vm.wrappers = {
      b(chunk) {
        return `<span class="font-bold text-ink-900">${chunk}</span>`;
      },
      a(text) {
        return `<a ng-href="${vm.API.dashboard}/account/subscription?open=compare">${text}</a>`;
      },
    };

    vm.storeHasCheckoutEnabled = function () {
      return (
        vm.StoreService.activeStore.is_rates_enabled ||
        (vm.StoreService.activeStore.oauth_applications &&
          vm.StoreService.activeStore.oauth_applications.production.token &&
          vm.StoreService.activeStore.platform.credentials_retrieval_method === 'user_input')
      );
    };

    vm.isPlanBadgeVisible = function (featureKey) {
      return vm.SubscriptionService.isPlanBadgeVisible(featureKey);
    };

    vm.getPlanNameByFeatureKey = function (featureKey) {
      return vm.SubscriptionService.getPlanNameByFeatureKey(featureKey);
    };

    vm.suggestedPlanId = function () {
      return vm.SubscriptionService.getSuggestedPlanIdByFeatureKey('shipping_rules');
    };

    vm.isFeatureAccessible = function () {
      return vm.SubscriptionService.isFeatureAccessible('shipping_rules');
    };

    vm.openUpgradeModalIfFeatureNotAccessible = function () {
      vm.SubscriptionService.openUpgradeModalIfFeatureNotAccessible(
        'shipping_rules',
        'Shipping Rules'
      );
    };

    vm.hasShippingRulesUserRole = function () {
      return vm.UserSession.hasUserRole('shipping_rules');
    };

    $timeout(function () {
      $('.isotope-grid').isotope({
        itemSelector: '.isotope-grid-item',
        masonry: {
          columnWidth: 300,
          gutter: 20,
        },
      });
    });
  }
})();

import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { FormattedMessage } from 'react-intl';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import { UserSessionProvider } from '@client/src/global/context/UserSessionProvider';
import { FeatureKey } from '@client/data/subscription';
import { ISubscriptionFeatureRecord } from 'typings/subscription';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import { react2angular } from 'react2angular';
import { SubscriptionService } from '@client/src/global/services/subscription/subscription.service';
import { HelpContactSupportFeature } from './types';
import FeatureAvatar from './FeatureAvatar';
import { FeatureDescription } from './FeatureDescription';
import FeatureChip from './FeatureChip';
import { Footer } from './Footer';

const useStyles = makeStyles(() => ({
  cardContent: {
    padding: '32px',
  },
}));

const FEATURES: HelpContactSupportFeature[] = ['email', 'chat', 'call'];
const FEATURE_KEY_MAP: Record<HelpContactSupportFeature, FeatureKey> = {
  email: 'email_support',
  chat: 'chat_support',
  call: 'phone_support',
};

interface HelpContactSupportProps {
  allFeatures: ISubscriptionFeatureRecord | undefined;
  isPlanBadgeVisible: SubscriptionService['isPlanBadgeVisible'];
  getPlanNameByFeatureKey: SubscriptionService['getPlanNameByFeatureKey'];
  onChatClick: () => void;
  onUpgradeClick: (featureKey: FeatureKey) => void;
}

export function HelpContactSupport({
  allFeatures,
  isPlanBadgeVisible,
  getPlanNameByFeatureKey,
  onChatClick,
  onUpgradeClick,
}: HelpContactSupportProps) {
  const classes = useStyles();
  const hasLoaded = !!allFeatures;

  if (!hasLoaded) {
    return null;
  }

  function checkIsAccessible(featureKey: FeatureKey): boolean {
    return !!allFeatures?.[featureKey]?.is_accessible;
  }

  function getPlanLabel(featureKey: FeatureKey) {
    return getPlanNameByFeatureKey(featureKey);
  }

  return (
    <Grid container spacing={2}>
      {FEATURES.map((feature) => {
        return (
          <Grid key={feature} item xs={4}>
            <Box
              clone
              height="100%"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Card>
                <CardContent className={classes.cardContent}>
                  <FeatureAvatar
                    feature={feature}
                    isAccessible={checkIsAccessible(FEATURE_KEY_MAP[feature])}
                  />
                  <Box clone pb={1.25}>
                    <Typography variant="h4">
                      <FormattedMessage id={`help-guide.contact-support.${feature}.title`} />
                      <FeatureChip
                        visible={isPlanBadgeVisible(FEATURE_KEY_MAP[feature])}
                        planLabel={getPlanLabel(FEATURE_KEY_MAP[feature])}
                      />
                    </Typography>
                  </Box>
                  <Box clone width={90}>
                    <Divider light />
                  </Box>
                  <Box pt={2.25}>
                    <Typography component="div" variant="body2">
                      <FeatureDescription
                        feature={feature}
                        hasCallSupport={checkIsAccessible('phone_support')}
                      />
                    </Typography>
                  </Box>
                </CardContent>
                <Footer
                  feature={feature}
                  isAccessible={checkIsAccessible(FEATURE_KEY_MAP[feature])}
                  onChatClick={onChatClick}
                  onUpgradeClick={() => onUpgradeClick(FEATURE_KEY_MAP[feature])}
                />
              </Card>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
}

function WrappedHelpContactSupport(
  props: HelpContactSupportProps
): ReactElement<HelpContactSupportProps> {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <HelpContactSupport {...props} />
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularHelpContactSupport = react2angular(WrappedHelpContactSupport, [
  'onChatClick',
  'onUpgradeClick',
  'isPlanBadgeVisible',
  'getPlanNameByFeatureKey',
  'allFeatures',
]);

import { ICourierAccountsService } from 'typings/courier';
import { IUserSession } from 'typings/user-session';

import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { toastError } from '@client/core/components/react/Toastify';
import { CourierLogo } from '@client/data/courier';
import template from './courier-redirect.html?raw';
import style from './courier-redirect.module.scss';
// keep this hardcoded until the redirect link is fixed
const MY_POST_BUSINESS_UMBRELLA_NAME = 'australia-post-mypost-business';

class CourierRedirect implements IComponentController {
  style = style;
  urlParams = this.$location.search();

  static $inject = [
    '$state',
    '$stateParams',
    '$document',
    '$location',
    'CourierAccounts',
    '$window',
    'UserSession',
    'MixpanelService',
  ];
  constructor(
    private $state: ng.ui.IStateService,
    private $stateParams: ng.ui.IStateParamsService,
    private $document: ng.IDocumentService,
    private $location: ng.ILocationService,
    private CourierAccounts: ICourierAccountsService,
    private $window: ng.IWindowService,
    private UserSession: IUserSession,
    private MixpanelService: MixpanelService
  ) {}

  $onInit() {
    this.$document.find('#app__initial-load').remove();
    this.addCourier();
  }

  private addCourier() {
    const isAuPostMyPostBusiness = this.urlParams.umbrella_name === MY_POST_BUSINESS_UMBRELLA_NAME;
    const isCanadaPost = !!this.urlParams['token-id'] && !!this.urlParams['registration-status'];

    if (isCanadaPost) {
      this.processCanadaPost();
      return;
    }

    if (isAuPostMyPostBusiness) {
      this.processMyPostBusiness();
    }
  }

  private processMyPostBusiness() {
    const australiaCountry = this.UserSession.company.shipping_countries.find(
      ({ name }) => name === 'Australia'
    );

    const goCourierPage = () => {
      this.$state.go('app.couriers');
    };

    const payload = {
      account: {
        umbrella_name: CourierLogo.MyPost,
        code: this.urlParams.code ?? '',
        nickname:
          this.urlParams.nickname ??
          this.$window.localStorage.getItem('myPostBusinessUserAccountName'),
        origin_country_id: australiaCountry?.id,
      },
    };

    this.CourierAccounts.connectAuMyPostBusinessCode(payload)
      .then(() => {
        this.MixpanelService.track('Courier - Add Service - Connect - Success', {
          courier_name: CourierLogo.MyPost,
        });
      })
      .catch((error) => {
        toastError(error.data && error.data.status);
        this.MixpanelService.track('Courier - Add Service - Connect - Failure', {
          courier_name: CourierLogo.MyPost,
        });
      })
      .finally(() => {
        goCourierPage();
      });
  }

  private processCanadaPost() {
    const courierId = this.$stateParams.courier_id;
    const urlParams = this.$location.search();
    const goToCourierPageAndOpenEditModal = () => {
      this.$state.go('app.couriers', {
        courier_id: courierId,
      });
    };

    if (urlParams['registration-status'] !== 'SUCCESS') {
      goToCourierPageAndOpenEditModal();
    }

    this.CourierAccounts.updateCourierInfo({
      id: courierId,
      account: {
        umbrella_name: 'canada-post',
        token_id: urlParams['token-id'],
        registration_status: urlParams['registration-status'],
      },
    })
      .then(() => {
        this.$state.go('app.couriers', {
          courier_id: courierId,
          show_step: 'success',
        });
      })
      .catch(() => {
        goToCourierPageAndOpenEditModal();
      });
  }
}

const CourierRedirectComponent: ng.IComponentOptions = {
  controller: CourierRedirect,
  template,
  bindings: {},
};

export { CourierRedirectComponent };

import React, { lazy, Suspense } from 'react';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import { react2angular } from 'react2angular';
import { UserSessionProvider } from '@client/src/global/context/UserSessionProvider';
import { ChartComponentParams } from '@app/features/ChartComponent/types';
import { Pulse } from 'easyship-components';

const ChartComponent = lazy(() => import('@app/features/ChartComponent'));
function WrappedChartComponent(props: ChartComponentParams) {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <Suspense fallback={<Pulse />}>
          <ChartComponent {...props} />
        </Suspense>
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularChartComponent = react2angular(WrappedChartComponent, [
  'dateParams',
  'scopes',
  'emptyState',
  'onGraphClick',
]);

import React from 'react';
import connectSuccessImageUrl from '@assets/images/dashboard/connect-courier-modal/connect-success.svg';
import connectFailImageUrl from '@assets/images/dashboard/connect-courier-modal/connect-fail.svg';
import { Alert } from 'easyship-components';
import { FormattedMessage } from 'react-intl';

export interface CourierConnectResultContentProps {
  nickname: string;
  errorMessage?: string;
  isSuccess: boolean;
}
// Modal window content
export function CourierConnectResultContent({
  nickname,
  errorMessage,
  isSuccess,
}: CourierConnectResultContentProps) {
  return (
    <main className="px-12 py-10 text-lg text-ink-900">
      <div className="flex items-center justify-center mb-6">
        {isSuccess ? (
          <img src={connectSuccessImageUrl} alt="Connect success icon" />
        ) : (
          <img src={connectFailImageUrl} alt="Connect fail icon" />
        )}
      </div>
      <p className="text-center m-0">
        {isSuccess ? (
          <FormattedMessage id="courier.connect.success.settings" values={{ nickname }} />
        ) : (
          <FormattedMessage id="courier.connect.fail" />
        )}
      </p>
      {errorMessage && (
        <Alert severity="error" className="!mt-4">
          {errorMessage}
        </Alert>
      )}
    </main>
  );
}

import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { IShipmentListItem } from '@typings/shipment';
import CellLayout from './CellLayout';

const COLLECT_STATE_TRANSLATION_PREFIX = 'shipments.collection';

const STATUS_MAP = {
  collected: {
    colorClass: 'bg-green-500',
    translationId: `${COLLECT_STATE_TRANSLATION_PREFIX}.collected`,
  },
  to_be_collected: {
    colorClass: 'bg-red-500',
    translationId: `${COLLECT_STATE_TRANSLATION_PREFIX}.to_be_collected`,
  },
  none: {
    colorClass: 'bg-ink-100',
    translationId: `${COLLECT_STATE_TRANSLATION_PREFIX}.none`,
  },
};

const CollectStatusCell = memo(({ state }: { state: IShipmentListItem['collect_state'] }) => {
  const status = STATUS_MAP[state as keyof typeof STATUS_MAP] || STATUS_MAP.none;

  return (
    <CellLayout className="flex px-2 min-w-[132px] gap-2 items-center text-ink-900">
      <div className={`w-[14px] h-[14px] rounded-full ${status.colorClass}`} />
      <FormattedMessage id={status.translationId} />
    </CellLayout>
  );
});

export default CollectStatusCell;

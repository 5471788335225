/* eslint-disable no-param-reassign */
import { toastError } from '@client/core/components/react/Toastify';
import { getLocationPathName } from '@app/utils/routing';

(function () {
  angular
    .module('easyshipDashboardApp')
    .controller('SingleShipmentReceiverInfoCtrl', SingleShipmentReceiverInfoCtrl);

  SingleShipmentReceiverInfoCtrl.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$q',
    '$translate',
    'UserSession',
    'EndpointService',
    'CountryService',
    'AddressService',
    'resumeDraftModal',
    'PostalCodeService',
    'ShipmentAction',
    'MixpanelService',
    'DestinationAddressesService',
  ];

  function SingleShipmentReceiverInfoCtrl(
    $scope,
    $state,
    $stateParams,
    $q,
    $translate,
    UserSession,
    EndpointService,
    CountryService,
    AddressService,
    resumeDraftModal,
    PostalCodeService,
    ShipmentAction,
    MixpanelService,
    DestinationAddressesService
  ) {
    $scope.EndpointService = EndpointService;
    $scope.UserSession = UserSession;
    $scope.CountryService = CountryService;
    $scope.DestinationAddressesService = DestinationAddressesService;

    $scope.isLuxUser = false;
    $scope.isShowReceiverForm = false;
    $scope.isShowLuxotticaCustomForm = false;
    $scope.shipment = {};
    $scope.busy = { page: true, address: false };
    $scope.lengthErrorMessage = {};
    $scope.suburbs = [];
    $scope.originCountryAlpha2 = '';

    $scope.postalMessages = {
      showEmptyPostalCode: false,
      showRegexMismatch: false,
      showStateMismatch: false,
    };

    $scope.maxLength = {
      destination_name: 50,
      phone_number: 25,
      email_address: 50,
      address_line_1: 100,
      address_line_2: 100,
      city: 40,
      postal_code: 20,
      state: 30,
    };

    let tempParcelsAttributes = null;
    const UNITED_STATES_ID = 234;
    const AUSTRALIA_ID = 14;
    let defaultPickupAddress = null;

    (function init() {
      $q.all([CountryService.getCountries(), AddressService.getAddresses()]).then(function () {
        initShipment();
        $scope.busy.page = false;
      });

      $scope.isLuxUser = UserSession.isLuxUser();

      if ($scope.isLuxUser) {
        $scope.DestinationAddressesService.resetLuxotticaShipmentMetadata($scope.shipment);
        $scope.DestinationAddressesService.resetLuxotticaCustomFlow();
        $scope.DestinationAddressesService.resetSelectedSearchAddress();
        $scope.DestinationAddressesService.setAddressDataToShipment(
          $scope.shipment,
          $scope.DestinationAddressesService.luxotticaDefaultAddress
        );
      } else {
        $scope.isShowReceiverForm = true;
      }
    })();

    function initShipment() {
      setDefaultPickupAddress();

      if ($stateParams.shipment) {
        $scope.shipment = $stateParams.shipment;

        $stateParams.shipment.destination_country = findCountryObject(
          $stateParams.shipment.destination_country_id
        );

        // Sets country alpha2 param needed for Luxottica request to be restricted to origin countries
        if ($scope.isLuxUser) {
          setOriginCountryAlpha2();
        }

        // If shipment is to Australia and user is coming back then repopulate suburbs array
        // and checks if there is a destination_city key and if so then to reassign it to as city key
        if ($scope.shipment.destination_country_id === AUSTRALIA_ID) {
          if ($stateParams.shipment.destination_city)
            $scope.shipment.city = $stateParams.shipment.destination_city;
          findStateWithPostalCode($scope.shipment);
        }

        // If an array called tempParcelsAttributes exists then save it to tempParcelsAttributes
        if ($stateParams.shipment.tempParcelsAttributes) {
          tempParcelsAttributes = $stateParams.shipment.tempParcelsAttributes;
        }

        // Need to run these two functions to enable postal code logic otherwise validatePostalCode won't run
        checkIfPostalCodeIsRequiredForDestination($stateParams.shipment);

        prefillCityAndState();
      } else {
        if (defaultPickupAddress) {
          setOriginCountryAlpha2();
          $scope.shipment.destination_country = CountryService.findCountry(
            defaultPickupAddress ? defaultPickupAddress.country_id : UserSession.company.country_id
          );
        }

        if (['US', 'AU'].indexOf(_.get($scope.shipment, 'destination_country.alpha2')) > -1) {
          checkIfPostalCodeIsRequiredForDestination($scope.shipment);
        }
      }

      // Draft exists && no shipment passed into the state (quote, press back etc.) => Open modal
      if (!$stateParams.shipment && UserSession.company.has_pickup_address) {
        getTheLastDraft();
      }
    }

    function setDefaultPickupAddress() {
      defaultPickupAddress = AddressService.getDefaultPickupAddress();
    }

    function setOriginCountryAlpha2() {
      if (defaultPickupAddress) {
        $scope.originCountryAlpha2 = defaultPickupAddress.country.alpha2;
      }
    }

    $scope.isLuxotticaFlowSelected = function () {
      return $scope.isLuxUser && $scope.DestinationAddressesService.selectedLuxotticaFlow;
    };

    $scope.next = function () {
      // Prevents the function from running when user is in the middle of finding a
      // state with postal code - this situation happens when user enters a valid
      // postal code, then changes it to an invalid one and immediately clicking on
      // next -> MESSAGE FOR $scope.busy.findingState only
      if (
        $scope.busyNext ||
        $scope.postalMessages.showEmptyPostalCode ||
        $scope.postalMessages.showRegexMismatch ||
        $scope.postalMessages.showStateMismatch ||
        $scope.busy.findingState
      )
        return;

      $scope.busyNext = true;
      let isFormValid = false;

      const isBasicReceiverInfoPage = () => {
        const route = getLocationPathName();
        return !!route.includes('basic/receiver-info');
      };

      const fixOptionalEmailContact = (shipmentSubset) => {
        const isEmailMissing = !shipmentSubset.email_address?.trim();
        const isPhoneMissing = !shipmentSubset.phone_number?.trim();
        const shouldFixContact = (isEmailMissing || isPhoneMissing) && isBasicReceiverInfoPage();
        if (shouldFixContact) {
          const accountAddresses = AddressService.getReusableShippingAddresses();
          const addressDestination = (accountAddresses || []).find(
            (address) => address.id === shipmentSubset.origin_address_id
          );
          if (addressDestination) {
            if (isEmailMissing) shipmentSubset.email_address = addressDestination.contact_email;
            if (isPhoneMissing) shipmentSubset.phone_number = addressDestination.contact_phone;
          }
        }
      };

      // eslint-disable-next-line no-nested-ternary
      isFormValid = !$scope.isLuxUser
        ? $scope.shipmentSenderReceiverForm.$valid
        : $scope.DestinationAddressesService.isLuxotticaCustomFormShow()
        ? $scope.DestinationAddressesService.validateLuxotticaCustomForm()
        : $scope.shipmentSenderReceiverForm.$valid && $scope.isLuxotticaFlowSelected();

      if (!isFormValid) {
        $scope.shipmentSenderReceiverForm.$submit();

        $scope.busyNext = false;

        toastError($translate.instant('toast.incomplete-form'));
      } else {
        $scope.shipment.destination_country_id = $scope.shipment.destination_country.id;

        const isSimplified = UserSession.getFeatureFlagsByFlagName(
          'smb_simplified_domestic_shipping_flow'
        );
        const isDomesticShipment =
          $scope.shipment.destination_country_id === $scope.shipment.origin_country_id;

        // When user resume draft,and user hasSimplified Domestic key, user decided to change the international shipment, reset battery and liquid declaration
        if (isSimplified && !isDomesticShipment) {
          $scope.shipment.contains_battery_pi967_user_input = null;
          $scope.shipment.contains_liquids_user_input = null;
        }

        if (!$scope.shipment.id) {
          const saveApiParams = {
            company_id: UserSession.company.id,
            company_type: UserSession.company.type,
            include: 'shipment_items,list_of_couriers,store',
          };

          fixOptionalEmailContact($scope.shipment);

          const savePayload = {
            shipment: $scope.shipment,
            action_type: 'create_single_shipment',
          };

          ShipmentAction.singleShipmentSave(saveApiParams, savePayload)
            .then(function (res) {
              prepareShipmentBeforeRedirect(res);

              if (res.shipment && res.shipment.is_valid_address) {
                $state.go('add-shipment', { shipment: res.shipment });
              }
            })
            .catch((e) => {
              toastError($translate.instant('toast.incomplete-form'));
              return undefined;
            })
            .finally(function () {
              $scope.busyNext = false;
            });
        } else {
          const shipmentSubset = ShipmentAction.getSubsetOfShipment('Receiver', $scope.shipment);
          fixOptionalEmailContact(shipmentSubset);

          const updateApiParams = {
            company_id: UserSession.company.id,
            company_type: UserSession.company.type,
            id: $scope.shipment.id,
            include: 'shipment_items,list_of_couriers,store',
          };

          const updatePayload = {
            option: {
              preserve_courier: true,
              calculate_rates: false,
              validate_item_declared_value: false,
            },
            shipment: shipmentSubset,
          };

          ShipmentAction.singleShipmentUpdate(updateApiParams, updatePayload)
            .then(function (res) {
              prepareShipmentBeforeRedirect(res);

              if (res.shipment && res.shipment.is_valid_address) {
                $state.go('add-shipment', { shipment: res.shipment });
              }
            })
            .catch((e) => {
              toastError($translate.instant('toast.incomplete-form'));
              return undefined;
            })
            .finally(function () {
              $scope.busyNext = false;
            });
        }
      }
    };

    $scope.showInputErrorMessage = function (field) {
      if ($scope.shipment[field].length > $scope.maxLength[field]) {
        $scope.lengthErrorMessage[field] = true;
      } else {
        $scope.lengthErrorMessage[field] = false;
      }
    };

    $scope.isDestinationUnitedStates = function () {
      return (
        $scope.shipment.destination_country &&
        $scope.shipment.destination_country.id === UNITED_STATES_ID
      );
    };

    $scope.onOriginChange = function (changes) {
      this.shipment.origin_address_id = changes.originAddressId;
      this.shipment.origin_country_id = changes.originCountryId;
      this.shipment.origin_address = changes.originAddress;
    };

    function queryOnSelectFlow(flow, shipment) {
      $scope.busy.address = true;
      $scope.DestinationAddressesService.queryDestinationAddressesByCountryAndFlow(
        $scope.originCountryAlpha2,
        flow
      )
        .then(function () {
          const addressesDictionary = {
            return: $scope.DestinationAddressesService.setSelectedLuxotticaReturnAddress(0),
            damage: $scope.DestinationAddressesService.setSelectedLuxotticaDamageAddress(0),
            ftc: $scope.DestinationAddressesService.setSelectedLuxotticaFtcAddress(0),
          };
          $scope.DestinationAddressesService.setLuxotticaShipmentData(
            shipment,
            addressesDictionary[flow]
          );
          $scope.busy.address = false;
        })
        .catch(function () {
          //
        });
    }

    $scope.onSelectFlow = function (flow) {
      this.DestinationAddressesService.setSelectedLuxotticaFlow(flow);

      const isShowLuxCustomForm = this.DestinationAddressesService.isLuxotticaCustomFormShow();
      $scope.isShowReceiverForm = !isShowLuxCustomForm;
      $scope.isShowLuxotticaCustomForm = isShowLuxCustomForm;

      if (flow === 'storeToStore' || (!$scope.isShowReceiverForm && !isShowLuxCustomForm)) {
        this.DestinationAddressesService.setAddressDataToShipment(
          this.shipment,
          this.DestinationAddressesService.luxotticaDefaultAddress
        );
      } else if (flow === 'return') {
        if (this.DestinationAddressesService.luxotticaReturnAddresses.length > 0) {
          const defaultSelectedLuxotticaReturnAddress =
            this.DestinationAddressesService.getSelectedLuxotticaReturnAddress(
              this.DestinationAddressesService.selectedLuxotticaReturnAddress
            ) || this.DestinationAddressesService.getSelectedLuxotticaReturnAddress(0);

          this.DestinationAddressesService.setLuxotticaShipmentData(
            this.shipment,
            defaultSelectedLuxotticaReturnAddress
          );
        } else {
          queryOnSelectFlow(flow, $scope.shipment);
        }
      } else if (flow === 'damage') {
        if (this.DestinationAddressesService.luxotticaDamageAddresses.length > 0) {
          const defaultSelectedLuxotticaDamageAddress =
            this.DestinationAddressesService.getSelectedLuxotticaDamageAddress(
              this.DestinationAddressesService.selectedLuxotticaDamageAddress
            ) || this.DestinationAddressesService.getSelectedLuxotticaDamageAddress(0);

          this.DestinationAddressesService.setLuxotticaShipmentData(
            this.shipment,
            defaultSelectedLuxotticaDamageAddress
          );
        } else {
          queryOnSelectFlow(flow, $scope.shipment);
        }
      } else if (flow === 'ftc') {
        if (this.DestinationAddressesService.luxotticaFtcAddresses.length > 0) {
          const defaultSelectedLuxotticaFtcAddress =
            this.DestinationAddressesService.getSelectedLuxotticaFtcAddress(
              this.DestinationAddressesService.selectedLuxotticaFtcAddress
            ) || this.DestinationAddressesService.getSelectedLuxotticaFtcAddress(0);

          this.DestinationAddressesService.setLuxotticaShipmentData(
            this.shipment,
            defaultSelectedLuxotticaFtcAddress
          );
        } else {
          queryOnSelectFlow(flow, $scope.shipment);
        }
      } else {
        $scope.DestinationAddressesService.setAddressDataToShipment(
          $scope.shipment,
          $scope.DestinationAddressesService.luxotticaDefaultAddress
        );
      }
    };

    function getTheLastDraft() {
      const apiParams = {
        company_id: UserSession.company.id,
        company_type: UserSession.company.type,
        include: 'shipment_items,list_of_couriers',
      };

      const payload = {};

      ShipmentAction.getLastDraft(apiParams, payload)
        .then(function (res) {
          if (res.shipment) {
            const rawShipment = res.shipment;
            rawShipment.destination_country = findCountryObject(rawShipment.destination_country_id);

            if ($state.current.name === 'receiver-info') {
              resumeDraftModal.open(rawShipment);
            }
          }
        })
        .catch(function () {
          toastError(
            $translate.instant('toast.fetch-error', {
              noun: $translate.instant('global.draft-shipment').toLowerCase(),
            })
          );
        });
    }

    function prepareShipmentBeforeRedirect(res) {
      if (tempParcelsAttributes) {
        res.shipment.tempParcelsAttributes = tempParcelsAttributes;
      }

      // This is so when we get to choose courier page, we know what courier the user selected in
      // quotes page
      if ($scope.shipment.quote_selected_courier_id)
        res.shipment.quote_selected_courier_id = $scope.shipment.quote_selected_courier_id;
    }

    // Function related to Postal Code validation

    $scope.initOnCountryChange = function (shipment) {
      checkIfPostalCodeIsRequiredForDestination(shipment);
      clearStatesInput();
      clearCityInput();
      showTargetErrorMessage();

      if (shipment.postal_code) clearPostalCodeInput();
    };

    $scope.validatePostalCode = function runPostalCodeFunctionsOnChange(shipment) {
      if (!_.has(shipment, 'destination_country.requirements')) return;

      if (!shipment.postal_code) {
        shipment.postal_code = $('#destination-postal-code').find('input[name="postalCode"]').val();
      }

      PostalCodeService.isPostalCodeRequiredForDestination(shipment).then(function (
        postalCodeRequiredForDestination
      ) {
        if (PostalCodeService.doesCountryHavePostalCodeRegex(shipment)) {
          checkIfPostalCodeMatchesRegex(shipment);
        }

        showAlertMessageIfPostalCodeIsMissing(
          shipment.postal_code,
          postalCodeRequiredForDestination
        );
      });
    };

    $scope.changeOriginAddress = function (value) {
      $scope.shipment.origin_address_id = value;
    };

    function checkIfPostalCodeIsRequiredForDestination(shipment) {
      $scope.destinationNeedsPostalCode = PostalCodeService.isCountryStateInDatabase(shipment);
    }

    // Fillup city and state if postal code has value and empty state
    // which can usually happen if user comes from `Get quote`
    function prefillCityAndState() {
      if ($stateParams.shipment.postal_code && !$stateParams.shipment.state) {
        $scope.validatePostalCode($stateParams.shipment);
      }
    }

    function checkIfPostalCodeMatchesRegex(shipment) {
      if (PostalCodeService.doesPostalCodeMatchRegex(shipment)) {
        if ($scope.destinationNeedsPostalCode) findStateWithPostalCode(shipment);
        $scope.postalMessages.showRegexMismatch = false;
      } else {
        if ($scope.destinationNeedsPostalCode) clearStatesInput();
        showTargetErrorMessage('showRegexMismatch');
      }
    }

    function showAlertMessageIfPostalCodeIsMissing(postalCode, requiredCodeShip) {
      if (requiredCodeShip && !postalCode) {
        showTargetErrorMessage('showEmptyPostalCode');
      } else if (!requiredCodeShip) {
        $scope.postalMessages.showEmptyPostalCode = false;
      }
    }

    function showTargetErrorMessage(target) {
      Object.keys($scope.postalMessages).forEach(function (key) {
        $scope.postalMessages[key] = target === key;
      });
    }

    function findStateWithPostalCode(shipment) {
      const doesDestinationCountrySupportAutoFillState =
        $scope.CountryService.doesDestinationCountrySupportAutoFillState(
          $scope.shipment.destination_country.alpha2
        );

      if (!doesDestinationCountrySupportAutoFillState) return;

      $scope.busy.findingState = true;

      const params = {
        postal_code: shipment.postal_code,
        country_id: shipment.destination_country.id,
      };

      PostalCodeService.getState(params)
        .then(function (res) {
          if (params.country_id === AUSTRALIA_ID) {
            processAUPostalCodeResponse(res);
          } else {
            $scope.shipment.state = res.state && res.state.name;

            if ($scope.CountryService.countriesWithPostalCodeCity.indexOf(params.country_id) > -1)
              $scope.shipment.city = res.city;

            $scope.busy.findingState = false;
            showTargetErrorMessage();
          }
        })
        .catch(function () {
          $scope.busy.findingState = false;
          clearStatesInput();
          if ($scope.CountryService.countriesWithPostalCodeCity.indexOf(params.country_id) > -1)
            clearCityInput();
          showTargetErrorMessage('showStateMismatch');
        });
    }

    function processAUPostalCodeResponse(res) {
      $scope.suburbs = [];

      if (res.suburbs.length > 0) {
        $scope.suburbs = res.suburbs;

        // eslint-disable-next-line prefer-destructuring
        if ($scope.suburbs.length === 1) $scope.shipment.city = $scope.suburbs[0];

        $scope.shipment.state = res.state.name;
        $scope.busy.findingState = false;
        showTargetErrorMessage();
      } else {
        $scope.busy.findingState = false;
        clearStatesInput();
        showTargetErrorMessage('showStateMismatch');
      }
    }

    function clearStatesInput() {
      $scope.shipment.state = null;
    }

    function clearPostalCodeInput() {
      $scope.shipment.postal_code = null;
    }

    function clearCityInput() {
      $scope.shipment.city = null;
    }

    function findCountryObject(countryId) {
      return _.find(CountryService.countries, { id: countryId });
    }
  }
})();

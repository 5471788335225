import React from 'react';
import { twMerge } from 'tailwind-merge';

interface CountryFlagProps {
  alpha2?: string;
  className?: string;
}

export default function CountryFlag({ alpha2, className }: CountryFlagProps) {
  const lowerCaseAlpha2 = alpha2?.toLowerCase();

  const flagFromAlpha = alpha2
    ? `${import.meta.env.VITE_APP_STORAGE}/countries/${lowerCaseAlpha2}.svg`
    : '';

  return (
    <div
      className={twMerge(
        'inline-flex h-[16px] w-[20px] rounded-[2px] overflow-hidden bg-sky-300',
        className
      )}
    >
      <img className=" h-full w-full object-cover" src={flagFromAlpha} alt={`${alpha2} flag`} />
    </div>
  );
}

import angular from 'angular';
// eslint-disable-next-line import/extensions
import { AngularLiquidAndBatteryDeclaration } from './index.tsx';

const MODULE_NAME = 'app.components.liquid-and-battery-declaration-checkboxes';

angular
  .module(MODULE_NAME, [])
  .component('esLiquidAndBatteryDeclarationCheckboxes', AngularLiquidAndBatteryDeclaration);

export default MODULE_NAME;

import { IComponentController } from 'angular';

import {
  IRegistrationErrorResponse,
  IRegistrationData,
  IRegistrationUpdateParams,
} from 'typings/auth/services/registration';
import {
  AbTestVariation,
  AbTestVariationData,
  AbTestVariationParams,
  IABTestCookiePayload,
  IAbTestsService,
} from 'typings/ab-tests';
import {
  RegistrationFlowStateName,
  RegistrationFlowStep,
  StateParamsPlatform,
  InvalidStep,
} from '@client/auth/data/state';
import { IHelperService } from 'typings/helper';

import {
  SIGNUP_REDIRECTION_DELAY,
  StepsNavigationService,
} from '@client/auth/global/services/steps-navigation/steps-navigation.service';
import { ShopifyAuthService } from '@client/auth/global/services/shopify-auth/shopify-auth.service';
import { IAddressService } from 'typings/address';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { toastError } from '@client/core/components/react/Toastify';
import { IAuthService } from 'typings/core/services/auth';
import { getVisitorResult } from '@client/src/initializers/auth-full-login-success/fingerprint-loader/fingerprint-loader';
import * as Sentry from '@sentry/browser';
import style from './registration-flow-form.component.module.scss';
import template from './registration-flow-form.html?raw';
import { RegistrationService } from '../../services/registration/registration.service';

enum SlideDirection {
  none = 'none',
  left = 'left',
  right = 'right',
}

const PRE_NEGOTIATED_COUNTRIES: number[] = [
  234, // United States
  39, // Canada
  78, // 78 United Kingdom
  76, // 76 France
  96, // 96 Hong Kong
  199, // 199 Singapore
  14, // 14 Australia
  172, // 172 New Zealand
];

const AB_TEST_KEY_OF_SIGNUP = 'direct_to_quote';
class RegistrationFLowFormLayoutController implements IComponentController {
  style = style;
  busy = false;
  slide = SlideDirection.none;
  private currentStep = '';
  private registrationData: any;

  static $inject = [
    '$scope',
    '$window',
    '$timeout',
    '$state',
    '$stateParams',
    '$cookies',
    '$translate',
    'Auth',
    'HelperService',
    'GoogleAnalytics',
    'MixpanelService',
    'GrowsumoService',
    'RegistrationService',
    'ShopifyAuthService',
    'StepsNavigationService',
    'AbTestsService',
    'AddressService',
  ];
  constructor(
    private $scope: ng.IScope,
    private $window: ng.IWindowService,
    private $timeout: ng.ITimeoutService,
    private $state: ng.ui.IStateService,
    private $stateParams: ng.ui.IStateParamsService,
    private $cookies: angular.cookies.ICookiesService,
    private $translate: angular.translate.ITranslateService,
    private Auth: IAuthService,
    private HelperService: IHelperService,
    private GoogleAnalytics: any,
    private MixpanelService: MixpanelService,
    private GrowsumoService: any,
    private RegistrationService: RegistrationService,
    private ShopifyAuthService: ShopifyAuthService,
    public StepsNavigationService: StepsNavigationService,
    private AbTestsService: IAbTestsService,
    private AddressService: IAddressService
  ) {
    // Update current step every time user switch steps
    this.$scope.$watch(
      () => this.$stateParams.step,
      (currentStep) => {
        this.currentStep = this.HelperService.capitalize(currentStep);
      }
    );
  }

  get platformName(): string {
    return this.RegistrationService.getBackendPlatformName(this.$stateParams.platform);
  }

  get platformId(): any {
    return (
      this.RegistrationService.data &&
      this.RegistrationService.data.store &&
      this.RegistrationService.data.store.platform_id
    );
  }

  get isInvalidCountryStep(): boolean {
    return this.$state.current.name === `${RegistrationFlowStateName}.${InvalidStep.Country}`;
  }

  back(): void {
    if (this.isInvalidCountryStep) {
      this.slide = SlideDirection.left;
      setTimeout(() => {
        this.StepsNavigationService.backToUserForm();
      }, 50);
      return;
    }

    this.slide = SlideDirection.left;

    setTimeout(() => {
      this.StepsNavigationService.back();
      this.MixpanelService.track(
        `Signup - ${this.currentStep} Step - Back`,
        this.signupMixpanelData
      );
    }, 50);
  }

  private getABTestsCookie() {
    return this.$cookies.getObject<IABTestCookiePayload | undefined>('AB_TESTS');
  }

  private getActiveAbTestVariation(): string | undefined {
    const abTest = this.$cookies.getObject<IABTestCookiePayload | undefined>('AB_TESTS');
    return abTest?.[AB_TEST_KEY_OF_SIGNUP];
  }

  get abTestVariation(): string | undefined {
    const variation = this.$cookies.getObject<IABTestCookiePayload | undefined>('AB_TESTS');

    if (variation?.new_signup_flow_1) return variation.new_signup_flow_1;
    if (variation?.direct_to_quote) return variation.direct_to_quote;

    return '';
  }

  get persona(): any {
    return (
      this.RegistrationService.data &&
      this.RegistrationService.data.user &&
      this.RegistrationService.data.user.persona
    );
  }

  get drivers(): any {
    return (
      this.RegistrationService.data &&
      this.RegistrationService.data.company &&
      this.RegistrationService.data.company.signup_drivers
    );
  }

  get selectedDriversCount(): number {
    return this.drivers && this.drivers.length;
  }

  get signupMixpanelData(): any {
    return {
      drivers_selected_count: this.selectedDriversCount,
      platform_id: this.platformId,
      platform_name: this.platformName,
      persona: this.persona,
      ab_test_key: this.abTestVariation ? AB_TEST_KEY_OF_SIGNUP : '',
      ab_test_variation: this.abTestVariation,
    };
  }

  async handleSubmit(form: ng.IFormController): Promise<void> {
    if (this.StepsNavigationService.isLastStep) {
      if (this.registrationData) {
        this.busy = true;
        // add manual timeout for the fingerprint
        const visitor = await getVisitorResult(this.registrationData.data.user.id, 'signup');
        await this.next(form, visitor.sealedResult);
      }
    } else {
      this.next(form);
    }
  }

  next(form: ng.IFormController, fingerprintResult?: string): void {
    if (this.$stateParams.step === RegistrationFlowStep.Address) {
      this.AddressService.touchAllForms(form);
    }

    if (this.isDtqAbTestApplicable && this.$stateParams.step === RegistrationFlowStep.User) {
      const params: AbTestVariationParams = { key: AB_TEST_KEY_OF_SIGNUP };
      const abTestForce = this.$window.localStorage.getItem('AB_TEST_FORCE');

      if (abTestForce) params.force = true;
      params.variation = this.getActiveAbTestVariation();

      this.AbTestsService.getAbTestVariation(params)
        .then(({ variation }: AbTestVariationData) => this.handleAbTestVariationSuccess(variation))
        .catch(() => this.setNoTestVariation());
    }

    if (form.$invalid) {
      toastError(this.$translate.instant('toast.incomplete-form'));
      return;
    }

    this.busy = true;
    this.slide = SlideDirection.right;

    const params: IRegistrationUpdateParams = {
      id: this.$stateParams.id,
      platform: this.RegistrationService.getBackendPlatformName(this.$stateParams.platform),
      step: this.$stateParams.step,
      complete: this.StepsNavigationService.isLastStep,
    };

    this.RegistrationService.update(params, fingerprintResult)
      .then(({ easyship_company_id, session_token, registration, redirect_url }) => {
        const credentials = this.Auth.getCredentials();
        this.registrationData = registration;

        // Tracking logic
        // If user already has credentials, that means user already exist and just connected their store
        if (params.complete && !credentials) {
          this.GrowsumoService.storeData('email', this.RegistrationService.data.user.email);
          this.GrowsumoService.createSignup();

          this.sendCompleteGAEvents(registration.data);

          this.$cookies.remove(this.RegistrationService.impactIntegrationCookieName.IrClickId);
          this.$cookies.remove(this.RegistrationService.impactIntegrationCookieName.MediaId);
          this.$cookies.remove(this.RegistrationService.cookiesKey.EasyshipOwnerId);
        }
        // Send tracking if fulfillment and step is company
        else if (
          this.$stateParams.platform === StateParamsPlatform.Fulfillment &&
          this.$stateParams.step === RegistrationFlowStep.Company
        ) {
          this.sendEfulfillmentGAEvent(registration.data);
        }

        if (registration.complete) {
          // check the userId and send to fingerprint
          if (registration.data.user.id) {
            getVisitorResult(
              registration.data.user.id,
              'signup - success',
              easyship_company_id ?? ''
            );
          }
          if (!this.isDtqAbTestApplicable) {
            this.$cookies.putObject('AB_TESTS', { [AB_TEST_KEY_OF_SIGNUP]: 'no_test' });
          }
          this.AbTestsService.saveAbTestAssignment({
            ab_test_assignment: {
              agent_id: registration.data.company.id,
              variation: this.abTestVariation ?? '',
            },
            abTestKey: AB_TEST_KEY_OF_SIGNUP,
          }).catch((error) => console.error(error));
        }

        // Redirection
        if (redirect_url) {
          // Redirect to the url provided in order to complete steps in on the plaform side
          // For Shopify: handshake to approve adding app to store

          if (this.$state.params.platform === StateParamsPlatform.Square) {
            // Save state in local storage for validation
            const parsedUrl = new URL(redirect_url);
            const squareState = parsedUrl.searchParams.get('state');
            if (squareState) this.$window.localStorage.setItem('squareState', squareState);
          }

          if (session_token) {
            this.Auth.setCredentials(session_token);
            // identify the user in Mixpanel
            this.MixpanelService.identify(registration.data.user.id || '');
            this.$cookies.putObject('ES_USED_DASHBOARD', true, { domain: 'easyship.com' });
          }

          this.$timeout(() => {
            // NOTE: Create a new service (eg. AuthRedirectUrlService) if we need to add more platform
            if (
              this.$state.params.platform === StateParamsPlatform.Cashier &&
              session_token &&
              typeof this.RegistrationService.data.store.default_category_id === 'number'
            ) {
              this.ShopifyAuthService.handleBackendRedirectUrl(
                session_token,
                redirect_url,
                this.RegistrationService.data.store.default_category_id
              );
            }

            this.MixpanelService.track(`Signup - Redirect to platform`, {
              platform: params.platform,
              step: params.step,
              ...this.signupMixpanelData,
            });

            this.$window.location.href = redirect_url;
          }, SIGNUP_REDIRECTION_DELAY);
        } else if (session_token) {
          const isNewStore = !!registration.data?.store.id;

          if (this.platformName === 'uber_freight') {
            this.$window.localStorage.setItem('isUberFreight', 'true');
          }

          // If there is a session token, go to the dashboard
          this.StepsNavigationService.goToAuthenticatedPage(
            session_token,
            isNewStore ? '&new_connect' : ''
          );
        } else {
          // Go to the next registration step
          this.MixpanelService.track(`Signup - Go next registration step`, {
            step: params.step,
            complete: params.complete,
            ...this.signupMixpanelData,
          });

          this.StepsNavigationService.next(registration.fields_by_steps);

          this.busy = false;
        }
      })
      .catch((errorResponse: IRegistrationErrorResponse) => {
        this.busy = false;

        if (errorResponse.data && errorResponse.data.data) {
          const countryId = this.RegistrationService.data.company.country_id;
          const hasCountryError = errorResponse.data.data.error.some((error) =>
            error.includes('country')
          );

          if (!!countryId && hasCountryError) {
            this.StepsNavigationService.goToInvalidCountryState(countryId);
            return;
          }
        }

        toastError(
          this.$translate.instant('toast.update-error', {
            noun: this.$translate.instant('global.registration').toLowerCase(),
            details: this.RegistrationService.formatErrors(errorResponse),
          })
        );
      });
  }

  private get isDtqAbTestApplicable(): boolean {
    return (
      this.persona === 'personal' &&
      PRE_NEGOTIATED_COUNTRIES.includes(this.RegistrationService.data.company.country_id || -1)
    );
  }

  private setABTestsCookie(payload: IABTestCookiePayload) {
    const currentAbTest = this.getABTestsCookie();
    this.$cookies.putObject('AB_TESTS', {
      ...currentAbTest,
      ...payload,
    });
  }

  private handleAbTestVariationSuccess(variation: AbTestVariation): void {
    this.setABTestsCookie({ [AB_TEST_KEY_OF_SIGNUP]: variation });
  }

  private setNoTestVariation() {
    this.setABTestsCookie({ [AB_TEST_KEY_OF_SIGNUP]: 'no_test' });
  }

  private sendEfulfillmentGAEvent(registration: IRegistrationData) {
    if (registration.address) {
      this.GoogleAnalytics.createEvent({
        event: 'gaEvent',
        eventCategory: 'User',
        eventAction: 'Efulfillment Enquiry',
        eventUser: registration.address.city,
      });
    }
  }

  private sendCompleteGAEvents(registration: IRegistrationData) {
    const userId = (registration.user.id || '').slice(-12);
    const platformName = this.RegistrationService.getBackendPlatformName(
      this.$stateParams.platform
    );

    this.GoogleAnalytics.createEvent({
      event: 'gaEvent',
      eventCategory: 'User',
      eventAction: 'Account signup',
      eventLabel: `${userId}, ${platformName}`,
    });

    this.GoogleAnalytics.createEvent({
      event: 'gaEvent',
      eventCategory: 'User',
      eventAction: 'Company Signup',
      eventLabel: `${userId}, ${platformName}`,
    });
  }
}

const RegistrationFLowFormLayoutComponent = {
  controller: RegistrationFLowFormLayoutController,
  template,
};

export { RegistrationFLowFormLayoutComponent };

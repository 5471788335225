import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { react2angular } from 'react2angular';

import { makeStyles, styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid/Grid';
import Box from '@material-ui/core/Box/Box';
import Divider from '@material-ui/core/Divider';

import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import Alert from '@client/core/components/react/Alert';
import COLORS from '@client/src/colors';

import {
  useUserSession,
  UserSessionProvider,
} from '@client/src/global/context/UserSessionProvider';
import returnsImageUrl from '@assets/images/returns.svg';
import calcImageUrl from '@assets/images/calc.svg';
import BillingSvg from './adjustments-modal.svg?svgo';
import { useAdjustmentsQuery } from './useAdjustmentsQuery';

import AdjustmentItem from './AdjustmentItem';
import { AdjustmentSummary } from './AdjustmentSummary';

interface AdjustmentsModalProps {
  onClose: () => void;
  onContactSupport: () => void;
  onSeeDetails: () => void;
}

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    margin: 0,
    padding: '20px 50px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  svg: {
    marginBottom: 40,
    display: 'flex',
    justifyContent: 'center',
  },
  detailsButton: {
    width: 150,
  },
  grid: {
    '& > *': {
      paddingBottom: theme.spacing(1),
    },
  },
  section: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),

    '& > *:not(:last-child)': {
      marginBottom: 20,
    },
  },
  summaryDivider: {
    backgroundColor: COLORS.inkFaded,
  },
}));

const DialogContent = styled(MuiDialogContent)({
  padding: '32px 40px',
});

const Dialog = styled(MuiDialog)({
  '& .MuiDialog-paper': {
    width: '800px',
    maxWidth: '800px',
  },
});

const DialogActions = styled(MuiDialogActions)({
  margin: 0,
  padding: '20px 50px',
  justifyContent: 'space-between',
});

const wrappers = () => ({
  a: (chunk: string) => (
    <a
      href="https://support.easyship.com/hc/en-us/articles/360039684252"
      target="_blank"
      rel="noreferrer noopener"
      style={{
        color: COLORS.blueNormal,
      }}
    >
      {chunk}
    </a>
  ),
});

export default function AdjustmentsModal({
  onClose,
  onContactSupport,
  onSeeDetails,
}: AdjustmentsModalProps) {
  const classes = useStyles();
  const { formatMessage, formatDate } = useIntl();
  const { user, company, features } = useUserSession();
  const [open, setOpen] = React.useState(false);
  const { data: adjustments } = useAdjustmentsQuery({
    companyId: company.id,
    enabled: !user.flowRecords.adjustment,
    onSuccess: (data) => {
      const hasAdjustmentsMessage =
        data.payOnScan.amount || data.additionalCharges.amount || data.refundedCharges.amount;

      features.canUseTeamRightsAndPermission
        ? user.isOwner && hasAdjustmentsMessage && setOpen(true)
        : hasAdjustmentsMessage && setOpen(true);
    },
  });

  const totalCharges = adjustments?.totalCharges ?? 0;

  const showPayOnScan = !!adjustments?.payOnScan.amount;
  const showAdjustments =
    !!adjustments && !!(adjustments.additionalCharges.amount || adjustments.refundedCharges.amount);

  const closeModal = () => {
    setOpen(false);
    onClose();
  };

  const handleClose = () => {
    closeModal();
  };

  const handleSeeDetails = () => {
    closeModal();
    onSeeDetails();
  };

  const handleContactSupport = () => {
    onContactSupport();
  };

  return (
    <Dialog open={open}>
      <MuiDialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant="h6">
          <FormattedMessage id="adjustments-modal.title" />
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>

      <DialogContent dividers>
        {adjustments && (
          <Grid container spacing={6}>
            <Grid item xs={5}>
              <Box dangerouslySetInnerHTML={{ __html: BillingSvg }} className={classes.svg} />

              <Alert severity="success">
                <FormattedMessage id="adjustments-modal.note" values={wrappers()} />
              </Alert>
            </Grid>
            <Grid item xs={7}>
              <Typography className="text-ink-700" variant="body2" style={{ lineHeight: '20px' }}>
                <FormattedMessage
                  id="adjustments-modal.description"
                  values={{
                    date: formatDate(adjustments.nextBillingDate, {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    }),
                    ...wrappers(),
                  }}
                />
              </Typography>

              {showPayOnScan && (
                <>
                  <Grid container className={classes.section}>
                    <Grid item xs={12}>
                      <Box mb="4px">
                        <img alt="returnSvg" src={returnsImageUrl} />
                        <Typography
                          style={{ marginLeft: 8, marginRight: 8, color: COLORS.inkDarkest }}
                          variant="body1"
                          component="span"
                        >
                          <FormattedMessage id="adjustments-modal.pay-on-scan-label" />
                        </Typography>
                      </Box>

                      <Typography
                        variant="body2"
                        style={{ lineHeight: '20px', fontWeight: 'italic' }}
                      >
                        <FormattedMessage id="adjustments-modal.pay-on-scan-label-tooltip" />
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <AdjustmentItem
                        title={formatMessage({ id: 'adjustments-modal.scanned-by-couriers' })}
                        itemCount={adjustments.payOnScan.shipmentCount}
                        amount={adjustments.payOnScan.amount}
                        currency={company.currency}
                      />
                    </Grid>
                  </Grid>

                  {showAdjustments && <Divider />}
                </>
              )}

              {showAdjustments && (
                <Grid container className={classes.section}>
                  <Grid item xs={12}>
                    <Box mb="4px">
                      <img alt="calcSvg" src={calcImageUrl} />
                      <Typography
                        style={{ marginLeft: 8, marginRight: 8, color: COLORS.inkDarkest }}
                        variant="body1"
                        component="span"
                      >
                        <FormattedMessage id="adjustments-modal.shipping-adjustments" />
                      </Typography>
                    </Box>

                    <Typography
                      variant="body2"
                      style={{ lineHeight: '20px', fontWeight: 'italic' }}
                    >
                      <FormattedMessage id="adjustments-modal.shipping-adjustments-tooltip" />
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container className={classes.grid}>
                      <Grid item xs={12}>
                        <AdjustmentItem
                          title={formatMessage({ id: 'adjustments-modal.additional-charges' })}
                          itemCount={adjustments.additionalCharges.shipmentCount}
                          amount={adjustments.additionalCharges.amount}
                          currency={company.currency}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <AdjustmentItem
                          isRefunded
                          title={formatMessage({ id: 'adjustments-modal.refunded-charges' })}
                          itemCount={adjustments.refundedCharges.shipmentCount}
                          amount={adjustments.refundedCharges.amount}
                          currency={company.currency}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              <Divider className={classes.summaryDivider} />

              <Box mt={4}>
                <AdjustmentSummary
                  title={formatMessage(
                    { id: 'adjustments-modal.total-amount-change' },
                    { isDebit: totalCharges >= 0 }
                  )}
                  amount={-totalCharges}
                  currency={company.currency}
                />
              </Box>
            </Grid>
          </Grid>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleContactSupport}>
          <FormattedMessage id="global.contact-support" />
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.detailsButton}
          onClick={handleSeeDetails}
        >
          <FormattedMessage id="global.see-details" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function WrappedAdjustmentsModal(props: AdjustmentsModalProps) {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <AdjustmentsModal {...props} />
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularAdjustmentsModal = react2angular(WrappedAdjustmentsModal, [
  'onClose',
  'onContactSupport',
  'onSeeDetails',
]);

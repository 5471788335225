import angular from 'angular';
import {
  ExcludedCourierResponse,
  IFieldType,
  IShipmentAction,
  IShipmentListActionPayload,
  IShipmentListActionSelectAllPayload,
  IShipmentListAdvancedTotalData,
  IShipmentListDeleteResponse,
  IShipmentListExportPayload,
  IShipmentListItem,
  IShipmentListItemResponse,
  IShipmentListLoading,
  IShipmentListMergeConfirmResponse,
  IShipmentListMergePrepareResponse,
  IShipmentListMergeResponse,
  IShipmentListPayload,
  IShipmentListSplitConfirmResponse,
  IShipmentListUpdateField,
  IShipmentResource,
  IShipmentSortByOptions,
  IShipmentUpdateParams,
  IShipmentUpdatePayload,
} from 'typings/shipment';
import { IUserSession } from 'typings/user-session';
import { ICountryService } from 'typings/auth/services/country';
import { IExportService } from 'typings/export';
import { IBatchService } from 'typings/batch';
import { IStatusMessageService } from 'typings/status';
import { IHelperService } from 'typings/helper';
import { IPlatformService } from 'typings/platform';
import { IPickupService } from 'typings/pickup';
import { IStoreService } from 'typings/store';
import { ICourierService } from 'typings/courier';

import { isEmpty } from 'lodash';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import { NormalizeMultiselectAttr } from './data/normalize';
import { IShipmentListPayloadFilter } from './shipment-list-normalize.service';
import { ShipmentListAbstractService } from './shipment-list-abstract.service';
import { EqualityModelValue } from './data/multi-select';
import { ShipmentViewsService } from '../shipment-views/shipment-views.service';
import { ShipmentListResource } from './shipment-list.resource';
import { AdvancedSearchComponentApiNormalizeService } from '../advanced-search-component-api-normalize/advanced-search-component-api-normalize.service';

class ShipmentListAdvancedService extends ShipmentListAbstractService {
  loading: IShipmentListLoading = {
    order: false,
    receiver: false,
    courier: false,
  };

  private isCourierModalOpen = false;
  private _totals: IShipmentListAdvancedTotalData | null = null;
  private _selectedShipment: IShipmentListItem | null = null;
  readonly sortingOptions: IShipmentSortByOptions[] = [
    ...this.commonSortingOptions,
    { key: 'is_valid_to_ship' },
    // Not available
    // { key: 'delivery_time_range' },
    { key: 'total_charge' },
    // Not available
    // { key: 'courier_tracking_rating' },
    // { key: 'effective_incoterms' },
    // { key: 'box.name' },
  ];

  // Keep this sync with IShipmentListItemDisplay
  private readonly shipmentDisplayAttributes = [
    'selected',
    'showOrder',
    'showReceiver',
    'showCourier',
    'shown',
    'loadingOrder',
    'loadingReceiver',
    'loadingCourier',
    'preserveCourier',
    'refresh',
  ];

  static $inject = [
    '$translate',
    'ShipmentListResource',
    'Shipment',
    'UserSession',
    'CountryService',
    'BatchService',
    'StatusMessageService',
    'CourierService',
    'HelperService',
    '$q',
    'StoreService',
    'PlatformService',
    'PickupService',
    'ShipmentViewsService',
    'AdvancedSearchComponentApiNormalizeService',
    'ShipmentAction',
    'ExportService',
  ];

  constructor(
    $translate: angular.translate.ITranslateService,
    ShipmentListResource: ShipmentListResource,
    Shipment: IShipmentResource,
    UserSession: IUserSession,
    CountryService: ICountryService,
    BatchService: IBatchService,
    StatusMessageService: IStatusMessageService,
    CourierService: ICourierService,
    HelperService: IHelperService,
    $q: ng.IQService,
    StoreService: IStoreService,
    PlatformService: IPlatformService,
    PickupService: IPickupService,
    ShipmentViewsService: ShipmentViewsService,
    AdvancedSearchComponentApiNormalizeService: AdvancedSearchComponentApiNormalizeService,
    private ShipmentAction: IShipmentAction,
    private ExportService: IExportService
  ) {
    super(
      ShipmentListResource,
      Shipment,
      UserSession,
      BatchService,
      StatusMessageService,
      HelperService,
      CourierService,
      $q,
      $translate,
      StoreService,
      PlatformService,
      PickupService,
      ShipmentViewsService,
      CountryService,
      AdvancedSearchComponentApiNormalizeService
    );
  }

  fetchShipmentTotalWithFilter(): void {
    if (!isEmpty(this.filterPayload)) {
      return this.fetchShipmentTotal(this.filterPayload);
    }
    return this.fetchShipmentTotal();
  }

  fetchShipmentTotal(filterData?: IShipmentListPayloadFilter): void {
    let payload: IShipmentListPayload = {
      offset: this.offset,
      limit: this.limit,
      scope: 'orders_all',
      by_search: true,
      include: 'collect_state',
    };

    if (filterData) {
      payload = {
        ...payload,
        ...filterData,
      };
    }

    this.totalBusy = true;
    this.ShipmentListResource.getShipmentTotal(
      {
        company_id: this.UserSession.getCompanyId() || '',
      },
      payload
    )
      .then((data) => {
        this._totals = data.totals as IShipmentListAdvancedTotalData;
      })
      .finally(() => {
        this.totalBusy = false;
      });
  }

  toggleOrderForm(shipmentIndex: number): void {
    if (this.currentShipments) {
      if (!this.currentShipments[shipmentIndex].showOrder) {
        this.openFieldAction(shipmentIndex, 'order');
      } else {
        this.closeFieldAction(shipmentIndex, 'order');
      }
    }
  }

  toggleReceiverForm(shipmentIndex: number): void {
    if (this.currentShipments) {
      if (!this.currentShipments[shipmentIndex].showReceiver) {
        this.openFieldAction(shipmentIndex, 'receiver');
      } else {
        this.closeFieldAction(shipmentIndex, 'receiver');
      }
    }
  }

  toggleCourierModal(shipmentIndex: number): void {
    this.openFieldAction(shipmentIndex, 'courier');
  }

  closeAllField(shipmentIndex: number | null, isClearShipment?: boolean): void {
    if (this.currentShipments && (shipmentIndex || shipmentIndex === 0)) {
      this.currentShipments[shipmentIndex].showOrder = false;
      this.currentShipments[shipmentIndex].showReceiver = false;
      this.currentShipments[shipmentIndex].showCourier = false;
      this.resetLoadingState();
    }

    // Clear SelectedShipment
    if (isClearShipment) {
      this.clearSelectedShipment();
    }
  }

  updateSelectedShipment(shipment: IShipmentListItem): void {
    this._selectedShipment = angular.copy(shipment);
  }

  updateCurrentShipment(shipment: IShipmentListItem, index: number): void {
    if (this.currentShipments) {
      this.currentShipments[index] = angular.copy({
        ...this.currentShipments[index],
        ...shipment,
      });
      this.cloneCurrentShipments();
    }
  }

  updateShipmentInList(updatedShipment: IShipmentListItem): void {
    const shipmentIndexFound = (this.currentShipments || []).findIndex(
      (shipment) => shipment.id === updatedShipment.id
    );
    if (shipmentIndexFound !== -1) {
      this.updateCurrentShipment(updatedShipment, shipmentIndexFound);
    }
  }

  updateShipmentByOrderForm(
    selectedShipment: IShipmentListItem
  ): ng.IPromise<IShipmentListItemResponse> {
    const payload = this.getUpdateShipmentPayload(selectedShipment, 'Order');
    return this.updateShipmentForm(selectedShipment.id, payload);
  }

  updateShipmentByReceiverForm(
    selectedShipment: IShipmentListItem
  ): ng.IPromise<IShipmentListItemResponse> {
    const payload = this.getUpdateShipmentPayload(selectedShipment, 'Receiver');
    return this.updateShipmentForm(selectedShipment.id, payload);
  }

  fetchShipmentEntitiesAfterEditForm(callback?: () => void): void {
    this.fetchShipmentEntitiesWithFilter('orders_all').then(() => {
      if (callback) {
        callback();
      }
    });
  }

  modifyShipmentAfterUpdate(shipmentIndex: number, newShipment: IShipmentListItem): void {
    if (this.currentShipments) {
      this.modifyCurrentShipment(shipmentIndex, newShipment);
      this.updateSelectedShipment(this.currentShipments[shipmentIndex]);
    }
  }

  modifyCurrentShipment(shipmentIndex: number, newShipment: IShipmentListItem): void {
    if (this.currentShipments) {
      // Keep existed Shipment list display attribute
      const displayValueObject = this.HelperService.filterObjectByKeys(
        angular.copy(this.currentShipments[shipmentIndex]),
        this.shipmentDisplayAttributes
      );

      const updatedShipment = Object.assign(newShipment, displayValueObject);
      this.updateCurrentShipment(updatedShipment, shipmentIndex);
    }
  }

  overwriteCourier(shipmentId: string, courierId: string): ng.IPromise<IShipmentListItemResponse> {
    return this.Shipment.overwriteCourier(
      {
        company_type: this.UserSession.company.type,
        company_id: this.UserSession.getCompanyId(),
        id: shipmentId,
      },
      {
        include:
          'shipment_items,list_of_couriers,store,missing_dimensions_warning,chargeable_weight',
        courier: { courier_id: courierId },
      }
    ).$promise.catch((err: any) => {
      if (err.data) {
        toastError(
          this.$translate.instant('create.notifications.rate-select-error', {
            details: err.data.errors,
          })
        );
      }

      throw err;
    });
  }

  fetchShipmentEntitiesAndTotalWithFilter(): void {
    this.fetchShipmentTotalWithFilter();
    this.fetchShipmentEntitiesWithFilter('orders_all');
  }

  fetchShipmentEntitiesAndTotal(): void {
    this.fetchShipmentTotal();
    this.fetchShipmentEntities('orders_all');
  }

  mergeAllPrepare(): ng.IPromise<IShipmentListMergeResponse> {
    return this.Shipment.mergeAllPrepare({
      company_id: this.UserSession.getCompanyId(),
      company_type: 'cloud',
      by_search: true,
      scope: 'orders_all',
    })
      .$promise.then((res: IShipmentListMergeResponse) => {
        return res;
      })
      .catch((err: unknown) => {
        throw err;
      });
  }

  mergeAllConfirm(): ng.IPromise<IShipmentListMergeResponse> {
    return this.Shipment.mergeAllConfirm(
      {
        company_id: this.UserSession.getCompanyId(),
        company_type: 'cloud',
      },
      { by_search: true, scope: 'orders_all' }
    )
      .$promise.then((res: IShipmentListMergeResponse) => {
        return res;
      })
      .catch((err: unknown) => {
        throw err;
      });
  }

  mergeSelectedPrepare(): ng.IPromise<IShipmentListMergePrepareResponse> {
    let payload: IShipmentListActionPayload | IShipmentListActionSelectAllPayload =
      this.shipmentActionPayload('shipment_items,list_of_couriers,store');

    if (this.isSelectedAll && this.filter) {
      payload = {
        ...payload,
        ...this.normalizeFilterData(this.filter),
      };
    }

    return this.Shipment.mergeSelectedPrepare(
      {
        company_id: this.UserSession.getCompanyId(),
        company_type: 'cloud',
      },
      payload
    )
      .$promise.then((res: IShipmentListMergePrepareResponse) => {
        this.fetchShipmentTotalWithFilter();
        return res;
      })
      .catch((err: unknown) => {
        throw err;
      });
  }

  splitPrepare(): ng.IPromise<IShipmentListItemResponse> {
    let payload: IShipmentListActionPayload | IShipmentListActionSelectAllPayload =
      this.shipmentActionPayload('shipment_items');

    if (this.isSelectedAll && this.filter) {
      payload = {
        ...payload,
        ...this.normalizeFilterData(this.filter),
      };
    }

    return this.Shipment.splitPrepare(
      {
        company_id: this.UserSession.getCompanyId(),
        company_type: 'cloud',
      },
      { ...payload, include: 'shipment_items' }
    )
      .$promise.then((res: IShipmentListItemResponse) => {
        return res;
      })
      .catch((err: unknown) => {
        throw err;
      });
  }

  itemsBulkEditPrepare(params: { shipmentId?: string } = {}): ng.IPromise<any> {
    let payload: IShipmentListActionPayload | IShipmentListActionSelectAllPayload =
      params.shipmentId
        ? {
            by_search: true,
            include: 'shipment_items',
            shipment_ids: [params.shipmentId],
          }
        : this.shipmentActionPayload('shipment_items');

    if (!params.shipmentId && this.isSelectedAll && this.filter) {
      payload = {
        ...payload,
        ...this.normalizeFilterData(this.filter),
      };
    }

    return this.Shipment.itemsBulkEditPrepare(
      {
        company_id: this.UserSession.getCompanyId(),
        company_type: 'cloud',
      },
      payload
    )
      .$promise.then((res: any) => {
        return res;
      })
      .catch((err: unknown) => {
        throw err;
      });
  }

  splitConfirm(
    shipmentItemIdsWithQuantity: string[],
    shipmentId: string
  ): ng.IPromise<IShipmentListSplitConfirmResponse> {
    return this.Shipment.splitConfirm(
      {
        company_id: this.UserSession.getCompanyId(),
        company_type: 'cloud',
        include: 'shipment_items',
        id: shipmentId,
      },
      {
        split_shipment_item_ids_with_quantity: shipmentItemIdsWithQuantity,
        by_search: true,
      }
    )
      .$promise.then((res: IShipmentListSplitConfirmResponse) => {
        return res;
      })
      .catch((err: unknown) => {
        throw err;
      });
  }

  deleteConfirm(): ng.IPromise<IShipmentListDeleteResponse> {
    let payload: IShipmentListActionPayload | IShipmentListActionSelectAllPayload =
      this.shipmentActionPayload();

    if (this.isSelectedAll && this.filter) {
      payload = {
        ...payload,
        ...this.normalizeFilterData(this.filter),
      };
    }

    return this.Shipment.deleteSelected(
      {
        company_id: this.UserSession.getCompanyId(),
        company_type: 'cloud',
      },
      payload
    )
      .$promise.then((res: IShipmentListDeleteResponse) => {
        this.fetchShipmentTotalWithFilter();
        return res;
      })
      .catch((err: unknown) => {
        throw err;
      });
  }

  exportShipments(exportType?: string): ng.IPromise<void> {
    let payload: IShipmentListExportPayload = {
      by_search: true,
      include: 'shipment_items,list_of_couriers,store',
      scope: 'orders_all',
      context: 'Advanced Shipments',
    };

    if (exportType) {
      payload.export_type = exportType;
    }

    if ((this.selectedIds && this.selectedIds.length <= 0) || this.isSelectedAll) {
      payload.limit = this.totals ? this.totals.orders_count : 0;
      payload.offset = 0;

      if (this.keyword && this.keyword !== '') {
        (payload as IShipmentListActionSelectAllPayload).keyword = this.keyword;
      }

      if (this.filter) {
        payload = {
          ...payload,
          ...this.normalizeFilterData(this.filter),
        };
      }
    }

    if (this.isSelectedAll) {
      payload.exclude_shipment_ids = this.excludedIds;
    } else {
      payload.shipment_ids = this.selectedIds;
    }

    return this.ExportService.getCSV(payload).then(() => {
      this.fetchShipmentTotalWithFilter();
    });
  }

  mergeSelectedConfirm(
    shipmentIds: string[],
    mergedShipmentId: string
  ): ng.IPromise<IShipmentListMergeConfirmResponse> {
    return this.Shipment.mergeSelectedConfirm(
      {
        company_id: this.UserSession.getCompanyId(),
        company_type: 'cloud',
      },
      {
        shipment_ids: shipmentIds,
        merged_shipment_id: mergedShipmentId,
        by_search: true,
      }
    )
      .$promise.then((res: IShipmentListMergeConfirmResponse) => {
        return res;
      })
      .catch((err: unknown) => {
        throw err;
      });
  }

  clearIsCourierModalOpen(): void {
    this.isCourierModalOpen = false;
  }

  async updateViewAndShipments(
    viewId: string,
    stateParams?: ng.ui.IStateParamsService
  ): Promise<void> {
    this.applyCustomView(viewId);
    // Apply filter from url
    if (stateParams) {
      await this.applyFilterFromUrl(stateParams);
    }
    this.fetchShipmentEntitiesAndTotalWithFilter();
  }

  setLimitFromUserSession(): void {
    this.limit = this.UserSession.getItemsPerPageValue('multiple');
  }

  fetchAdvancedCustomViews(): ng.IPromise<void> {
    return this.fetchCustomViews('orders_all');
  }

  get shipmentCheckoutPreparePayload():
    | IShipmentListActionPayload
    | IShipmentListActionSelectAllPayload {
    let payload: IShipmentListActionPayload | IShipmentListActionSelectAllPayload = this
      .isSelectedAll
      ? {
          by_search: true,
          limit: this.totals ? this.totals.orders_count : 0,
          offset: 0,
          include: 'shipment_items,list_of_couriers,orders_totals,store',
          scope: 'orders_all',
          exclude_shipment_ids: this.excludedIds,
          is_valid_to_ship: {
            operator: EqualityModelValue.EqualTo,
            type: NormalizeMultiselectAttr.Boolean,
            values: [true],
          },
        }
      : {
          by_search: true,
          shipment_ids: this.validToShipSelectedIds,
        };

    if (this.isSelectedAll) {
      if (this.keyword && this.keyword !== '') {
        (payload as IShipmentListActionSelectAllPayload).keyword = this.keyword;
      }

      if (this.filter) {
        payload = {
          ...payload,
          ...this.normalizeFilterData(this.filter),
        };
      }
    }

    return payload;
  }

  get totals(): IShipmentListAdvancedTotalData | null {
    return this._totals;
  }

  get selectedShipment(): IShipmentListItem | null {
    return this._selectedShipment;
  }

  get allLoadingFalse(): boolean {
    return (
      !this.loading.order &&
      !this.loading.receiver &&
      !this.loading.courier &&
      !this.isCourierModalOpen
    );
  }

  private shipmentActionPayload(
    include?: string
  ): IShipmentListActionPayload | IShipmentListActionSelectAllPayload {
    const selectedAllPayload: IShipmentListActionSelectAllPayload = {
      by_search: true,
      limit: this.totals ? this.totals.orders_count : 0,
      offset: 0,
      scope: 'orders_all',
      exclude_shipment_ids: this.excludedIds,
    };

    if (include) {
      selectedAllPayload.include = include;
    }

    const payload: IShipmentListActionPayload | IShipmentListActionSelectAllPayload = this
      .isSelectedAll
      ? selectedAllPayload
      : {
          by_search: true,
          shipment_ids: this.selectedIds,
        };

    if (this.isSelectedAll && this.keyword && this.keyword !== '') {
      (payload as IShipmentListActionSelectAllPayload).keyword = this.keyword;
    }

    return payload;
  }

  updateShipmentForm(
    shipmentId: string,
    payload: IShipmentUpdatePayload
  ): ng.IPromise<IShipmentListItemResponse> {
    const params = this.getUpdateShipmentParams(shipmentId);
    return this.Shipment.update(params, payload)
      .$promise.then(({ shipment }: IShipmentListItemResponse) => {
        toastSuccess(
          this.$translate.instant(
            'toast.update-success',
            { noun: this.$translate.instant('global.order').toLowerCase() },
            'messageformat'
          )
        );
        this.fetchShipmentTotalWithFilter();

        return {
          shipment,
        };
      })
      .catch((err: unknown) => {
        toastError(
          this.$translate.instant('toast.update-error', {
            noun: this.$translate.instant('global.order').toLowerCase(),
          })
        );
        throw err;
      });
  }

  private getUpdateShipmentParams(shipmentId: string): IShipmentUpdateParams {
    return {
      company_id: this.UserSession.company.id,
      company_type: this.UserSession.company.type,
      id: shipmentId,
      include: 'shipment_items,list_of_couriers,missing_dimensions_warning,chargeable_weight',
    };
  }

  private getUpdateShipmentPayload(
    shipment: IShipmentListItem,
    currentField: IShipmentListUpdateField
  ): IShipmentUpdatePayload {
    const shipmentSubset = this.ShipmentAction.getSubsetOfShipment(currentField, shipment);
    const validateAddress = currentField === 'Receiver';
    // Make sure  preserve_courier is always set to true incase user do not touch flat rate box field
    const preserveCourier = shipment.preserveCourier !== false;
    return {
      shipment: shipmentSubset,
      option: {
        preserve_courier: preserveCourier,
        validate_address: validateAddress,
        except_incoterms_and_insurance: true,
      },
    };
  }

  private openOrderForm(shipmentIndex: number): void {
    if (this._selectedShipment && this.currentShipments && this.currentShipments[shipmentIndex]) {
      this.loading.order = true;
      const currentShipment = this.currentShipments[shipmentIndex];
      currentShipment.loadingOrder = true;
      this.getEditShipment(this._selectedShipment.id)
        .then((data) => {
          if (data && data.shipment && this.currentShipments) {
            this._selectedShipment = data.shipment;
            currentShipment.showOrder = true;
          }
        })
        .finally(() => {
          this.loading.order = false;
          currentShipment.loadingOrder = false;
        });
    }
  }

  private openReceiverForm(shipmentIndex: number): void {
    if (this._selectedShipment && this.currentShipments && this.currentShipments[shipmentIndex]) {
      this.loading.receiver = true;
      const currentShipment = this.currentShipments[shipmentIndex];
      currentShipment.loadingReceiver = true;
      this.getEditShipment(this._selectedShipment.id)
        .then((data) => {
          if (data && data.shipment && this.currentShipments) {
            this._selectedShipment = data.shipment;
            currentShipment.showReceiver = true;
          }
        })
        .finally(() => {
          this.loading.receiver = false;
          currentShipment.loadingReceiver = false;
        });
    }
  }

  private openEditCourierModal(shipmentIndex: number): void {
    if (this._selectedShipment && this.currentShipments && this.currentShipments[shipmentIndex]) {
      this.loading.courier = true;
      const currentShipment = this.currentShipments[shipmentIndex];
      currentShipment.loadingCourier = true;
      this.isCourierModalOpen = true;
      this.getEditShipment(this._selectedShipment.id)
        .then((data) => {
          if (data && data.shipment && this.currentShipments) {
            this._selectedShipment = data.shipment;
            currentShipment.showCourier = true;
          }
        })
        .finally(() => {
          this.loading.courier = false;
          currentShipment.loadingCourier = false;
        });
    }
  }

  getEditShipment(
    shipmentId: string,
    includeList?: string
  ): ng.IPromise<{ shipment: IShipmentListItem }> {
    return this.Shipment.query({
      company_id: this.UserSession.company.id,
      company_type: this.UserSession.company.type,
      id: shipmentId,
      include:
        includeList ||
        'shipment_items,list_of_couriers,missing_dimensions_warning,state_not_match_postal_code_warning',
    }).$promise.catch(() => {
      toastError(this.$translate.instant('toast.default-error'));
    });
  }

  getShipmentUnavailableRates(
    shipmentId: string
  ): ng.IPromise<{ excluded_couriers: ExcludedCourierResponse[] }> {
    return this.Shipment.unavailableRates({
      company_id: this.UserSession.company.id,
      company_type: this.UserSession.company.type,
      id: shipmentId,
    }).$promise.catch(() => {
      toastError(this.$translate.instant('toast.default-error'));
    });
  }

  private openFieldAction(shipmentIndex: number, fieldType: IFieldType): void {
    if (this.currentShipments) {
      this._selectedShipment = angular.copy(this.currentShipments[shipmentIndex]);
      this.closeAllField(shipmentIndex);

      if (this._selectedShipment) {
        if (fieldType === 'order') {
          this.openOrderForm(shipmentIndex);
        }

        if (fieldType === 'receiver') {
          this.openReceiverForm(shipmentIndex);
        }

        if (fieldType === 'courier') {
          this.openEditCourierModal(shipmentIndex);
        }
      }
    }
  }

  private closeFieldAction(shipmentIndex: number, fieldType: IFieldType): void {
    if (this.currentShipments) {
      if (fieldType === 'order') {
        this.currentShipments[shipmentIndex].showOrder = false;
        this.loading.order = false;
      }

      if (fieldType === 'receiver') {
        this.currentShipments[shipmentIndex].showReceiver = false;
        this.loading.receiver = false;
      }

      if (fieldType === 'courier') {
        this.currentShipments[shipmentIndex].showCourier = false;
        this.loading.courier = false;
      }

      this.clearSelectedShipment();
    }
  }

  clearSelectedShipment(): void {
    this._selectedShipment = null;
  }

  private resetLoadingState(): void {
    this.loading = {
      order: false,
      receiver: false,
      courier: false,
    };
  }
}

export { ShipmentListAdvancedService };

import { toastError } from '@client/core/components/react/Toastify';

(function () {
  angular
    .module('easyshipDashboardApp')
    .controller('SingleShipmentAddShipmentCtrl', SingleShipmentAddShipmentCtrl);

  SingleShipmentAddShipmentCtrl.$inject = [
    '$scope',
    '$state',
    '$translate',
    '$stateParams',
    'CountryService',
    'ShipmentAction',
    'UserSession',
    'PlatformService',
    'MixpanelService',
    'AppCuesService',
  ];

  function SingleShipmentAddShipmentCtrl(
    $scope,
    $state,
    $translate,
    $stateParams,
    CountryService,
    ShipmentAction,
    UserSession,
    PlatformService,
    MixpanelService,
    AppCuesService
  ) {
    $scope.UserSession = UserSession;
    $scope.CountryService = CountryService;

    (function init() {
      if (!$stateParams.shipment) {
        $state.go('receiver-info');
        return;
      }
      $scope.platformNames = PlatformService.getPlatformNames();

      $scope.shipment = _prefillShipmentInformation($stateParams.shipment);
      $scope.originAddressSingleBetaUser = true; // UserSession.company.dashboard_settings.beta_feature_origin_address_single;
      $scope.validationErrors = {};
      $scope.hasTrySubmit = false;
    })();

    $scope.prev = function () {
      $scope.shipment.tempParcelsAttributes = $scope.shipment.parcels_attributes;

      $state.go('receiver-info', { shipment: $scope.shipment });
    };

    $scope.next = function () {
      if ($scope.busyNext) return;

      $scope.validationErrors.tags =
        $scope.UserSession.getCompanyDashboardSettings().beta_feature_require_order_tag &&
        (!$scope.shipment.order_tag_list || $scope.shipment.order_tag_list.length === 0);

      $scope.hasTrySubmit = true;

      if (!$scope.addShipmentForm.$valid || $scope.validationErrors.tags) {
        $scope.addShipmentForm.$submit();
        toastError($translate.instant('toast.incomplete-form'));
        return;
      }
      const invalidOunceInput = $scope.shipment.parcels_attributes.find(
        (parcel) => !parcel.display_weight
      );
      if (invalidOunceInput) {
        toastError($translate.instant('toast.incomplete-form'));
        return;
      }

      $scope.busyNext = true;
      const shipmentSubset = ShipmentAction.getSubsetOfShipment('Information', $scope.shipment);

      shipmentSubset.parcels_attributes.forEach((parcel) => {
        parcel.total_actual_weight = null;
      });

      const updateApiParams = {
        company_id: UserSession.company.id,
        company_type: UserSession.company.type,
        id: $scope.shipment.id,
        include: 'shipment_items,list_of_couriers,store',
      };

      const updatePayload = {
        option: {
          preserve_courier: true,
          validate_address: false,
          except_incoterms_and_insurance: true,
        },
        shipment: {
          ...shipmentSubset,
          total_customs_value_user_input: null,
        },
      };

      ShipmentAction.singleShipmentUpdate(updateApiParams, updatePayload)
        .then(function (res) {
          addQuoteCourierIdToShipment(res);

          $state.go('choose-courier', { shipment: res.shipment });
          AppCuesService.track('Basic | Created Shipment', null, true);
        })
        .catch(function () {
          $scope.busyNext = false;
        });
    };

    $scope.saveInformation = function (shipment) {
      $scope.shipment = shipment;
    };

    $scope.saveParcels = function (parcels, preserveCourier) {
      $scope.shipment.parcels_attributes = parcels;
      $scope.shipment.preserveCourier = preserveCourier;
    };

    function addQuoteCourierIdToShipment(res) {
      // This is so when we get to choose courier page, we know what courier the user selected in
      // quotes page
      if ($scope.shipment.quote_selected_courier_id)
        res.shipment.quote_selected_courier_id = $scope.shipment.quote_selected_courier_id;
    }

    // Multi box preparations
    function _prefillShipmentInformation(shipment) {
      // If shipment has an array called tempParcelsAttributes then convert it to the shipment parcels_attributes
      if (shipment.tempParcelsAttributes) {
        shipment.parcels_attributes = shipment.tempParcelsAttributes;
        delete shipment.tempParcelsAttributes;

        // If shipment item does not have either a tempParcelsAttributes or parcels_attributes array then
        // add a new array to it
      } else if (
        !shipment.tempParcelsAttributes &&
        shipment.parcels_attributes[0].shipment_items_attributes.length === 0
      ) {
        shipment.platform_name = 'Direct Sales';
        shipment.incoterms = 'DDU';
        shipment.parcels_attributes[0].shipment_items_attributes.push({
          declared_currency: UserSession.company.currency,
        });
      }

      return shipment;
    }
  }
})();

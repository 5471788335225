import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import TrackingExcellent from '@icons/tracking/TrackingExcellent';
import TrackingRegular from '@icons/tracking/TrackingRegular';
import TrackingBasic from '@icons/tracking/TrackingBasic';
import TrackingLimited from '@icons/tracking/TrackingLimited';
import TrackingEmpty from '@icons/tracking/TrackingEmpty';
import { TrackingQuality } from '@client/core/corelogic/models/TrackingQuality';
import { CellProps } from './d';
import CellLayout from './CellLayout';

const svgs = {
  [TrackingQuality.None]: TrackingEmpty,
  [TrackingQuality.Limited]: TrackingLimited,
  [TrackingQuality.Basic]: TrackingBasic,
  [TrackingQuality.Regular]: TrackingRegular,
  [TrackingQuality.Excellent]: TrackingExcellent,
};

const trackingLabels = {
  [TrackingQuality.None]: 'none',
  [TrackingQuality.Limited]: 'limited',
  [TrackingQuality.Basic]: 'basic',
  [TrackingQuality.Regular]: 'regular',
  [TrackingQuality.Excellent]: 'excellent',
};

export const TrackingCell = memo(({ row }: CellProps) => {
  const shipment = row.original;
  const trackingRate = shipment.courier_tracking_rating;

  const SVGComponent = (trackingRate in svgs && svgs[trackingRate as keyof typeof svgs]) || null;
  const label = trackingLabels[trackingRate as keyof typeof trackingLabels];
  return (
    <CellLayout className="min-w-[156px] flex flex-col gap-1 justify-center px-2">
      {shipment.courier?.name && SVGComponent && (
        <div className="flex flex-row justify-start items-center font-bold gap-2">
          <SVGComponent />
          <div className="text-ink-900">
            <FormattedMessage id={`shipments.list.tracking.${label}.label`} />
          </div>
        </div>
      )}
      {shipment.courier?.name && trackingRate >= 1 && (
        <div className="font-normal leading-5 text-ink-900 sm">
          <FormattedMessage id="shipments.list.tracking.delivery-confirmation" />
        </div>
      )}
    </CellLayout>
  );
});

(function () {
  ShipmentAction.$inject = [
    '$q',
    '$state',
    'ReportService',
    'Shipment',
    'UserSession',
    'ReturnService',
  ];
  function ShipmentAction($q, $state, ReportService, Shipment, UserSession, ReturnService) {
    const service = this;

    service.ReportService = ReportService;

    service.singleShipmentSave = function (apiParams, payload) {
      return $q(function (resolve, reject) {
        Shipment.save(
          apiParams,
          payload,
          function (res) {
            resolve(res);
          },
          function (err) {
            reject(err);
          }
        );
      });
    };

    service.singleShipmentUpdate = function (apiParams, payload) {
      return $q(function (resolve, reject) {
        Shipment.update(
          apiParams,
          payload,
          function (res) {
            resolve(res);
          },
          function (err) {
            reject(err);
          }
        );
      });
    };

    service.getLastDraft = function (apiParams, payload) {
      return $q(function (resolve, reject) {
        Shipment.getLastDraft(
          apiParams,
          payload,
          function (res) {
            resolve(res);
          },
          function (err) {
            reject(err);
          }
        );
      });
    };

    service.getSubsetOfShipment = function (field, object) {
      const subsetKeys = selectKeys(field);

      return _.pick(object, subsetKeys);
    };

    /**
     * [syncOrders] Initiate the download of orders from a given store for a given company
     * Gets a report_id used to call the ReportService
     *
     * @param  {Object} syncParams:
     *  {
     *    created_before
          created_after
          store_id
     *  }
     * @param  {Object} store: whole store object
     * @param  {String} source: Mixpanel event property
     * @return {Promise}
     */
    service.syncOrders = function (store, syncParams, source, successCallback, noOrdersCallback) {
      if (!syncParams) {
        syncParams = {
          created_before: new Date(),
          created_after: new Date(),
          store_id: store.id,
          job_description: 'Manual Store Sync Store Page',
        };

        syncParams.created_after.setDate(syncParams.created_before.getDate() + 1);
        syncParams.created_after.setMonth(syncParams.created_before.getMonth() - 1);
      }

      if (!source) {
        source = 'Create Shipments > Advanced';
      }

      return $q(function (resolve, reject) {
        if (!store) reject();

        ReportService.resetReportService();
        ReportService.busy = true;

        Shipment.apiUpload(
          { company_type: 'cloud', company_id: UserSession.company.id },
          syncParams,
          function (response) {
            ReportService.getReport({
              id: response.report_id,
              listParams: { origin: $state.current.name },
              platform: store.platform.css_class,
              store,
              successCallback,
              noOrdersCallback,
              source,
            });
            resolve();
          },
          function () {
            ReportService.busy = false;
            reject();
          }
        );
      });
    };

    service.mergeAllPrepare = function () {
      const params = {
        company_id: UserSession.company.id,
        company_type: 'cloud',
        by_search: true,
      };

      return Shipment.mergeAllPrepare(params)
        .$promise.then(function (response) {
          return response;
        })
        .catch(function (error) {
          throw error;
        });
    };

    service.mergeAllConfirm = function () {
      return Shipment.mergeAllConfirm(
        { company_id: UserSession.company.id, company_type: 'cloud' },
        { by_search: true }
      )
        .$promise.then(function (response) {
          return response;
        })
        .catch(function (error) {
          throw error;
        });
    };

    service.mergeSelectedConfirm = function (payload) {
      return Shipment.mergeSelectedConfirm(
        { company_id: UserSession.company.id, company_type: 'cloud' },
        { shipment_ids: payload.shipments, merged_shipment_id: payload.id, by_search: true }
      )
        .$promise.then(function (response) {
          return response;
        })
        .catch(function (error) {
          throw error;
        });
    };

    service.splitConfirm = function (shipmentItemIdsWithQuantity, shipmentId) {
      return Shipment.splitConfirm(
        {
          company_id: UserSession.company.id,
          company_type: 'cloud',
          include: 'shipment_items',
          id: shipmentId,
        },
        {
          split_shipment_item_ids_with_quantity: shipmentItemIdsWithQuantity,
          by_search: true,
        }
      )
        .$promise.then(function (response) {
          return response;
        })
        .catch(function (error) {
          throw error;
        });
    };

    service.sendConsigneeEmail = function (shipmentId, payload) {
      return Shipment.sendConsigneeEmail(
        { company_id: UserSession.company.id, id: shipmentId, company_type: 'cloud' },
        payload
      )
        .$promise.then(function (data) {
          return data;
        })
        .catch(function (err) {
          throw err;
        });
    };

    service.itemsBulkEditConfirm = function (payload) {
      const params = {
        company_id: UserSession.company.id,
        company_type: 'cloud',
      };

      return Shipment.itemsBulkEditConfirm(params, payload)
        .$promise.then(function (response) {
          return response;
        })
        .catch(function (error) {
          throw error;
        });
    };

    service.duplicateAndSanitizeShipment = function (shipment) {
      const sanitizedShipment = angular.copy(shipment);

      const fieldsToClearInShipment = [
        'id',
        'platform_order_number',
        'created_at',
        'updated_at',
        'label_paid_at',
        'easyship_shipment_id',
        'tracking_number',
        'trackings',
        'consignee_notified_at',
        'pickup',
        'status_records',
        'checkpoints',
        'store',
        'box',
        'shipment_items_attributes',
      ];

      fieldsToClearInShipment.forEach(function (field) {
        delete sanitizedShipment[field];
      });

      // Clean all the IDs so the BE knows that it is a new data
      sanitizedShipment.parcels_attributes = sanitizedShipment.parcels_attributes.map(function (
        parcel
      ) {
        delete parcel.id;

        parcel.shipment_items_attributes.map(function (item) {
          delete item.id;
          delete item.parcel_id;

          return item;
        });

        return parcel;
      });

      sanitizedShipment.platform_name = 'Direct Sales';

      return sanitizedShipment;
    };

    service.getShipmentsId = function (shipments, excludedShipments) {
      const shipmentsId = [];
      shipments.forEach(function (shipment) {
        if (excludedShipments.indexOf(shipment.id) === -1) {
          shipmentsId.push(shipment.id);
        }
      });

      return shipmentsId;
    };

    function selectKeys(field) {
      switch (field) {
        case 'Receiver':
          return [
            'id',
            'destination_name',
            'destination_company_name',
            'address_line_1',
            'address_line_2',
            'city',
            'destination_country_id',
            'phone_number',
            'email_address',
            'postal_code',
            'state',
            'set_as_residential',
            'consignee_tax_id',
            'origin_address',
            'origin_address_id',
            'origin_country_id',
            'automated_return_requested', // We have a logic to reset this if the shipment is not US domestic
            'contains_liquids_user_input',
            'contains_battery_pi967_user_input',
          ];
        case 'Order':
        case 'Information':
          return [
            'id',
            'incoterms',
            'is_insured',
            'platform_name',
            'platform_order_number',
            'quote_selected_courier_id',
            'recalculate_shipment',
            'seller_notes',
            'order_tag_list',
            'additional_services',
            'parcels_attributes',
            'eei_reference',
            'broker_id',
            'order_data',
            'automated_return_requested',
            'metadata',
            'contains_liquids_user_input',
            'contains_battery_pi967_user_input',
          ];
      }
    }

    function defaultParams(shipment_id, company_id, company_type) {
      return {
        company_type: company_type == null ? 'cloud' : company_type,
        company_id,
        id: shipment_id,
        include: 'shipment_items,list_of_couriers,store',
      };
    }
  }

  angular
    .module('app.service.ShipmentAction', [
      'app.global.report',
      'app.global.shipment',
      'app.manage-shipments',
      'ui.router',
    ])
    .service('ShipmentAction', ShipmentAction);
})();

import React, { useEffect, useState, useRef, FormEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { react2angular } from 'react2angular';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';
import SearchIcon from '@material-ui/icons/Search';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import COLORS from '@client/src/colors';
import { IAdvanceSearchTagItem } from 'typings/tags';

import { toastError } from '@client/core/components/react/Toastify';
import { useDebouncedEffect } from './useDebouncedEffect';
import style from './TagSearch.r.module.scss';
import ReactRootProviders from '../../context/ReactRootProviders';
import { TagsService } from '../../services/tags/tags.service';

const useStyles = makeStyles({
  root: {
    backgroundColor: COLORS.skyDarkest,
    height: 20,
    borderRadius: 4,
    margin: '0 5px 5px 0',
    padding: '2px 6px',
    lineHeight: '16px',

    '&:hover': {
      backgroundColor: COLORS.inkFaded,
    },
  },
  label: { fontWeight: 'normal', color: 'white', padding: 2 },
  progress: { backgroundColor: COLORS.tealLight },
  bar: { backgroundColor: COLORS.tealNormal },
});

interface TagSearchProps {
  $injector: angular.auto.IInjectorService;
  $scope: ng.IScope;
  tags?: string[];
  onChange?: (tags: string[]) => void;
}

export default function TagSearch({
  $scope,
  $injector,
  tags = [],
  onChange,
}: TagSearchProps): JSX.Element {
  const TagsService = $injector.get<TagsService>('TagsService');

  const [keyword, setKeyword] = useState('');
  const [newTag, setNewTag] = useState('');
  const [searchMode, setSearchMode] = useState(true);
  const [showingUnselected, setShowingUnselected] = useState(true);
  const [displayedTags, setDisplayedTags] = useState<IAdvanceSearchTagItem[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>(tags);
  const [allLoaded, setAllLoaded] = useState<boolean>(false);
  const { progress, bar, ...chipClasses } = useStyles();
  const progressClasses = { root: progress, bar };
  const loader = useRef<HTMLDivElement>(null);
  const { formatMessage } = useIntl();

  if (tags !== selectedTags) {
    setKeyword('');
    setSelectedTags(tags);
    setDisplayedTags(
      displayedTags.map((tagItem) => ({ ...tagItem, selected: tags.includes(tagItem.value) }))
    );
  }

  const searchTags = (newKeyword?: string, append = false): ng.IPromise<void> => {
    if (append && allLoaded) return Promise.resolve();
    if (!append) setAllLoaded(false);

    return TagsService.query(newKeyword || keyword, append ? displayedTags.length : 0)
      .then((tagItems) => {
        if (tagItems && tagItems.length === 0) setAllLoaded(true);

        const injectSelection = (tagItem: IAdvanceSearchTagItem) => ({
          ...tagItem,
          selected: selectedTags.includes(tagItem.value),
        });
        setDisplayedTags(
          (append ? displayedTags : []).concat((tagItems || []).map(injectSelection))
        );
      })
      .catch(() => {
        toastError(formatMessage({ id: 'toast.default-error' }));
        setDisplayedTags([]);
      });
  };

  // Initialize infinite scroll watcher
  useEffect(() => {
    let observed = false;
    const handleObservation = ([target]: IntersectionObserverEntry[]) => {
      if (target.isIntersecting && !observed && displayedTags.length) {
        searchTags(undefined, true);
        observed = true;
      }
    };
    const options = { root: null, threshold: 1.0 };
    const observer = new IntersectionObserver(handleObservation, options);

    if (loader.current) {
      observer.observe(loader.current);
    }
    return () => observer.disconnect(); // returns the function that will clean up the observer
  }, [loader.current, displayedTags.length]);

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayedTags([]);
    setAllLoaded(false);
    setKeyword(event.target.value);
  };

  const handleSelection = (tagItem: IAdvanceSearchTagItem) => {
    const displayIndex = displayedTags.findIndex(({ value }) => value === tagItem.value);
    if (displayIndex >= 0) {
      const displayedTagsClone = displayedTags.slice();
      displayedTagsClone.splice(displayIndex, 1, {
        ...displayedTags[displayIndex],
        selected: !displayedTags[displayIndex].selected,
      });
      setDisplayedTags(displayedTagsClone);
    }

    const newSelections = selectedTags.includes(tagItem.value)
      ? selectedTags.filter((tag) => tag !== tagItem.value)
      : selectedTags.concat([tagItem.value]);
    setSelectedTags(newSelections);

    if (onChange) {
      onChange(newSelections);
      $scope.$apply();
    }
  };

  const handleAddition = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (searchMode || newTag.length === 0) return;

    const preparedTag = newTag.toLowerCase();
    const existingTagItem = displayedTags.find((tagItem) => tagItem.value === preparedTag);

    if (existingTagItem) {
      if (!existingTagItem.selected) handleSelection(existingTagItem);
      setNewTag('');
      return;
    }

    const newSelections = selectedTags.concat([preparedTag]);
    const newTagItem = { value: preparedTag, display: preparedTag, selected: true };

    // Display in component
    setSelectedTags(newSelections);
    setDisplayedTags(
      displayedTags.concat([newTagItem]).sort((a, b) => (a.value > b.value ? 1 : -1))
    );
    setNewTag('');

    // Add tag to database via API
    TagsService.newTag(preparedTag);

    // Trigger response in parent components
    if (onChange) {
      onChange(newSelections);
      $scope.$apply();
    }
  };

  useDebouncedEffect(() => searchTags(keyword), [keyword], 300);

  return (
    <form className={style.root} onSubmit={handleAddition}>
      {searchMode ? (
        <Input
          placeholder={formatMessage({
            id: 'shipments.modal.shipment-details.search-tags',
          })}
          className={style.inputElement}
          value={keyword}
          onChange={handleInput}
          disableUnderline
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon color="disabled" />
            </InputAdornment>
          }
        />
      ) : (
        <Input
          placeholder={formatMessage({
            id: 'shipments.modal.shipment-details.tag-enter',
          })}
          className={style.inputElement}
          value={newTag}
          onChange={(event) => setNewTag(event.target.value)}
          disableUnderline
          startAdornment={
            <InputAdornment position="start">
              <AddBoxIcon color="disabled" />
            </InputAdornment>
          }
        />
      )}
      {searchMode && !displayedTags.length && allLoaded && (
        <p className="font-bold text-base text-center !mt-[5px]">
          <FormattedMessage id="shipments.modal.shipment-details.no-tag-match" />
        </p>
      )}

      <div className={style.container}>
        {selectedTags.map((value) => (
          <Chip
            key={value}
            label={value}
            classes={chipClasses}
            className={style.selected}
            size="small"
            onClick={() => handleSelection({ value })}
          />
        ))}
      </div>

      <div className={style.container}>
        {searchMode &&
          showingUnselected &&
          displayedTags
            .filter(({ selected }) => !selected)
            .map((tagItem) => (
              <Chip
                key={tagItem.value}
                label={tagItem.value}
                classes={chipClasses}
                size="small"
                onClick={() => handleSelection(tagItem)}
              />
            ))}
        {searchMode && showingUnselected && !allLoaded && (
          <LinearProgress ref={loader} classes={progressClasses} />
        )}
      </div>
      <div className={style.footer}>
        <div className={`${style.searchModeButtons} ${searchMode ? '' : style.toggled}`}>
          <Button
            onClick={() => setShowingUnselected(!showingUnselected)}
            className={`${style.buttonNocaps} ${style.buttonToggle}`}
          >
            <FormattedMessage
              id={`shipments.modal.shipment-details.${
                showingUnselected ? 'hide' : 'show'
              }-unassigned-tags`}
            />
          </Button>
          <Button onClick={() => setSearchMode(false)} className={style.buttonAdd}>
            <FormattedMessage id="shipments.modal.shipment-details.add-tag" />
          </Button>
        </div>
        <Button
          onClick={() => setSearchMode(true)}
          className={style.buttonNocaps}
          startIcon={<ArrowBackIosIcon />}
        >
          <FormattedMessage id="shipments.modal.shipment-details.search-tags" />
        </Button>
      </div>
    </form>
  );
}

function WrappedTagSearch(props: TagSearchProps) {
  return (
    <ReactRootProviders>
      <TagSearch {...props} />
    </ReactRootProviders>
  );
}

export const AngularTagSearch = react2angular(
  WrappedTagSearch,
  ['tags', 'onChange'],
  ['$injector', '$scope']
);

import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import Button from '@client/core/components/react/Button';
import Input from '@client/core/components/react/Input';

interface EditorVersionDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (feedback: string) => void;
}

export default function EditorVersionDialog({
  open,
  onClose,
  onSubmit,
}: EditorVersionDialogProps): JSX.Element {
  const [feedback, setFeedback] = useState('');
  const { formatMessage } = useIntl();

  return (
    <Dialog maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <span>
            <FormattedMessage id="dashboard-header.shipments.viewer-selector.dialog-title" />
          </span>
          <IconButton aria-label="close modal" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h3" paragraph style={{ fontWeight: 'normal' }}>
          <FormattedMessage id="dashboard-header.shipments.viewer-selector.dialog-content" />
        </Typography>
        <Input
          multiline
          rows={12}
          placeholder={formatMessage({
            id: 'dashboard-header.shipments.viewer-selector.dialog-placeholder',
          })}
          style={{ width: 400 }}
          onChange={({ currentTarget }) => setFeedback(currentTarget.value)}
        />
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-around' }}>
        <Button variant="contained" color="primary" onClick={() => onSubmit(feedback)}>
          <FormattedMessage id="dashboard-header.shipments.viewer-selector.dialog-submit" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import template from './request-pickup-courier-handover-options.html?raw';
import style from './request-pickup-courier-handover-options.module.scss';

const handoverOptionsOrder = ['pickup', 'dropoff', 'handover'];

class RequestPickupCourierHandoverOptionsCtrl {
  static $inject = ['$filter', 'CheckoutService', 'AddressService', 'UserSession'];

  constructor($filter, CheckoutService, AddressService, UserSession) {
    this.$filter = $filter;
    this.CheckoutService = CheckoutService;
    this.AddressService = AddressService;
    this.style = style;
    this.UserSession = UserSession;
  }

  $onInit() {
    this.handoverOptions = this._addAdditionalAttributes(this.handoverOptions)
      .reduce(
        (agg, opt) =>
          agg.some(({ handover_option_id }) => handover_option_id === opt.handover_option_id)
            ? agg
            : [...agg, opt],
        []
      )
      .sort(
        (a, b) =>
          handoverOptionsOrder.indexOf(a.category) - handoverOptionsOrder.indexOf(b.category)
      );
  }

  updateTimeSlotsDropdown(dayChosen, handoverOption) {
    this.CheckoutService.updateTimeSlotsDropdown({
      dayChosen,
      handoverOption,
      courierIndex: this.courierIndex,
      originIndex: this.originIndex,
    });
  }

  updateCheckoutService(handoverOption) {
    this.CheckoutService.updateCheckoutService({
      handoverOption,
      courierIndex: this.courierIndex,
      originIndex: this.originIndex,
    });
    this.CheckoutService.data.lastSelectedHandoverOptions = handoverOption;
  }

  isPickupTimeSelectorOpen(handoverOption) {
    return (
      this.CheckoutService.payload.origins[this.originIndex].pickups[this.courierIndex]
        .handover_option_id === handoverOption.handover_option_id
    );
  }

  doesHandoverHavePickupMessage(handoverOption, address) {
    return (
      this._isHandlerUSPS(handoverOption) &&
      this._isHandoverOptionAPickup(handoverOption) &&
      this.AddressService.isAddressInvalidForPickup(address.pickup_address_id)
    );
  }

  isPickupHandoverOptionIncomplete() {
    const selectedHandoverOption = this.CheckoutService.getCourierSelectedHandoverOption(
      this.originIndex,
      this.courierIndex
    );
    const errorOptions = this.CheckoutService.getErrorOptionState();

    return (
      (errorOptions && !selectedHandoverOption.selected_date) ||
      (errorOptions && !selectedHandoverOption.time_slot_id)
    );
  }

  isPickupDateMissing() {
    const selectedHandoverOption = this.CheckoutService.getCourierSelectedHandoverOption(
      this.originIndex,
      this.courierIndex
    );
    const errorOptions = this.CheckoutService.getErrorOptionState();

    return errorOptions && !selectedHandoverOption.selected_date;
  }

  isPickupTimeSlotMissing() {
    const selectedHandoverOption = this.CheckoutService.getCourierSelectedHandoverOption(
      this.originIndex,
      this.courierIndex
    );
    const errorOptions = this.CheckoutService.getErrorOptionState();

    return errorOptions && !selectedHandoverOption.time_slot_id;
  }

  isHandoverOptionNotSelected() {
    const errorOptions = this.CheckoutService.getErrorOptionState();
    const selectedHandoverOption = this.CheckoutService.getCourierSelectedHandoverOption(
      this.originIndex,
      this.courierIndex
    );

    return errorOptions && !selectedHandoverOption.handover_option_id;
  }

  showHandoverOptionErrorMessage(handoverOption) {
    const errorOptions = this.CheckoutService.getErrorOptionState();
    const selectedHandoverOption = this.CheckoutService.getCourierSelectedHandoverOption(
      this.originIndex,
      this.courierIndex
    );

    return (
      ((errorOptions && !selectedHandoverOption.selected_date) ||
        (errorOptions && !selectedHandoverOption.time_slot_id)) &&
      selectedHandoverOption.handover_option_id === handoverOption.handover_option_id
    );
  }

  isAutoRetryEligible(handoverOption) {
    return (
      this._isHandlerUPS(handoverOption) &&
      this.UserSession.getCompanyDashboardSettings().beta_feature_auto_retry_pickup
    );
  }

  _addAdditionalAttributes(handoverOptions) {
    return handoverOptions.map((handoverOption) => {
      if (this._isHandoverOptionAPickup(handoverOption)) {
        handoverOption.days = this._buildDaysForOneHandoverOption(handoverOption.slots);
      }

      handoverOption.displayableOption = `pickups.reschedule.${handoverOption.category}`;

      return handoverOption;
    });
  }

  _buildDaysForOneHandoverOption(slots) {
    return Object.entries(slots).map(([dateString, handoverOptions]) => ({
      date: dateString,
      displayableDate: this.$filter('intlDate')(dateString),
      isDisabled: handoverOptions.length === 0,
    }));
  }

  _setAndFormatSlotsArray(dayChosen, slots) {
    return _.map(slots[dayChosen], (slot) => {
      slot.displayableTime = `${slot.from_time} - ${slot.to_time}`;

      return slot;
    });
  }

  _isHandoverOptionAPickup(handoverOption) {
    return handoverOption.category === 'pickup';
  }

  _isHandlerUSPS(handoverOption) {
    return handoverOption.handler_name === 'USPS';
  }

  _isHandlerUPS(handoverOption) {
    return handoverOption.handler_name === 'UPS';
  }

  get handoverOptionId() {
    return this.CheckoutService.payload.origins[this.originIndex].pickups[this.courierIndex]
      .handover_option_id;
  }
}

const RequestPickupCourierHandoverOptionsComponent = {
  controller: RequestPickupCourierHandoverOptionsCtrl,
  template,
  bindings: {
    handoverOptions: '<',
    courierIndex: '<',
    originIndex: '<',
    companyCurrency: '<',
    isEasyshipCourier: '<',
  },
};

export { RequestPickupCourierHandoverOptionsComponent };

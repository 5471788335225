import { createPopper } from '@popperjs/core';

import { FEATURE_KEY } from '@client/data/subscription';
import iconLiquidUrl from '@assets/images/dashboard/create-shipment-main-modal/icon-liquid.svg';
import iconBatteryUrl from '@assets/images/dashboard/create-shipment-main-modal/icon-battery.svg';
import template from './item-list.html?raw';
import style from './item-list.module.scss';

const MANDATORY = 'mandatory';

class ItemListController {
  iconLiquidUrl = iconLiquidUrl;

  iconBatteryUrl = iconBatteryUrl;

  static $inject = [
    '$q',
    '$timeout',
    '$element',
    '$translate',
    '$scope',
    'UserSession',
    'CountryService',
    'BoxService',
    'FlatRateBoxService',
    'ProductListingService',
    'SubscriptionService',
    'API',
    'RestrictionsService',
    'ItemCategoryService',
    'DestinationAddressesService',
  ];

  constructor(
    $q,
    $timeout,
    $element,
    $translate,
    $scope,
    UserSession,
    CountryService,
    BoxService,
    FlatRateBoxService,
    ProductListingService,
    SubscriptionService,
    API,
    RestrictionsService,
    ItemCategoryService,
    DestinationAddressesService
  ) {
    this.$q = $q;
    this.$timeout = $timeout;
    this.$element = $element;
    this.$translate = $translate;
    this.$scope = $scope;
    this.UserSession = UserSession;
    this.CountryService = CountryService;
    this.BoxService = BoxService;
    this.FlatRateBoxService = FlatRateBoxService;
    this.ProductListingService = ProductListingService;
    this.SubscriptionService = SubscriptionService;
    this.API = API;
    this.RestrictionsService = RestrictionsService;
    this.ItemCategoryService = ItemCategoryService;
    this.DestinationAddressesService = DestinationAddressesService;

    this.style = style;
    this.itemCategories = [];
    this.parcels = [];
    this.preserveCourier = true;
    this.allBoxes = [];
    this.selectedBoxes = [];
    this.dimensions = [];
    this.categoryHsCodeSelectedValue = [];
    this.suggestedHsCodes = {};
    this.matchingProducts = {};
    this.typeaheadTemplate = `
      <span class="flex items-center">
        <es-platform-logo
          class="!mr-[5px]"
          es-key="%css_class"
        >
        </es-platform-logo>
        <span>
          {{%identifier|h}} - {{%name|h}} {{%displayItemCategoryName}} {{%displayHsCode}} ({{%display_dimensions}} - {{%display_weight}}{{%weight_unit}}) {{%display_origin_country}} {{%displayLiquid}} {{%displayBattery}}
        </span>
      </span>
    `;
    this.deleteConfirmationPromptIndex = null;
    this.showMoveItemConfirmationPrompt = false;
    this.showModal = {
      enterpriseCall: false,
      upgrade: false,
    };

    this.onCategorySelect = this.onCategorySelect.bind(this);
    this.onHsCodeSelect = this.onHsCodeSelect.bind(this);
    this.onOunceWeightChange = this.onOunceWeightChange.bind(this);
    this.isMultiBoxFeatureVisible = false;
    this.isMultiBoxFeatureUsable = false;
    this.isBasicFlow = window.location.pathname.includes('basic');
  }

  get minimumDeclaredValue() {
    const enableZeroDeclaredValue =
      this.UserSession.company.dashboard_settings.beta_feature_zero_declared_value &&
      this.isDomesticShipment;
    return this.isDescPriceQtyOptional || enableZeroDeclaredValue ? 0 : 0.01;
  }

  get containsBattery() {
    return !!this.shipment?.contains_battery_pi967_user_input;
  }

  get minimumQuantityValue() {
    return this.isDescPriceQtyOptional && !this.containsBattery ? 0 : 1;
  }

  get isSimplifiedFlow() {
    return this.UserSession.getFeatureFlagsByFlagName('smb_simplified_domestic_shipping_flow');
  }

  get isDomesticShipment() {
    return this.esOriginCountryId === this.esDestinationCountryId;
  }

  get isShipmentEnsured() {
    return this.esIsEnsured;
  }

  get isDescPriceQtyOptional() {
    return (
      this.isSimplifiedFlow &&
      this.isDomesticShipment &&
      !this.isShipmentEnsured &&
      this.isBasicFlow
    );
  }

  $onInit() {
    /**
     * Add `parcels` local state so we can filter out empty boxes before passing it to the API
     * TODO: Check in the parent level why it is returning `undefined` when landing on `/basic/add-shipment`
     */
    this.parcels = this.esParcels || [];
    this.allBoxes = new Array(this.parcels.length).fill([]);
    this.selectedBoxes = [];
    this.dimensions = [];

    this._normalizeBoxProperties();
    this._normalizeWeight();
    this._prepareCategoriesHsCode();
    this._prepareSuggestedHsCodes();
    this._checkMultiBoxSupport();

    this.ItemCategoryService.getItemCategories().then(
      (itemCategories) => (this.itemCategories = itemCategories)
    );
    this._setInitialParcelQtyToOne();
    if (this._isLuxUser()) {
      this._setLuxDefaultHsCode();
      this._setLuxDefaultDescription();
      // Needs to be a watcher in order to overwrite custom box when resuming shipment
      this.$scope.$watch(
        () => this.parcels,
        (parcels) => {
          parcels.forEach((parcel) => {
            if (parcel.id === null) this._setLuxSmallestBoxAsDefault();
          });
        }
      );
    }
  }

  $onChanges() {
    if (this._isLuxUser()) {
      this._setLuxDefaultHsCode();
      this._setLuxDefaultDescription();
    }
  }

  cleanParcelsAndTriggerChange() {
    const parcelsToProcess = this.isSimplifiedFlow
      ? this.parcels
      : this.parcels.filter((parcel) => !this.isParcelFieldsUnfilled(parcel));
    this.esOnChange({ parcels: parcelsToProcess, preserveCourier: this.preserveCourier });
  }

  addItem(parcelIndex) {
    this.parcels[parcelIndex].shipment_items_attributes.push({
      declared_currency: this.UserSession.company.currency,
      quantity: 1,
    });

    this.cleanParcelsAndTriggerChange();
  }

  duplicateItem(parcelIndex, itemIndex) {
    // Make sure the object is clean by destroying all the reference
    const clonedItem = angular.copy(this.parcels[parcelIndex].shipment_items_attributes[itemIndex]);
    // Clean all the IDs so the BE knows that it is a new data
    delete clonedItem.id;

    this.parcels[parcelIndex].shipment_items_attributes.splice(itemIndex + 1, 0, clonedItem);
    this.categoryHsCodeSelectedValue[parcelIndex].splice(
      itemIndex + 1,
      0,
      this.categoryHsCodeSelectedValue[parcelIndex][itemIndex]
    );

    this.cleanParcelsAndTriggerChange();
  }

  removeItem(parcelIndex, itemIndex) {
    this.parcels[parcelIndex].shipment_items_attributes.splice(itemIndex, 1);
    this.categoryHsCodeSelectedValue[parcelIndex].splice(itemIndex, 1);

    this.cleanParcelsAndTriggerChange();
  }

  addBox() {
    if (!this.isMultiBoxFeatureUsable) {
      if (
        this.SubscriptionService.currentSubscription.plan.id ===
        this.SubscriptionService.planIds.Enterprise
      ) {
        this.showModal.enterpriseCall = true;
      } else {
        this.showModal.upgrade = true;
      }

      return;
    }

    this.parcels.push({
      package_data: {},
      shipment_items_attributes: [
        {
          declared_currency: this.UserSession.company.currency,
          quantity: 1,
        },
      ],
    });
    this.allBoxes.push([]);
    this.categoryHsCodeSelectedValue.push([]);

    this._normalizeBoxProperties(this.parcels.length - 1);
    this.cleanParcelsAndTriggerChange();
  }

  removeBox(parcelIndex) {
    if (this.parcels[parcelIndex].id) {
      this.parcels[parcelIndex]._destroy = true;
    } else {
      this.parcels.splice(parcelIndex, 1);
      this.categoryHsCodeSelectedValue.splice(parcelIndex, 1);
    }

    this.deleteConfirmationPromptIndex = null;
    this.cleanParcelsAndTriggerChange();
  }

  showDeleteConfirmationPrompt(parcelIndex) {
    this.deleteConfirmationPromptIndex = parcelIndex;
  }

  hideDeleteConfirmationPrompt() {
    this.deleteConfirmationPromptIndex = null;
  }

  duplicateBox(parcelIndex) {
    // Make sure the object is clean by destroying all the reference
    const clonedParcel = angular.copy(this.parcels[parcelIndex]);
    const clonedCategoryHsCode = angular.copy(this.categoryHsCodeSelectedValue[parcelIndex]);

    // Clean all the IDs so the BE knows that it is a new data
    delete clonedParcel.id;
    clonedParcel.shipment_items_attributes.map((item) => {
      delete item.id;

      return item;
    });

    this.parcels.splice(parcelIndex + 1, 0, clonedParcel);
    this.categoryHsCodeSelectedValue.splice(parcelIndex + 1, 0, clonedCategoryHsCode);
    this.allBoxes.push([]);
    this._normalizeBoxProperties(parcelIndex + 1);
    this.cleanParcelsAndTriggerChange();
  }

  moveItem($event, parcelIndex, itemIndex) {
    // Hide the popup if the same item is clicked
    if (
      this.showMoveItemConfirmationPrompt &&
      this.itemToMoveParcelIndex === parcelIndex &&
      this.itemToMoveIndex === itemIndex
    ) {
      this.hideMoveItemConfirmationPrompt();
      return;
    }

    // Fix issue where the next popup will automatically close when there is already an open popup
    $event.stopPropagation();

    this.showMoveItemConfirmationPrompt = true;
    this.itemToMoveParcelIndex = parcelIndex;
    this.itemToMoveIndex = itemIndex;

    this.$timeout(() => {
      const initializer = $event.target;
      const popper = this.$element.find(`[data-popper='multi-box-move-item']`)[0];
      popper.style.zIndex = 2;
      popper.style.display = 'block';

      createPopper(initializer, popper, {
        placement: 'bottom-end',
        modifiers: [
          {
            name: 'eventListeners',
            options: {
              scroll: false,
              resize: false,
            },
          },
        ],
      });
    });
  }

  hideMoveItemConfirmationPrompt() {
    this.showMoveItemConfirmationPrompt = false;
  }

  onConfirmMoveItem(quantity, parcelIndex) {
    const itemToMove =
      this.parcels[this.itemToMoveParcelIndex].shipment_items_attributes[this.itemToMoveIndex];
    const categoryHsCodeToMove =
      this.categoryHsCodeSelectedValue[this.itemToMoveParcelIndex][this.itemToMoveIndex];

    // Remove the items if the user want to move everything else highlight the items that changed
    if (quantity === itemToMove.quantity) {
      this.parcels[this.itemToMoveParcelIndex].shipment_items_attributes = this.parcels[
        this.itemToMoveParcelIndex
      ].shipment_items_attributes.filter((item, index) => index !== this.itemToMoveIndex);

      this.categoryHsCodeSelectedValue[this.itemToMoveParcelIndex].splice(this.itemToMoveIndex, 1);
    } else {
      this.parcels[this.itemToMoveParcelIndex].shipment_items_attributes[this.itemToMoveIndex] = {
        ...itemToMove,
        quantity: itemToMove.quantity - quantity,
        _moved: true,
      };
    }

    let newItem = angular.copy(itemToMove);
    newItem = {
      ...newItem,
      quantity,
      _moved: true,
    };
    delete newItem.id;

    const itemCount = this.parcels[parcelIndex].shipment_items_attributes.length;

    if (
      this.isItemFieldsUnfilled(this.parcels[parcelIndex].shipment_items_attributes[itemCount - 1])
    ) {
      this.parcels[parcelIndex].shipment_items_attributes[itemCount - 1] = newItem;
      this.categoryHsCodeSelectedValue[parcelIndex][itemCount - 1] = categoryHsCodeToMove;
    } else {
      this.parcels[parcelIndex].shipment_items_attributes.push(newItem);
      this.categoryHsCodeSelectedValue[parcelIndex].push(categoryHsCodeToMove);
    }

    this.cleanParcelsAndTriggerChange();
    this.hideMoveItemConfirmationPrompt();

    // Removed the item highlight
    this.$timeout(() => {
      // Only do the animation if there are still remaining quantities in an item
      if (quantity !== itemToMove.quantity) {
        this.parcels[this.itemToMoveParcelIndex].shipment_items_attributes[
          this.itemToMoveIndex
        ]._moved = false;
      }

      this.parcels[parcelIndex].shipment_items_attributes[
        this.parcels[parcelIndex].shipment_items_attributes.length - 1
      ]._moved = false;
    }, 3000);
  }

  getParcelCount() {
    return this.parcels.filter((parcel) => {
      return !parcel._destroy;
    }).length;
  }

  isPlanBadgeVisible(featureKey) {
    return this.SubscriptionService.isPlanBadgeVisible(featureKey);
  }

  get courierAlertMessage() {
    if (!this.esCourierAlerts) return '';

    return Array.isArray(this.esCourierAlerts)
      ? this.esCourierAlerts.join(' ')
      : this.esCourierAlerts;
  }

  isItemFieldsUnfilled(item) {
    const {
      company_sku,
      description,
      item_category_id,
      hs_code_description,
      declared_customs_value,
      quantity,
    } = item;

    const noCategoryHsCodeSelected = !item_category_id || !hs_code_description;
    const isDefaultQuantity = !quantity || quantity <= 1;

    return (
      !company_sku &&
      !description &&
      noCategoryHsCodeSelected &&
      !declared_customs_value &&
      isDefaultQuantity
    );
  }

  isParcelFieldsUnfilled({
    package_data: { length, width, height },
    total_actual_weight,
    shipment_items_attributes,
  }) {
    const areItemsFieldsUnfilled = shipment_items_attributes.every((item) => {
      return this.isItemFieldsUnfilled(item);
    });

    return !length && !width && !height && !total_actual_weight && areItemsFieldsUnfilled;
  }

  isParcelFieldsRequired(parcel, parcelIndex) {
    if (this.isDescPriceQtyOptional) return false;
    if (parcelIndex === 0) return true;

    return !this.isParcelFieldsUnfilled(parcel);
  }

  isItemActionsAlwaysShow(parcelIndex, itemIndex) {
    return (
      this.showMoveItemConfirmationPrompt &&
      this.itemToMoveParcelIndex === parcelIndex &&
      this.itemToMoveIndex === itemIndex
    );
  }

  onBoxChange(value, parcelIndex) {
    if (value === null || parcelIndex === null) return;

    const { id, flat_rate_box_id, length, width, height } = value;

    this.selectedBoxes[parcelIndex] = this.BoxService.selectBox(this.allBoxes[parcelIndex], value);

    this.dimensions[parcelIndex] = {
      length,
      width,
      height,
    };

    // Do not preserve courier if box type changed to any flat rate box
    this.preserveCourier = !flat_rate_box_id;

    const boxId = id || flat_rate_box_id || null;
    const packageableType = id ? 'Box' : 'FlatRateBox';

    this.parcels[parcelIndex].packageable_id = boxId;
    this.parcels[parcelIndex].packageable_type = boxId ? packageableType : '';
    this.parcels[parcelIndex].package_data = value;

    this.cleanParcelsAndTriggerChange();
  }

  onDimensionsChange(value, parcelIndex) {
    const { length, width, height } = value;

    this.dimensions[parcelIndex] = {
      length,
      width,
      height,
    };

    this.parcels[parcelIndex].package_data.length = length;
    this.parcels[parcelIndex].package_data.width = width;
    this.parcels[parcelIndex].package_data.height = height;

    this.cleanParcelsAndTriggerChange();
  }

  onWeightChange(value, parcelIndex) {
    this.parcels[parcelIndex].total_actual_weight = Number(value);
    this.cleanParcelsAndTriggerChange();
  }

  onOunceWeightChange(unit, value, parcelIndex) {
    if (unit === 'oz' || unit === 'lb' || unit === 'kg' || unit === 'g') {
      this.parcels[parcelIndex].display_weight_unit = unit;
    }
    this.parcels[parcelIndex].display_weight = Number(value);
    this.cleanParcelsAndTriggerChange();
    this.$scope.$apply();
  }

  onCategorySelect({ categoryId }, parcelIndex, itemIndex) {
    const itemCategory = this.itemCategories.find((category) => category.id === categoryId);
    this.categoryHsCodeSelectedValue[parcelIndex][itemIndex] = {
      categoryId,
    };

    this.parcels[parcelIndex].shipment_items_attributes[itemIndex] = {
      ...this.parcels[parcelIndex].shipment_items_attributes[itemIndex],
      item_category_id: categoryId,
      hs_code: null,
      hs_code_description: null,
      contains_liquids: itemCategory?.contains_liquids,
      contains_battery_pi966: itemCategory?.contains_battery_pi966,
      contains_battery_pi967: itemCategory?.contains_battery_pi967,
    };
  }

  onHsCodeSelect({ hsCodeNumber, hsCodeDescription }, parcelIndex, itemIndex) {
    this.categoryHsCodeSelectedValue[parcelIndex][itemIndex] = {
      hsCodeNumber,
      hsCodeDescription,
    };

    this.parcels[parcelIndex].shipment_items_attributes[itemIndex] = {
      ...this.parcels[parcelIndex].shipment_items_attributes[itemIndex],
      hs_code: hsCodeNumber,
      hs_code_description: hsCodeDescription,
      item_category_id: null,
      contains_liquids: false,
      contains_battery_pi965: false,
      contains_battery_pi966: false,
      contains_battery_pi967: false,
    };

    this.suggestedHsCodes[hsCodeNumber] = hsCodeDescription;
  }

  onItemChange(value, field, parcelIndex, itemIndex) {
    switch (field) {
      case 'quantity': {
        this.parcels[parcelIndex].shipment_items_attributes[itemIndex].quantity = Number(value);

        break;
      }
      default: {
        this.parcels[parcelIndex].shipment_items_attributes[itemIndex][field] = value;
      }
    }
    this.cleanParcelsAndTriggerChange();
  }

  onProductQuery(value, field, parcelIndex, itemIndex) {
    const index = `${parcelIndex}-${itemIndex}`;

    const constructDisplayValue = (product) => {
      const displayHsCode = product.hs_code ? `HS:${product.hs_code}` : '';
      const itemCategoryName =
        product.item_category_id &&
        this.itemCategories.find((item) => {
          return item.id === product.item_category_id;
        });
      const displayItemCategoryName = itemCategoryName ? `${itemCategoryName.name}` : '';
      const displayDimensions = `${product.height || '?'}x${product.width || '?'}x${
        product.length || '?'
      }`;
      const displayOriginCountry = product.origin_country_id
        ? `- ${this.$translate.instant('global.origin')}: ${this.CountryService.getCountryNameById(
            product.origin_country_id
          )}`
        : '';

      const displayLiquid = product.contains_liquids
        ? `- ${this.$translate.instant('shipments.category-hs-codes.contain-liquid')}`
        : '';

      const displayBattery =
        product.contains_battery_pi966 || product.contains_battery_pi967
          ? `- ${this.$translate.instant('shipments.category-hs-codes.lithium-ion-battery')}${
              product.contains_battery_pi966
                ? ' (UN3481-PI966)'
                : product.contains_battery_pi967
                ? ' (UN3481-PI967)'
                : ''
            } `
          : '';

      return {
        ...product,
        dimensions_unit: this.UserSession.getCompanyDimensionsUnit(),
        weight_unit: this.UserSession.getCompanyWeightUnit(),
        display_dimensions: displayDimensions,
        display_weight: product.weight || '?',
        display_origin_country: displayOriginCountry,
        displayLiquid,
        displayBattery,
        displayHsCode,
        displayItemCategoryName,
      };
    };

    this.matchingProducts[index] = {};

    this.ProductListingService.suggest(value, field).then((products) => {
      this.matchingProducts[index][field] = products.map(constructDisplayValue);
    });
  }

  onProductSelect(product, parcelIndex, itemIndex) {
    const categoryId = product.item_category_id;
    const hsCodeNumber = product.hs_code;
    const hsCodeDescription = product.hs_code_description;
    const originCountryAlpha2 =
      (product.origin_country_id &&
        this.CountryService.getCountryCodeById(product.origin_country_id)) ||
      null;

    this.parcels[parcelIndex].shipment_items_attributes[itemIndex] = {
      quantity: 1,
      ...this.parcels[parcelIndex].shipment_items_attributes[itemIndex],
      company_sku: product.identifier,
      description: product.name,
      item_category_id: categoryId,
      hs_code: hsCodeNumber,
      hs_code_description: hsCodeDescription,
      product_id: product.id,
      height: product.height,
      length: product.length,
      width: product.width,
      origin_country_alpha2: originCountryAlpha2,
      pick_location: product.pick_location,
      contains_battery_pi966: product.contains_battery_pi966,
      contains_battery_pi967: product.contains_battery_pi967,
      contains_liquids: product.contains_liquids,
    };

    let categoryHsCode = null;

    if (product.hs_code && product.hs_code_description) {
      categoryHsCode = {
        hsCodeNumber: product.hs_code,
        hsCodeDescription: product.hs_code_description,
      };
    } else if (product.item_category_id) {
      categoryHsCode = {
        categoryId: product.item_category_id,
      };
    }

    this.categoryHsCodeSelectedValue[parcelIndex][itemIndex] = categoryHsCode;

    if (hsCodeNumber && hsCodeDescription) {
      this.suggestedHsCodes[hsCodeNumber] = hsCodeDescription;
    }
  }

  /**
   * Get the parcel error from either `logs.shipment` or `logs.parcels`
   * Remove once the BE removed the `logs.shipment` completely - once multi box is stable
   */
  getParcelsError(id, field, parcelIndex) {
    if (!this.esErrorLogs) return '';

    let errorLogs;
    const { parcels, shipment } = this.esErrorLogs;
    const parcelsErrorLogs = parcels && parcels[id];

    // If no `logs.parcels`, only show the error on the first box
    if (!parcelsErrorLogs && parcelIndex === 0) {
      errorLogs = shipment;
    } else {
      errorLogs = parcelsErrorLogs;
    }

    if (!errorLogs) return '';

    if (
      field === 'dimensions' &&
      Object.keys(errorLogs).find((f) => f === ('length' || 'width' || 'height'))
    ) {
      return ['length', 'width', 'height']
        .map((measurement) => errorLogs[measurement])
        .join('<br>');
    }

    return errorLogs[field];
  }

  getShipmentItemsError(id, field) {
    if (!this.esErrorLogs) return '';

    const errorLogs = this.esErrorLogs.shipmentItems;

    if (!errorLogs || !errorLogs[id]) return '';

    return errorLogs[id][field];
  }

  currentSubscription() {
    return this.SubscriptionService.currentSubscription;
  }

  suggestedPlanId() {
    if (
      this.SubscriptionService.isCurrentSubscriptionLoaded &&
      this.SubscriptionService.isPlansDetailLoaded
    ) {
      return this.SubscriptionService.getSuggestedPlanIdByFeatureKey(FEATURE_KEY.MultiBoxShipments);
    }

    return 0;
  }

  // Sync local state of box and dimension when doing CRUD operation on box
  async _normalizeBoxProperties(startIndex = null) {
    for (const [index, parcel] of this.parcels.entries()) {
      if (startIndex !== null && index < startIndex) continue;

      const {
        packageable_id,
        packageable_type,
        package_data: { length, width, height, is_box_fitted },
      } = parcel;
      const [customBoxes, flatRateBoxes] = await this.$q.all([
        this.BoxService.getCustomBoxesSelectOptions({
          id: packageable_id,
          length,
          width,
          height,
          is_box_fitted,
        }),
        this.FlatRateBoxService.getFlatRateBoxesSelectOptions(),
      ]);

      this.allBoxes[index] = [...customBoxes, ...flatRateBoxes];
      this.selectedBoxes[index] = this.BoxService.selectBox(this.allBoxes[index], packageable_id);

      // Make sure input will show empty value if the BE return `0`
      this.dimensions[index] = {
        length: length || null,
        width: width || null,
        height: height || null,
      };

      /**
       * Add data that is needed by the BE to detect the correct box
       * This will fix the issue where the box switch to a custom box when box selection is untouched
       */
      if (packageable_type) {
        const boxIdKey = packageable_type === 'Box' ? 'box_id' : 'flat_rate_box_id';

        this.parcels[index].package_data[boxIdKey] = packageable_id;
      }
    }
  }

  // Make sure input will show empty value if the BE return `0`
  _normalizeWeight() {
    this.parcels = this.parcels.map((parcel) => {
      return Object.assign(parcel, { total_actual_weight: parcel.total_actual_weight || null });
    });
  }

  _setInitialParcelQtyToOne() {
    this.parcels.forEach((parcel) => {
      parcel.shipment_items_attributes.forEach((item) => {
        if (!item.quantity) {
          Object.assign(item, { quantity: 1 });
        }
      });
    });
  }

  _prepareCategoriesHsCode() {
    this.parcels.forEach((parcel, parcelIndex) => {
      this.categoryHsCodeSelectedValue[parcelIndex] = [];

      parcel.shipment_items_attributes.forEach(
        ({ item_category_id, hs_code, hs_code_description }) => {
          let categoryHsCodeSelectedValue = null;

          if (hs_code && hs_code_description) {
            categoryHsCodeSelectedValue = {
              hsCodeNumber: hs_code,
              hsCodeDescription: hs_code_description,
            };
          } else if (item_category_id) {
            categoryHsCodeSelectedValue = {
              categoryId: item_category_id,
            };
          }

          this.categoryHsCodeSelectedValue[parcelIndex].push(categoryHsCodeSelectedValue);
        }
      );
    });
  }

  _prepareSuggestedHsCodes() {
    this.parcels.forEach((parcel) => {
      parcel.shipment_items_attributes.forEach(({ hs_code, hs_code_description }) => {
        if (hs_code && hs_code_description) {
          this.suggestedHsCodes[hs_code] = hs_code_description;
        }
      });
    });
  }

  checkShowHScodeBaseOnRestrictionApi = (shipment) => {
    const { input_fields } = shipment;
    return (
      input_fields.hs_code.require === MANDATORY ||
      input_fields.item_category_id.require === MANDATORY
    );
  };

  showHsCode() {
    if (this.isDescPriceQtyOptional) {
      return this.requireHScodeByUSMilitaryState;
    }

    return true;
  }

  _checkMultiBoxSupport() {
    this.RestrictionsService.getShipmentRestrictions({
      origin_country_id: this.esOriginCountryId,
      destination_country_id: this.esDestinationCountryId,
      origin_postal_code: this.shipment.origin_address.postal_code,
      destination_postal_code: this.shipment.postal_code,
    })
      .then(({ shipment }) => {
        this.isMultiBoxFeatureVisible = shipment.features.multi_box.is_visible;
        this.isMultiBoxFeatureUsable = shipment.features.multi_box.is_usable;
        this.requireHScodeByUSMilitaryState = this.checkShowHScodeBaseOnRestrictionApi(shipment);
      })
      .catch(() => {
        this.isMultiBoxFeatureVisible = false;
        this.isMultiBoxFeatureUsable = false;
      });
  }

  // START Methods used for shipping flow customizing on Luxottica accounts

  _isLuxUser() {
    return this.UserSession.isLuxUser();
  }

  _setLuxDefaultHsCode() {
    this.parcels.forEach((parcel, parcelIndex) => {
      parcel.shipment_items_attributes.forEach((item, itemIndex) => {
        Object.assign(item, {
          hs_code: luxHsCode.hsCodeNumber,
          hs_code_description: luxHsCode.hsCodeDescription,
        });
        if (this.categoryHsCodeSelectedValue[parcelIndex]) {
          this.categoryHsCodeSelectedValue[parcelIndex][itemIndex] = luxHsCode;
        } else {
          this.categoryHsCodeSelectedValue.push([luxHsCode]);
        }
      });
    });
  }

  _setLuxDefaultDescription() {
    this.parcels.forEach((parcel) => {
      parcel.shipment_items_attributes.forEach((item) => {
        if (!item.description) {
          Object.assign(item, {
            description:
              this.DestinationAddressesService.glossary[
                this.DestinationAddressesService.selectedLuxotticaCommodity
              ],
          });
        }
      });
    });
  }

  async _setLuxSmallestBoxAsDefault() {
    // Needs to await _normalizeBoxProperties in order to have the list of boxes available
    await this._normalizeBoxProperties();

    this.parcels.forEach((_, index) => {
      if (!this._getSmallestNonCustomBox(this.allBoxes[index])) return;
      this.onBoxChange(this._getSmallestNonCustomBox(this.allBoxes[index]), index);
    });
  }

  _getSmallestNonCustomBox(boxesArray) {
    const boxesArrayToTransform = boxesArray.slice(0);
    const sortedBoxesArray = boxesArrayToTransform.sort(
      (a, b) => this._getBoxSize(a) - this._getBoxSize(b)
    );
    const getFirstNonNullBoxWithID = sortedBoxesArray.find(
      (box) => this._getBoxSize(box) > 0 && box.id
    );
    return getFirstNonNullBoxWithID;
  }

  _getBoxSize(box) {
    return box.width * box.height * box.length;
  }

  // END Methods used for shipping flow customizing on Luxottica accounts

  getPlanNameByFeatureKey(featureKey) {
    return this.SubscriptionService.getPlanNameByFeatureKey(featureKey);
  }
}

const luxHsCode = {
  hsCodeNumber: '90049000',
  hsCodeDescription:
    'Spectacles, goggles and the like, corrective, protective or other (excl. spectacles for testing eyesight, sunglasses, contact lenses, spectacle lenses and frames and mountings for spectacles)',
};

const ItemListComponent = {
  template,
  controller: ItemListController,
  bindings: {
    esParcels: '<',
    esOriginCountryId: '<',
    esDestinationCountryId: '<',
    esIsEnsured: '<',
    esErrorLogs: '<',
    esDisabled: '<',
    esCourierAlerts: '<',
    esOnChange: '&',
    esRecommendDimensions: '<',
    esHasTrySubmit: '<',
    shipment: '<',
  },
};

export { ItemListComponent };

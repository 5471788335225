import React from 'react';
import { FormattedMessage } from 'react-intl';
import Cookies from 'universal-cookie';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Alert, { AlertProps } from '@client/core/components/react/Alert';
import Button from '@client/core/components/react/Button';
import PlatformLogo from '@client/core/components/react/PlatformLogo';
import { IStore } from 'typings/store';
import MixpanelService from '@client/core/services/mixpanel/mixpanel.service';
import ReactRootProviders from '../../context/ReactRootProviders';

interface DisconnectedStoreAlertProps extends AlertProps {
  store: IStore;
  source?: string;
  onDismissal?: (id: string) => void;
  $injector: angular.auto.IInjectorService;
}

const dismissedAlertsCookieName = 'ES_DISMISSED_ALERTS';

export default function DisconnectedStoreAlert({
  store,
  source,
  onDismissal,
  $injector,
  ...props
}: DisconnectedStoreAlertProps) {
  const handleRedirect = () => {
    const { go } = $injector.get('$state') as ng.ui.IStateService;
    go('app.single-store', { id: store.id });
    MixpanelService.track('Disconnected Store - See Details - Banner', {
      source,
      store_id: store.id,
      store_name: store.name,
      platform: store.platform?.display_name,
    });
  };

  const handleDismiss = () => {
    const cookies = new Cookies();
    const dismissed = cookies.get(dismissedAlertsCookieName) || [];

    cookies.set(dismissedAlertsCookieName, [...dismissed, store.id], { path: '/' });

    onDismissal && onDismissal(store.id);
  };

  return (
    <Alert
      severity="error"
      action={
        <>
          <Button
            onClick={handleRedirect}
            variant="contained"
            size="small"
            style={{ whiteSpace: 'nowrap' }}
          >
            <FormattedMessage id="global.fix-now" />
          </Button>
          <IconButton onClick={handleDismiss} aria-label="close" className="!ml-[5px]" size="small">
            <CloseIcon />
          </IconButton>
        </>
      }
      style={{ marginBottom: 15 }}
      {...props}
    >
      {store.platform?.css_class && (
        <PlatformLogo platformNameSlug={store.platform.css_class} size="sm" className="mr-1" />
      )}
      <FormattedMessage id="connect.needs-reconnect" values={{ name: store.name }} />
    </Alert>
  );
}

export function WrappedDisconnectedStoreAlert(props: DisconnectedStoreAlertProps) {
  return (
    <ReactRootProviders>
      <DisconnectedStoreAlert {...props} />
    </ReactRootProviders>
  );
}

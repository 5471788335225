import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { CourierLogo } from '@client/data/courier';

import template from './connect-courier.html?raw';
import style from './connect-courier.module.scss';

// Enum
const FedexStep = {
  Eula: 'eula',
  Form: 'form',
  Invoice: 'invoice',
  PinChoice: 'pin-choice',
  PinSMS: 'pin-sms',
  PinEmail: 'pin-email',
};
const NEW_FEDEX_ACCOUNT_ID = 'fedex-v1';

const EMPTY_ADDRESS = {
  address_line: {
    line_1: '',
    line_2: '',
    line_3: '',
  },
  postal_code: '',
  city: '',
  state_province_code: '',
  country_code: '',
};

const EMPTY_SHIPPER_ACCOUNT = {
  shipper_account: {
    invoice_info: { currency_code: '' },
  },
};

class ConnectCourier {
  translations = {};

  static $inject = [
    '$sce',
    '$scope',
    '$translate',
    'API',
    'UserSession',
    'CourierAccounts',
    'CourierService',
    'CourierCreate',
    'MixpanelService',
    'AddressService',
    'CountryService',
    'PostalCodeService',
  ];

  constructor(
    $sce,
    $scope,
    $translate,
    API,
    UserSession,
    CourierAccounts,
    CourierService,
    CourierCreate,
    MixpanelService,
    AddressService,
    CountryService,
    PostalCodeService
  ) {
    this.style = style;
    this.$sce = $sce;
    this.$scope = $scope;
    this.$translate = $translate;
    this.API = API;
    this.UserSession = UserSession;
    this.CourierAccounts = CourierAccounts;
    this.CourierService = CourierService;
    this.CourierCreate = CourierCreate;
    this.MixpanelService = MixpanelService;
    this.AddressService = AddressService;
    this.CountryService = CountryService;
    this.PostalCodeService = PostalCodeService;
    this.courierDetails = {};
    this.currencies = [];
    this.countries = [];
    this.loading = { submit: false };
    this.FedexStep = FedexStep;
    this.fedexCurrentStep = FedexStep.Eula;
    this.fedexCompatible2023CourierData = {};
    this.hasValidInvoice = false;
    this.fixedPriceAmountInputSettings = {
      maxDecimals: 2,
      allowNegative: false,
    };
    this.fedexLastPinRequestedAt = null;

    this.AU_ID = 14;
    this.CA_ID = 39;
    this.US_ID = 234;
    this.US_CA_INVOICE_DAYS = 90;
    this.INVOICE_DAYS = 45;

    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.deleteCourier = this.deleteCourier.bind(this);
    this.onBackClick = this.onBackClick.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
    this.onChangeStep = this.onChangeStep.bind(this);
    this.sendVerificationPinCode = this.sendVerificationPinCode.bind(this);
    this.isConnectLYOCUserActionEnabled = this.UserSession.isConnectLYOCUserActionEnabled();
  }

  $onInit() {
    this.wrappers = {
      redirectToSupportPage: {
        a: (chunk) => {
          return `<a ng-click="$ctrl.onRedirectSupportPage()">${chunk}</a>`;
        },
      },
    };

    this.$translate(['toast.default-error', 'toast.incomplete-form']).then((translations) => {
      this.translations = translations;
    });

    if (!this.courier) return;

    if (this.courier.logoUrl === CourierLogo.Ups) {
      this.CountryService.getValidReceiverCountries().then(({ countries, currencies }) => {
        this.countries = countries;
        this.currencies = currencies;

        this.courierDetails.address = this.courierDetails.address || cloneDeep(EMPTY_ADDRESS);
        this.courierDetails.shipper_account =
          this.courierDetails.shipper_account || cloneDeep(EMPTY_SHIPPER_ACCOUNT);

        // Fallback, if no default pickup address is defined
        this.courierDetails.address.country_code =
          this.courierDetails.address.country_code ||
          this.CountryService.getCountryCodeById(this.UserSession.company.country_id);
        this.courierDetails.shipper_account.country_code =
          this.courierDetails.shipper_account.country_code ||
          this.CountryService.getCountryCodeById(this.UserSession.company.country_id);
      });
    }

    // Edit incomplete connect flow
    if (this.courier.account) {
      const { status, errors } = this.courier;

      if (status && errors && errors.length > 0) {
        this.error = status;
      }

      this.courierDetails = {
        ...this.courierDetails,
        ...this.courier.account,
      };
    } else if (this.courier.logoUrl === CourierLogo.Ups) this._prefillUpsReadyFormData();
  }

  passportApiKeyRegex = ['^[a-zA-Z0-9]+$'];

  isFedEx() {
    return [CourierLogo.Fedex, CourierLogo.FedexCrossBorder].includes(this.courier.logoUrl);
  }

  get showNewFedexLyoc() {
    // New flow is only enabled for `fedex`, not for cross border one
    return this.courier.logoUrl === CourierLogo.Fedex && this.UserSession.isNewFedexLyocEnabled();
  }

  get isAuPostMyPostBusiness() {
    return this.courier.logoUrl === CourierLogo.MyPost;
  }

  onInputChange(value, key) {
    this.courierDetails[key] = value;
  }

  onAddressChange(value, key) {
    this.courierDetails.address[key] = value;
  }

  onAddressLinesChange(value, key) {
    this.courierDetails.address.address_line[key] = value;
  }

  onShipperAccountChange(value, key) {
    this.courierDetails.shipper_account[key] = value;
  }

  onShipperAccountInvoiceInfoChange(value, key) {
    if (!this.courierDetails.shipper_account.invoice_info) {
      this.courierDetails.shipper_account.invoice_info = {};
      return;
    }

    if (key === 'invoice_date') {
      this.courierDetails.shipper_account.invoice_info[key] =
        moment(value).format('yyyy-MM-DDThh:mm:ss');
      return;
    }

    this.courierDetails.shipper_account.invoice_info[key] = value;
  }

  onFormChange(courierDetails) {
    this.courierDetails = courierDetails;
  }

  onChangeStep(step) {
    this.error = '';
    this.fedexCurrentStep = step;
    this.$scope.$apply();
  }

  onBackClick() {
    this.error = null;

    if (this.isFedEx() && this.fedexCurrentStep !== FedexStep.Eula) {
      switch (this.fedexCurrentStep) {
        case FedexStep.Invoice:
          this.fedexCurrentStep = FedexStep.Form;
          break;
        case FedexStep.PinChoice:
          this.fedexCurrentStep = FedexStep.Invoice;
          break;
        case FedexStep.PinEmail:
        case FedexStep.PinSMS:
          this.fedexCurrentStep = FedexStep.PinChoice;
          break;
        default:
          this.fedexCurrentStep = FedexStep.Eula;
          break;
      }

      return;
    }

    this.courierDetails = {};

    this.connectCourierForm &&
      this.connectCourierForm.$$controls.forEach((ngModelController) => {
        ngModelController.$setPristine();
      });

    this.onBack();

    this.$scope.$apply();
  }

  onRedirectSupportPage() {
    const support = {
      [CourierLogo.Fedex]: '/hc/en-us/articles/360056947771-Link-Your-FedEx-Account',
      [CourierLogo.Dhl]: '/hc/en-us/articles/360039958872-Link-Your-DHL-Express-Account',
      [CourierLogo.AustraliaPost]:
        '/hc/en-us/articles/360027090711-Link-Your-Account-Australia-Post',
      [CourierLogo.Ups]: '/hc/en-us/articles/360039959232-Link-Your-UPS-Account',
      [CourierLogo.StarTrack]: '/hc/en-us/',
      [CourierLogo.AustraliaPostOnDemand]:
        '/hc/en-us/articles/360027090711-Link-Your-Account-Australia-Post',
      [CourierLogo.RoyalMail]: '/hc/en-us/articles/360046578311-Link-Your-Royal-Mail-Account',
      [CourierLogo.Sendle]: '/hc/en-us/articles/360035365152/',
      [CourierLogo.CanadaPost]: '/hc/en-us/articles/4407016235533-Link-Your-Canada-Post-Account',
      [CourierLogo.MyPost]:
        '/hc/en-us/articles/12379903974809-Link-Your-Australia-Post-Mypost-Business-Account',
      // TODO: change link to Passport support page, when it emerges: https://easyship-team.atlassian.net/wiki/spaces/TECH/pages/786104345?focusedCommentId=800391174
      [CourierLogo.Passport]: '/hc/en-us/articles/23885130521625-Link-Your-Passport-Account',
    };
    const supportUrl = `${this.API.help}${support[this.courier.logoUrl] || '/hc/en-us'}`;
    window.open(supportUrl, '_blank', 'noopener');
  }

  // Check the next step for the selected courier
  validateCourierStep() {
    if (this.connectCourierForm.$invalid) {
      this.error = this.translations['toast.incomplete-form'];
    } else {
      this.error = '';

      if (this.isFedEx() && this.fedexCurrentStep === FedexStep.Eula) {
        this.fedexCurrentStep = FedexStep.Form;

        return;
      }

      this.onSubmitForm();
    }
  }

  _prefillUpsReadyFormData() {
    const { first_name, last_name, email } = this.UserSession.user;
    const { name, company_url, currency = 'USD' } = this.UserSession.company;

    this.courierDetails = {
      company_name: name,
      company_url,
      customer_name: `${first_name} ${last_name}`,
      email,
      address: { address_line: {} },
      shipper_account: {
        invoice_info: { currency_code: currency },
      },
    };

    this.AddressService.getDefaultAddress('pickup').then((data) => {
      const defaultAddress = data;
      this.invoiceDays = [this.CA_ID, this.US_ID].includes(defaultAddress.country_id)
        ? this.US_CA_INVOICE_DAYS
        : this.INVOICE_DAYS;

      this.today = new Date();
      this.oldestInvoiceDateAllowed = new Date(new Date().setDate(this.today.getDate() - 90));

      this.courierDetails.address = {
        address_line: {
          line_1: defaultAddress.line_1,
          line_2: defaultAddress.line_2,
          line_3: defaultAddress.line_3,
        },
        postal_code: defaultAddress.postal_code,
        city: defaultAddress.city,
        state_province_code: defaultAddress.state,
        country_code: defaultAddress.country.alpha2,
      };
      this.courierDetails.shipper_account.country_code = defaultAddress.country.alpha2;

      if (this.courierDetails.shipper_account.country_code && this.courier.account) {
        const { nickname, title, phone_extension, phone_number } = this.courier.account;
        this.courierDetails = {
          ...this.courierDetails,
          nickname,
          title,
          phone_extension,
          phone_number,
        };
      }
    });
  }

  _checkIfUpsInvoiceInfoNeeded() {
    if (this.courier.logoUrl === CourierLogo.Ups) {
      if (!this.hasValidInvoice) {
        delete this.courierDetails.shipper_account.invoice_info;
      }
    }
  }

  getFormPayload(account) {
    if (this.showNewFedexLyoc && this.fedexCurrentStep === FedexStep.Form) {
      return {
        umbrella_name: NEW_FEDEX_ACCOUNT_ID,
        account: {
          nickname: account.nickname,
          account_number: account.account_number,
          origin_country_id: account.origin_country_id,
          country_code: account.country_code,
          postal_code: account.postal_code,
          city: account.city,
          state: account.state,
          line_1: account.line_1,
          line_2: account.line_2,
        },
      };
    }

    if (this.fedexCurrentStep === FedexStep.Invoice) {
      return {
        id: this.fedexCompatible2023CourierData.courierId,
        account: {
          nickname: this.fedexCompatible2023CourierData.courierNickname,
          validation_option: 'invoice',
          pin_code: null,
          invoice_details: {
            number: account.invoice_details.number,
            currency: account.invoice_details.currency,
            date: account.invoice_details.date,
            amount: account.invoice_details.amount,
          },
        },
      };
    }

    if (this.showNewFedexLyoc && this.fedexCurrentStep === FedexStep.PinChoice) {
      return {
        id: this.fedexCompatible2023CourierData.courierId,
        account: {
          validation_option: account.validation_option,
        },
      };
    }

    const isPinVerificationStep = this._isPinVerificationStep();
    if (this.showNewFedexLyoc && isPinVerificationStep) {
      return {
        id: this.fedexCompatible2023CourierData.courierId,
        account: {
          nickname: this.fedexCompatible2023CourierData.courierNickname,
          validation_option: this.fedexCurrentStep === FedexStep.PinSMS ? 'sms' : 'email',
          pin_code: account.pin_code,
          invoice_details: {},
        },
      };
    }

    return {
      umbrella_name: this.courier.logoUrl,
      account,
    };
  }

  onSubmitForm(courierDetails) {
    this._checkIfUpsInvoiceInfoNeeded();

    const account = courierDetails || this.courierDetails;
    if (this.courier.logoUrl === CourierLogo.Dhl) {
      delete account.site_id;
    }
    const payload = this.getFormPayload(account);

    if (this.isAuPostMyPostBusiness) {
      window.localStorage.setItem('myPostBusinessUserAccountName', this.courierDetails.nickname);
      this.connectToAuPostMyPostBusiness();
      return;
    }

    if (this.courier.logoUrl === CourierLogo.Ups) {
      payload.access_license_text = this.CourierAccounts.accessLicenseAgreement;
    }

    if (this.isEditMode) {
      payload.id = this.courier.id;
    }

    const isPinChoiceOrVerificationStep = this._isPinChoiceOrVerificationStep();
    if (!isPinChoiceOrVerificationStep) {
      payload.account.origin_country_id = this.courier.country_id;
    }

    this.MixpanelService.track('Courier - Add Service - Connect', {
      courier_name: this.courier.logoUrl,
    });

    this.loading.submit = true;

    const courierAction = this._getCourierAction(payload);

    courierAction
      .then((response) => {
        if (this.showNewFedexLyoc && this.fedexCurrentStep === FedexStep.Form) {
          this.fedexCompatible2023CourierData = {
            courierId: response.id,
            courierNickname: payload.account.nickname,
          };

          this.fedexCurrentStep = FedexStep.Invoice;

          return;
        }

        if (this.fedexCurrentStep === FedexStep.PinChoice) {
          this.fedexCurrentStep =
            account.validation_option === 'email' ? FedexStep.PinEmail : FedexStep.PinSMS;
          this.error = '';
          return;
        }

        this.CourierService.getCouriers({
          refetch: true,
        });

        this.onSubmit({ linkedCourier: response });
      })
      .catch((error) => {
        this.error = (error.data && error.data.status) || this.translations['toast.default-error'];

        this.MixpanelService.track('Courier - Add Service - Connect - Failure', {
          error_reason: this.error,
          courier_name: this.courier.logoUrl,
        });

        const isPinVerification =
          this.fedexCurrentStep === FedexStep.PinSMS ||
          this.fedexCurrentStep === FedexStep.PinEmail;
        if (isPinVerification) {
          this.courierDetails.pin_code = null;
        }

        if (this.fedexCurrentStep === FedexStep.PinChoice) {
          toastError(this.$translate.instant('toast.default-error'));
        }
      })
      .finally(() => {
        this.CourierAccounts.getActiveCourierAccounts(this.UserSession.getCompanyId());

        this.loading.submit = false;
      });
  }

  connectToAuPostMyPostBusiness() {
    const AU_MYPOST_BUSINESS_AUTHORIZE_WEBSITE =
      'https://auspost.com.au/mypost-business/authorisation/authorize?response_type=code';
    const EASYSHIP_CLIENT_ID = 'EASYSHIP-7402';
    const AU_MYPOST_BUSINESS_STATE = 'test1234';
    const DASHBOARD_URL = `${this.API.dashboard}`;

    const umbrellaName = this.courier.logoUrl;

    const AustraliaPostMyPostBusinessUrl = `${AU_MYPOST_BUSINESS_AUTHORIZE_WEBSITE}&client_id=${EASYSHIP_CLIENT_ID}&redirect_uri=${DASHBOARD_URL}/couriers/${umbrellaName}/redirect?umbrella_name=${umbrellaName}&state=${AU_MYPOST_BUSINESS_STATE}`;

    window.open(AustraliaPostMyPostBusinessUrl, '_self');

    this.MixpanelService.track('Courier - Add Service - Connect', {
      courier_name: this.courier.logoUrl,
    });
  }

  isSingleCountryCourier(logoUrl) {
    const nonInterationalCouriers = [
      CourierLogo.AustraliaPost,
      CourierLogo.AustraliaPostOnDemand,
      CourierLogo.StarTrack,
      CourierLogo.Sendle,
      CourierLogo.MyPost,
      CourierLogo.CanadaPost,
      CourierLogo.RoyalMail,
      CourierLogo.Passport,
    ];
    return nonInterationalCouriers.includes(logoUrl);
  }

  courierIsAustraliaPostCompany(logoUrl) {
    const australiaPostRelatedCouriers = [
      CourierLogo.AustraliaPost,
      CourierLogo.AustraliaPostOnDemand,
      CourierLogo.StarTrack,
    ];
    return australiaPostRelatedCouriers.includes(logoUrl);
  }

  showConnectCourierWarning(logoUrl) {
    return [
      CourierLogo.Dhl,
      CourierLogo.DhlEcommerce,
      CourierLogo.Ups,
      CourierLogo.Fedex,
      CourierLogo.FedexCrossBorder,
    ].includes(logoUrl);
  }

  getSubmitLabel() {
    if (this.showNewFedexLyoc) {
      switch (this.fedexCurrentStep) {
        case FedexStep.Eula:
          return 'global.i-accept';
        case FedexStep.Form:
          return 'global.next';
        case FedexStep.PinChoice:
          return 'courier.connect.form.fedex.pin-choice.send-code';
        default:
          return 'global.connect';
      }
    }

    if (this.isFedEx()) {
      return this.fedexCurrentStep === FedexStep.Eula ? 'global.i-accept' : 'global.submit';
    }

    return 'global.connect';
  }

  deleteCourier() {
    this.loading.submit = true;

    return this.CourierAccounts.deleteCourier({ id: this.courier.id })
      .then(() => {
        toastSuccess(
          this.$translate.instant('toast.delete-success', { noun: this.courier.account.nickname })
        );
        this.CourierService.getCouriers({ refetch: true });
        this.CourierCreate.close();
      })
      .catch((error) => {
        this.error =
          error.data && error.data.status
            ? error.data.status
            : this.translations['toast.default-error'];
      })
      .finally(() => {
        this.loading.submit = false;
      });
  }

  toggleHaveUpsInvoiceCheckbox() {
    this.hasValidInvoice = !this.hasValidInvoice;
  }

  _isPinVerificationStep = () =>
    this.fedexCurrentStep === FedexStep.PinSMS || this.fedexCurrentStep === FedexStep.PinEmail;

  _isPinChoiceOrVerificationStep = () =>
    this.fedexCurrentStep === FedexStep.PinChoice ||
    this.fedexCurrentStep === FedexStep.PinEmail ||
    this.fedexCurrentStep === FedexStep.PinSMS;

  _getCourierAction = (payload) => {
    if (this.fedexCurrentStep === FedexStep.PinChoice) {
      return this.sendVerificationPinCode({ payload });
    }

    let shouldUpdateCourier;
    if (this.UserSession.isNewFedexLyocEnabled && this.courier.logoUrl === CourierLogo.Fedex) {
      shouldUpdateCourier = this.fedexCurrentStep !== FedexStep.Form;
    } else {
      shouldUpdateCourier = this.isEditMode && this.courier.logoUrl !== CourierLogo.Dhl;
    }

    return shouldUpdateCourier
      ? this.CourierAccounts.updateCourierInfo(payload)
      : this.CourierAccounts.addCourier(payload);
  };

  sendVerificationPinCode = ({ payload }) => {
    this.fedexLastPinRequestedAt = new Date();
    this.courierDetails.pin_code = null;

    return this.CourierAccounts.generatePinCodeForCourierVerification(payload);
  };

  get canDelete() {
    return (
      this.courier.account && (!this.showNewFedexLyoc || this.fedexCurrentStep === FedexStep.Eula)
    );
  }

  get isEditMode() {
    if (this.courier.account && this.courier.id && this.showNewFedexLyoc) {
      return true;
    }

    return (
      this.courier.account &&
      this.courier.id &&
      ![CourierLogo.Ups, CourierLogo.Fedex].includes(this.courier.logoUrl)
    );
  }

  get isPasswordRequired() {
    return (
      this.courier.logoUrl !== CourierLogo.Sendle &&
      this.courier.logoUrl !== CourierLogo.Skypostal &&
      this.courier.logoUrl !== CourierLogo.RoyalMail &&
      this.courier.logoUrl !== CourierLogo.Dhl &&
      this.courier.logoUrl !== CourierLogo.Passport &&
      !this.isAuPostMyPostBusiness
    );
  }

  get modalHeaderName() {
    return `Connect ${this.courier.name}`;
  }

  get accountNumberPlaceholder() {
    switch (this.courier.logoUrl) {
      case CourierLogo.RoyalMail:
        return this.$translate.instant(
          'courier.connect.field.account-number.placeholder-royal-mail'
        );
      case CourierLogo.Dhl:
        return this.$translate.instant('courier.connect.field.account-number.placeholder-dhl');
      default:
        return '';
    }
  }

  get hasStepCounter() {
    return this.courier.logoUrl === CourierLogo.Ups || this.showNewFedexLyoc;
  }

  get currentStepCount() {
    if (this.courier.logoUrl === CourierLogo.Ups) {
      return '3';
    }

    if (this.showNewFedexLyoc) {
      switch (this.fedexCurrentStep) {
        case FedexStep.Eula:
          return '1';
        case FedexStep.Form:
          return '2';
        default:
          return '3';
      }
    }

    return '1';
  }

  get maxStepCount() {
    return this.courier.logoUrl === CourierLogo.Ups || this.showNewFedexLyoc ? '3' : '1';
  }

  get hideDefaultModalDescription() {
    return this.showNewFedexLyoc && this.fedexCurrentStep !== FedexStep.Eula;
  }
}

const ConnectCourierComponent = {
  controller: ConnectCourier,
  template,
  bindings: {
    onBack: '&',
    onSubmit: '&',
    courier: '<',
    country: '<',
  },
};

export { ConnectCourierComponent };

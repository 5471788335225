import React from 'react';
import AlertIcon from '@client/src/create-shipments/shipment-details-drawer/ShipmentDetailsDrawerToolbar/Tabs/components/TabLabelTitle/AlertIcon';
import CourierLogo from '@client/core/components/react/CourierLogo';
import { FormattedMessage } from 'react-intl';
import { DeliveryTimeMessage } from '@client/src/create-shipments/shipment-details-drawer/sections/shared-courier-section';
import TruncateText from '@client/core/components/react/TruncateText/TruncateText';
import { CellProps } from './d';
import CellLayout from './CellLayout';

export function CourierCell({ row }: CellProps) {
  const shipment = row.original;
  const maxDeliveryTime = Number(shipment.max_delivery_time);
  const minDeliveryTime = Number(shipment.min_delivery_time);
  const courierLogoUrl = shipment?.courier?.logo_url ?? '';
  const courierName = shipment?.courier?.display_name;

  return (
    <CellLayout className="flex min-w-[188px] gap-2 items-center px-2">
      {courierLogoUrl ? (
        <>
          <CourierLogo size="sm" slug={courierLogoUrl} classImage="!object-cover" />
          <div className="flex flex-col flex-grow text-left gap-1">
            <TruncateText
              label={courierName}
              className="font-bold whitespace-normal line-clamp-2 text-ink-900"
            />
            <div className="text-ink-500 text-base font-body font-normal">
              <DeliveryTimeMessage min={minDeliveryTime} max={maxDeliveryTime} />
            </div>
          </div>
        </>
      ) : (
        <>
          <AlertIcon severity="error" />
          <span className="text-red-700 text-[12px]">
            <FormattedMessage id="shipments.panel-header.not-available" />
          </span>
        </>
      )}
    </CellLayout>
  );
}

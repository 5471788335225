import { makeStyles, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import React from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { IFeature, PlanRanking } from 'typings/subscription';
import clsx from 'clsx';
import { useUserSession } from '@client/src/global/context/UserSessionProvider';
import { ICategorizedPlan, IPlan } from '../types';
import { mapFeatureWithPlan, mapTranslationId } from './utils';

const useTableBodyStyles = makeStyles((theme) => ({
  featureCell: {
    '& > div': {
      padding: '0 5px',
      color: theme.palette.ink.light,
      borderTop: `1px solid ${theme.palette.sky.dark}`,
      borderBottom: `1px solid ${theme.palette.sky.dark}`,
      height: '40px',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    '&:nth-child(2) > div': {
      borderLeft: `1px solid ${theme.palette.sky.dark}`,
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px',
    },
    '&:last-child > div': {
      borderRight: `1px solid ${theme.palette.sky.dark}`,
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
    },
    '&.active > div': {
      backgroundColor: theme.palette.sky.main,
      color: theme.palette.ink.dark,
    },
    '&.highlight > div': {
      backgroundColor: theme.palette.teal.light,
      color: theme.palette.teal.dark,
    },
  },
}));

interface TableBodyProps {
  features: IFeature[];
  standardPlans: ICategorizedPlan['standardPlans'];
  selectedScalePlan: IPlan | undefined;
  smallestRankingInScalePlans: PlanRanking;
}

export default function TableBody({
  features,
  standardPlans,
  selectedScalePlan,
  smallestRankingInScalePlans,
}: TableBodyProps) {
  const theme = useTheme();
  const classes = useTableBodyStyles();
  const {
    company: { currency },
  } = useUserSession();
  const intl = useIntl();

  const transformedFeatures = mapFeatureWithPlan({
    plans: [...standardPlans, selectedScalePlan].filter((plan): plan is IPlan => !!plan),
    features,
    smallestRankingInScalePlans,
  });

  return (
    <tbody>
      {transformedFeatures.map((feature) => (
        <tr key={feature.key}>
          <td className="!pb-[15px] !pr-[5px]">
            <Box color={theme.palette.ink.main} fontSize={theme.utils.fz.smallest} maxWidth={130}>
              <FormattedMessage id={`subscription.compare.feature.${feature.key}`} />
            </Box>
          </td>

          {feature.plans.map((plan) => (
            <td
              key={plan.id}
              className={clsx(
                'text-base text-center !pb-[15px]',
                classes.featureCell,
                plan.isCurrent && 'active',
                plan.isHighlight && 'highlight'
              )}
            >
              <div>
                {typeof plan.data === 'boolean' && plan.data && <i className="icon-tick" />}

                {typeof plan.data === 'number' && <FormattedNumber value={plan.data} />}

                {typeof plan.data === 'string' && mapTranslationId(plan.data) && (
                  <FormattedMessage
                    id={mapTranslationId(plan.data)}
                    values={plan.getTranslationValues?.({ intl, currency })}
                  />
                )}
              </div>
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
}

import React, { ReactElement } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader, { CardHeaderProps as MuiCardHeaderProps } from '@material-ui/core/CardHeader';
import CardContent, {
  CardContentProps as MuiCardContentProps,
} from '@material-ui/core/CardContent';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';

import COLORS from '@client/src/colors';
import Input from '@client/core/components/react/Input';
import { DimensionsUnitAbbreviation, WeightUnitAbbreviation } from 'typings/units';
import { ProductFormData } from '../reset-confirmation/ProductFormData';

interface ProductInfoCardProps {
  dimensionsUnit: DimensionsUnitAbbreviation;
  weightUnit: WeightUnitAbbreviation;
  CardHeaderProps: MuiCardHeaderProps;
  CardContentProps: MuiCardContentProps;
  placeholders: {
    length?: string;
    width?: string;
    height?: string;
    weight?: string;
  };
}

const useStyles = makeStyles({
  weightInput: {
    width: 96,

    '& .MuiInputAdornment-root > p': {
      fontWeight: 'normal',
      color: COLORS.inkFaded,
    },
  },
});

export default function ProductInfoCard({
  dimensionsUnit,
  weightUnit,
  CardHeaderProps,
  CardContentProps,
  placeholders = {},
}: ProductInfoCardProps): ReactElement {
  const { control } = useFormContext<ProductFormData>();
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <Card>
      <CardHeader {...CardHeaderProps} />
      <CardContent {...CardContentProps}>
        <Grid container spacing={2}>
          <Grid container item xs={6}>
            <FormLabel style={{ margin: 0, cursor: 'pointer' }} htmlFor="product-dimensions-length">
              <FormattedMessage id="global.dimensions" />
            </FormLabel>
            <Grid container alignItems="center">
              <Box width={56} clone>
                <Grid item>
                  <Controller
                    render={({ field, fieldState }) => (
                      <Input
                        id="product-dimensions-length"
                        type="number"
                        {...field}
                        error={!!fieldState.error}
                        placeholder={placeholders.length}
                      />
                    )}
                    control={control}
                    name="length"
                    rules={{ min: 0.001 }}
                  />
                </Grid>
              </Box>
              <Box m={0.5}>
                <Typography variant="body2" style={{ color: COLORS.skyDarkest }}>
                  x
                </Typography>
              </Box>
              <Box width={50} clone>
                <Grid item>
                  <Controller
                    render={({ field, fieldState }) => (
                      <Input
                        type="number"
                        {...field}
                        error={!!fieldState.error}
                        placeholder={placeholders.width}
                      />
                    )}
                    control={control}
                    name="width"
                    rules={{ min: 0.001 }}
                  />
                </Grid>
              </Box>
              <Box m={0.5}>
                <Typography variant="body2" style={{ color: COLORS.skyDarkest }}>
                  x
                </Typography>
              </Box>
              <Box width={50} clone>
                <Grid item>
                  <Controller
                    render={({ field, fieldState }) => (
                      <Input
                        type="number"
                        {...field}
                        error={!!fieldState.error}
                        placeholder={placeholders.height}
                      />
                    )}
                    control={control}
                    name="height"
                    rules={{ min: 0.001 }}
                  />
                </Grid>
              </Box>
              <Box m={0.5}>
                <Typography variant="body2" style={{ color: COLORS.skyDarkest }}>
                  {dimensionsUnit}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Controller
              render={({ field, fieldState }) => (
                <Input
                  label={formatMessage({ id: 'global.weight' })}
                  type="number"
                  endAdornment={<InputAdornment position="end">{weightUnit}</InputAdornment>}
                  className={classes.weightInput}
                  {...field}
                  error={!!fieldState.error}
                  placeholder={placeholders.weight}
                />
              )}
              control={control}
              rules={{ min: 0.001 }}
              name="weight"
            />
          </Grid>

          <Grid item xs={6}>
            <Controller
              render={({ field, fieldState }) => (
                <Input
                  label={formatMessage({ id: 'product-listing.details.sku' })}
                  {...field}
                  error={!!fieldState.error}
                  maxLength={100}
                />
              )}
              control={control}
              rules={{ required: false, maxLength: 100 }}
              name="identifier"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

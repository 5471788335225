import { makeStyles } from '@material-ui/core/styles';
import COLORS from '@client/src/colors';

export const useStyles = makeStyles({
  platformLogo: {
    width: 20,
    borderRadius: 2,
    margin: '0 5px',
  },
  cardHeader: {
    padding: '20px 30px',
    borderBottom: `thin solid ${COLORS.skyNormal}`,

    '& .MuiCardHeader-title': {
      fontSize: '16px !important',
      color: COLORS.inkDarkest,
    },
  },
  cardContent: {
    padding: '24px 32px',
  },
  caption: {
    marginTop: 10,
    color: COLORS.inkLight,
    fontFamily: 'Karla',
    fontSize: 14,
    lineHeight: '20px',
    fontStyle: 'italic',
  },
  weightInput: {
    width: 115,

    '& .MuiInputAdornment-root > p': {
      fontWeight: 'normal',
      color: COLORS.inkFaded,
    },
  },
});

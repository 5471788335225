import angular from 'angular';

import { AnalyticsHelpers } from './analytics-helpers/analytics-helpers.service';
import { GetStartedActionsComponent } from './welcome/get-started-actions/get-started-actions.component.ts';
import { CallToActionComponent } from './welcome/get-started-actions/call-to-action/call-to-action.component.ts';
import { GoGlobalModalComponent } from './welcome/go-global-modal/go-global-modal.component.ts';
import { InviteeModalComponent } from './welcome/invitee-modal/invitee-modal.component.ts';
import { AngularChartComponent } from '../../core/components/wrappers/components/AngularChartComponent';

angular
  .module('app.analytics', [])
  .service('AnalyticsHelpers', AnalyticsHelpers)
  .component('esGetStartedActions', GetStartedActionsComponent)
  .component('esWelcomeCallToAction', CallToActionComponent)
  .component('esGoGlobalModal', GoGlobalModalComponent)
  .component('esInviteeModal', InviteeModalComponent)
  .component('chartComponent', AngularChartComponent);

import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import React, { lazy, Suspense } from 'react';
import { react2angular } from 'react2angular';

const ChangeCourierErrorMessage = lazy(() => import('@/components/ChangeCourierErrorMessage'));

function WrappedComponent() {
  return (
    <ReactRootProviders>
      <Suspense fallback={null}>
        <ChangeCourierErrorMessage />
      </Suspense>
    </ReactRootProviders>
  );
}

export const AngularChangeCourierErrorMessage = react2angular(WrappedComponent, []);

import { IComponentController } from 'angular';
import template from './credit-card-element.html?raw';
import style from './credit-card-element.module.scss';

class CreditCardElement implements IComponentController {
  style = style;

  getCardIcon(cssClass: string) {
    const cardImgSrc = `${import.meta.env.VITE_APP_STORAGE}/credit-cards/`;
    switch (cssClass) {
      case 'visa':
        return `${cardImgSrc}visa.svg`;
      case 'mastercard':
        return `${cardImgSrc}master.svg`;
      case 'amex':
        return `${cardImgSrc}amex.svg`;
      case 'discover':
        return `${cardImgSrc}discover.svg`;
      case 'dinersclub':
        return `${cardImgSrc}dinersclub.svg`;
      case 'jcb':
        return `${cardImgSrc}jcb.svg`;
      case 'unionpay':
        return `${cardImgSrc}unionpay.svg`;
      case 'wework':
        return `${cardImgSrc}wework-colour.svg`;
      default:
        return '';
    }
  }
}

const CreditCardElementComponent: ng.IComponentOptions = {
  controller: CreditCardElement,
  template,
  bindings: {
    esCssBrand: '<',
    esIsExpire: '<',
    esIsSelected: '<',
    esExpireDate: '<',
    esLastDigits: '<',
  },
};

export { CreditCardElementComponent };

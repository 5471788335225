import { toastError } from '@client/core/components/react/Toastify';
import Cookies from 'universal-cookie';

import { FEATURE_KEY } from '@client/data/subscription';
import template from './couriers.html?raw';
import style from './couriers.module.scss';

class Couriers {
  static $inject = [
    '$stateParams',
    '$scope',
    '$translate',
    'CourierDetails',
    'CourierCreate',
    'CourierAccounts',
    'CourierService',
    'MixpanelService',
    'SubscriptionService',
    'UserSession',
    'ContactSalesModal',
  ];

  constructor(
    $stateParams,
    $scope,
    $translate,
    CourierDetails,
    CourierCreate,
    CourierAccounts,
    CourierService,
    MixpanelService,
    SubscriptionService,
    UserSession,
    ContactSalesModal
  ) {
    this.style = style;
    this.$stateParams = $stateParams;
    this.$scope = $scope;
    this.$translate = $translate;
    this.CourierDetails = CourierDetails;
    this.CourierCreate = CourierCreate;
    this.CourierAccounts = CourierAccounts;
    this.CourierService = CourierService;
    this.MixpanelService = MixpanelService;
    this.SubscriptionService = SubscriptionService;
    this.UserSession = UserSession;
    this.ContactSalesModal = ContactSalesModal;
    this.showModal = {
      compare: false,
      upgrade: false,
      downgrade: false,
    };
    this.visibleAccounts = [];
    this.countries = [];
    this.shippingCountries = [];
    this.loading = true;
    this.showContent = true;
    this.courierInfoLoading = false;
    this.selectedCountry = '';
    this.isAddressModalOpen = false;
    // eslint-disable-next-line no-empty-function
    this.saveAddressSuccess = () => {};
    this.code = '';
    this.courierAccountId = '';
    this.isUpsDapModalOpen = false;
    this.upsDapCountries = [];
  }

  $onInit() {
    // Open UPS DAP modal if direct route is used (/couriers/ups/dap-agreement?countries)
    if (this.$stateParams.openExternalUpsDapModal) {
      const { countries } = this.$stateParams;

      if (countries) {
        this.upsDapCountries = countries.split(',');
        this.isUpsDapModalOpen = true;
        this.loading = false;
        this.showContent = false;
      } else {
        const cookies = new Cookies();
        let url = cookies.get('redirectBackUrl');
        url += url.includes('?') ? '&ups_dap_status=cancelled' : '?ups_dap_status=cancelled';
        cookies.remove('redirectBackUrl');
        window.open(url, '_self');
      }
    } else {
      this.filterAccounts = this.filterAccounts.bind(this);
      this.closeAddressModal = this.closeAddressModal.bind(this);
      this.handleCourierConnectionSuccess = this.handleCourierConnectionSuccess.bind(this);
      this.chooseCourierToLink = this.chooseCourierToLink.bind(this);
      this.handleToggleUpsDapModal = this.handleToggleUpsDapModal.bind(this);
      this.handleOnRegisterSucceed = this.handleOnRegisterSucceed.bind(this);
      this.shippingCountries = this.UserSession.company.shipping_countries;

      // this is to initiate the modal always closed. To prevent user from accessing the modal when click back from any redirection
      this.CourierCreate.close();
      this.code = this.$stateParams.code;
      this.courierAccountId = this.$stateParams.state;

      this.MixpanelService.track('Click Couriers');
      this.hideLyoc = this.UserSession.getCompanyDashboardSettings().hide_lyoc;

      this._fetchCouriers().then(() => {
        if (this.$stateParams.courier_id) {
          const courier = this.CourierAccounts.companyCouriers.find((companyCourier) => {
            return companyCourier.id === this.$stateParams.courier_id;
          });
          this.onAddCourier(courier);
        }
      });
      const companyId = this.UserSession.getCompanyId();
      if (companyId) {
        this.SubscriptionService.fetchCurrentSubscription({
          company_id: companyId,
        });
      } else {
        toastError(this.$translate.instant('toast.default-error'));
      }
      this.SubscriptionService.fetchPlansDetail({
        country_id: this.UserSession.company.country_id,
      });
      // Open new courier modal if param new is present
      if (this.$stateParams.new) this.onAddCourier();
    }
  }

  _fetchCouriers() {
    return this.CourierAccounts.getActiveCourierAccounts()
      .then(() => {
        this.visibleAccounts = this.CourierAccounts.easyshipCouriers;
        this.countries = this.CourierAccounts.easyshipCouriers
          .map(function (courierAccount) {
            return courierAccount.country_alpha2;
          })
          .filter(function (alpha2, index, arr) {
            return arr.indexOf(alpha2) === index;
          });
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      })
      .finally(() => {
        this.loading = false;
      });
  }

  handleCourierConnectionSuccess() {
    this._fetchCouriers();
  }

  // COUR-1553 to be removed after all old UPS accounts reconnected
  handleReconnect(courier) {
    this.CourierAccounts.getReconnectUrl({ id: courier.id }).then((res) => {
      const form = {
        accountName: courier.nickname,
      };
      localStorage.setItem(courier.id, JSON.stringify(form));
      window.location.href = res.authorize_url;
    });
  }

  onAddCourier(courierData) {
    if (!this.UserSession.hasPickupAddress()) {
      this.isAddressModalOpen = true;
      this.saveAddressSuccess = () => {
        this.isAddressModalOpen = false;
        this.onAddCourier(courierData);
      };
      return;
    }

    if (courierData) {
      const country = this.shippingCountries.find(
        (shippingCountry) => shippingCountry.id === courierData.country_id
      );
      this.courierInfoLoading = true;
      this.CourierAccounts.getCourierInfo({
        id: courierData.id,
      })
        .then((res) => {
          this.CourierCreate.open({
            showUpgradeModal: this.showUpgradeModal.bind(this),
            selectedCountry: country,
            courierData: res,
          });
        })
        .catch(() => {
          toastError(this.$translate.instant('toast.default-error'));
        })
        .finally(() => {
          this.courierInfoLoading = false;
        });
    } else {
      this.CourierCreate.open({
        showUpgradeModal: this.showUpgradeModal.bind(this),
      });
    }
  }

  onAddCourierCardAction() {
    if (this.isMaxCourier() && this.isSubscriptionAvailable() && !this.isFreePlan()) {
      this.showUpgradeModal();
    } else {
      this.onAddCourier();
    }
  }

  chooseCourierToLink(selectedCourier, selectedCountry) {
    if (!this.UserSession.hasPickupAddress()) {
      this.isAddressModalOpen = true;
      this.saveAddressSuccess = () => {
        this.isAddressModalOpen = false;
        this.chooseCourierToLink(selectedCourier, selectedCountry);
      };
      return;
    }

    this.CourierCreate.open({
      selectedCourier,
      selectedCountry,
      showUpgradeModal: this.showUpgradeModal.bind(this),
      isLyocOnly: true,
    });
  }

  onOpenCourierDetails(courier, type) {
    // TODO: add real check for UPS DAP agreement.
    if (this.isUpsDap(courier) && type === 'easyship') {
      // TODO: pass the needed value to modal e.g: country, etc.
      this.handleToggleUpsDapModal(courier);
      this.$scope.$apply();
    } else if (courier.auth_state === 'failed' || courier.auth_state === 'partially_verified') {
      this.onAddCourier(courier);
    } else {
      this.CourierDetails.fedexBrokerFormShow = null;
      this.CourierDetails.open({ ...courier, type });
    }
  }

  filterAccounts(countryAlpha2) {
    this.selectedCountry = countryAlpha2;
    this.visibleAccounts = !countryAlpha2
      ? this.CourierAccounts.easyshipCouriers
      : this.CourierAccounts.easyshipCouriers.filter(function (courierAccount) {
          return courierAccount.country_alpha2 === countryAlpha2;
        });
    this.$scope.$apply();
  }

  isMaxCourier() {
    return this.SubscriptionService.isMaxCourier;
  }

  isFreePlan() {
    return this.SubscriptionService.isFreePlan;
  }

  showMaxCourierLimitMessage() {
    return (
      this.isMaxCourier() && this.SubscriptionService.currentSubscription.all_features.lyoc.limit
    );
  }

  isSubscriptionAvailable() {
    return this.SubscriptionService.isSubscriptionAvailable;
  }

  isUpsDap(courier) {
    const upsDapCountries = ['GB', 'US', 'DE', 'CA'];

    return (
      courier.logo_url === 'ups' &&
      courier.request_ups_dap_agreement &&
      courier.request_ups_dap_agreement.length > 0 &&
      upsDapCountries.includes(courier.country_alpha2)
    );
  }

  suggestedPlanId() {
    return this.SubscriptionService.getSuggestedPlanIdByFeatureKey(FEATURE_KEY.Lyoc);
  }

  showUpgradeModal() {
    if (this.suggestedPlanId() === this.SubscriptionService.planIds.Enterprise) {
      this.showModal.enterpriseCall = true;
    } else {
      this.showModal.upgrade = true;
    }
  }

  closeAddressModal() {
    this.isAddressModalOpen = false;
    // eslint-disable-next-line no-empty-function
    this.saveAddressSuccess = () => {};
  }

  handleToggleUpsDapModal(courier) {
    this.isUpsDapModalOpen = !this.isUpsDapModalOpen;
    if (this.isUpsDapModalOpen) {
      this.upsDapCountries.push(courier.country_alpha2);
    } else {
      this.upsDapCountries = [];
    }
    this.$scope.$evalAsync();
  }

  handleOnRegisterSucceed() {
    this.handleToggleUpsDapModal();
    this._fetchCouriers().then(() => {
      this.$scope.$evalAsync();
    });
  }
}

const CouriersComponent = {
  controller: Couriers,
  template,
};

export { CouriersComponent };
